import { useReducer } from 'react';
import type {
  ISearchPageAds,
  SearchPageBreadcrumbs,
  ISearchPagePaging,
} from 'api/types/searchPageApiTypes';

enum ACTION_TYPE {
  SET_SECTION = 'SET_SECTION',
  SET_KEYWORD_SEARCH_TERM = 'SET_KEYWORD_SEARCH_TERM',
  SET_SORT = 'SET_SORT',
  SET_PAGING = 'SET_PAGING',
  SET_PAGE = 'SET_PAGE',
  SET_ON_SEARCH_SUCCESS = 'SET_ON_SEARCH_SUCCESS',
  RESET = 'RESET',
}

type PagingType = ISearchPagePaging;

type SetSectionType = { type: ACTION_TYPE.SET_SECTION; payload: string };
type SetKeywordSearchTermType = {
  type: ACTION_TYPE.SET_KEYWORD_SEARCH_TERM;
  payload: string;
};
type SetSortType = { type: ACTION_TYPE.SET_SORT; payload: string };
type SetPagingType = { type: ACTION_TYPE.SET_PAGING; payload: PagingType };
type SetPageType = { type: ACTION_TYPE.SET_PAGE; payload: number };
type SetOnSearchSuccessType = {
  type: ACTION_TYPE.SET_ON_SEARCH_SUCCESS;
  payload: {
    breadcrumbs: SearchPageBreadcrumbs[];
    recommendations: ISearchPageAds[];
  };
};
type ResetType = { type: ACTION_TYPE.RESET };

type SearchAction =
  | SetSectionType
  | SetKeywordSearchTermType
  | SetSortType
  | SetPagingType
  | SetPageType
  | ResetType
  | SetOnSearchSuccessType;

function searchReducer(
  state: ISearchPageState,
  action: SearchAction,
): ISearchPageState {
  switch (action.type) {
    case ACTION_TYPE.SET_SECTION:
      return { ...state, page: 0, section: action.payload };
    case ACTION_TYPE.SET_KEYWORD_SEARCH_TERM:
      return { ...state, page: 0, keywordSearchTerm: action.payload };
    case ACTION_TYPE.SET_SORT:
      return { ...state, sort: action.payload, page: 0 };
    case ACTION_TYPE.SET_PAGING:
      return { ...state, paging: action.payload };
    case ACTION_TYPE.SET_PAGE:
      return { ...state, page: action.payload };
    case ACTION_TYPE.SET_ON_SEARCH_SUCCESS:
      return {
        ...state,
        breadcrumbs: action.payload.breadcrumbs,
        recommendations: action.payload.recommendations,
      };
    case ACTION_TYPE.RESET:
      return defaultState;
    default:
      return state;
  }
}

export interface ISearchPageState {
  keywordSearchTerm: string;
  section: string;
  sort: string;
  page: number;
  paging: PagingType;
  breadcrumbs: SearchPageBreadcrumbs[];
  recommendations: ISearchPageAds[];
}

export interface ISearchPageDispatch {
  setKeywordSearchTerm: (value: string) => void;
  setPaging: (paging: PagingType) => void;
  setPageInitial: () => void;
  setPageByTotal: (pages: number) => void;
  setPageByDividing: (dividend: number, divisor: number) => void;
  setSort: (value: string) => void;
  setSection: (value: string) => void;
  setOnSearchSuccess: (
    breadcrumbs: SearchPageBreadcrumbs[],
    recommenations: ISearchPageAds[],
  ) => void;
  reset: () => void;
}

export const defaultState: ISearchPageState = {
  keywordSearchTerm: '',
  section: '',
  sort: '',
  page: 0,
  paging: {
    totalPages: 0,
    currentPage: 0,
    nextFrom: 0,
    previousFrom: 0,
    displayingFrom: 0,
    displayingTo: 0,
    totalResults: 0,
    pageSize: 0,
  },
  breadcrumbs: [],
  recommendations: [],
};

const useSearchPage = (
  section: string,
  keywordSearchTerm: string,
  sort: string,
  paging: PagingType,
  breadcrumbs: SearchPageBreadcrumbs[],
  recommendations: ISearchPageAds[],
) => {
  const [state, dispatch] = useReducer(searchReducer, {
    section,
    keywordSearchTerm,
    sort,
    paging,
    page: Math.max(paging.currentPage - 1, 0), // If no results, currentPage is 0
    breadcrumbs,
    recommendations,
  });

  const setSection = (value: string) =>
    dispatch({ type: ACTION_TYPE.SET_SECTION, payload: value });

  const setKeywordSearchTerm = (value: string) =>
    dispatch({ type: ACTION_TYPE.SET_KEYWORD_SEARCH_TERM, payload: value });

  const setPaging = (paging: PagingType) =>
    dispatch({
      type: ACTION_TYPE.SET_PAGING,
      payload: paging,
    });

  const setPageInitial = () =>
    dispatch({
      type: ACTION_TYPE.SET_PAGE,
      payload: 0,
    });

  const setPageByTotal = (pages: number) =>
    dispatch({
      type: ACTION_TYPE.SET_PAGE,
      payload: pages - 1,
    });

  const setPageByDividing = (dividend: number, divisor: number) =>
    dispatch({
      type: ACTION_TYPE.SET_PAGE,
      payload: dividend / divisor,
    });

  const setSort = (value: string) =>
    dispatch({
      type: ACTION_TYPE.SET_SORT,
      payload: value,
    });

  const setOnSearchSuccess = (
    breadcrumbs: SearchPageBreadcrumbs[],
    recommendations: ISearchPageAds[],
  ) =>
    dispatch({
      type: ACTION_TYPE.SET_ON_SEARCH_SUCCESS,
      payload: { breadcrumbs, recommendations },
    });

  const reset = () =>
    dispatch({
      type: ACTION_TYPE.RESET,
    });

  return {
    state,
    dispatch: {
      setKeywordSearchTerm,
      setPaging,
      setPageInitial,
      setPageByTotal,
      setPageByDividing,
      setSort,
      setSection,
      setOnSearchSuccess,
      reset,
    },
  };
};

export { useSearchPage };
