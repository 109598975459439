/**
 * Measures the widths of an array of strings using a specified font.
 *
 * @param {string[]} textArray - An array of strings to measure.
 * @param {string} [font='14px Lato'] - The font to use for measuring text. Defaults to '14px Lato'.
 * @returns {number[]} - An array of widths corresponding to each string in `textArray`.
 *
 * @example
 * const widths = getTextWidths(['apple', 'banana'], '16px Arial');
 * console.log(widths); // [80, 100]
 */
export const getTextWidths = (
  textArray: string[],
  font = '14px Lato',
): number[] => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = font;
    return textArray.map((text) => context.measureText(text).width);
  }

  return [0];
};

/**
 * Formats an array of options to fit within a specified available width.
 * Returns the visible options and a count of hidden options if they exceed the available width.
 *
 * @param {string[]} options - An array of options to display.
 * @param {number[]} optionWidths - An array of widths for each option.
 * @param {number} availableWidth - The maximum width available for displaying options.
 * @returns {string} - A string representing visible options and the count of hidden options.
 *
 * @example
 * const result = truncateOptionsForAccordion(['red', 'blue', 'green'], [50, 50, 50], 100);
 * console.log(result); // "red, blue, (+1)"
 */
export const truncateOptionsForAccordion = (
  options: string[],
  optionWidths: number[],
  availableWidth: number,
  filterName: string,
): string => {
  if (!options.length) return '';

  let accumulatedWidth = 0;
  let visibleOptionsCount = 0;

  for (const optionWidth of optionWidths) {
    if (accumulatedWidth + optionWidth > availableWidth) break;
    accumulatedWidth += optionWidth;
    visibleOptionsCount++;
  }

  const visibleOptions = options.slice(0, visibleOptionsCount);
  const hiddenOptionsCount = options.length - visibleOptionsCount;
  const showCommaBeforeRemainderCount =
    filterName === 'makeModel' && hiddenOptionsCount > 0;

  if (showCommaBeforeRemainderCount) {
    return `${visibleOptions.join(', ')}, (+${hiddenOptionsCount})`;
  }

  if (hiddenOptionsCount > 0) {
    return `${visibleOptions.join(', ')} (+${hiddenOptionsCount})`;
  }

  return visibleOptions.join(', ');
};
