import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import type { CSSProperties } from 'react';
import type { Interpolation } from 'styled-components';

import { ErrorMessage } from 'components/Toolkit/Inputs/ErrorMessage';
import { SubText } from 'components/Toolkit/Inputs/SubText';

export interface IRadioButton {
  id?: string;
  name: string;
  label: string;
  value: string;
  checked?: boolean;
  onChange?: (selected: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  hasError?: boolean;
  metaCallback?: () => void;
  radioButtonStyles?: Interpolation<CSSProperties>;
  labelStyles?: Interpolation<CSSProperties>;
  children?: ReactNode;
  tabIndex?: number;
}

// https://moderncss.dev/pure-css-custom-styled-radio-buttons/
const SRadio = styled.input<{ hasError?: boolean }>`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.WHITE};

  width: 24px;
  height: 24px;
  border: 1px solid
    ${({ hasError, theme }) =>
      hasError ? theme.colors.RED_DARK : theme.colors.GREY};
  border-radius: 50%;

  display: grid;
  place-content: center;
  cursor: pointer;

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 12px 12px ${({ theme }) => theme.colors.BLUE};
  }

  &:checked:before {
    transform: scale(1);
  }

  &:checked,
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.BLUE};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.GREY_LIGHT};
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }
`;

type TStyle = {
  disabled?: boolean;
  hasError?: boolean;
  styles?: Interpolation<React.CSSProperties>;
};
const SLabel = styled.label<TStyle>`
  ${({ theme }) => theme.fontSize.M16}
  color: ${({ disabled, theme, hasError }) =>
    disabled
      ? theme.colors.GREY_LIGHT
      : hasError
      ? theme.colors.RED_DARK
      : theme.colors.GREY_DARKER};
  display: flex;
  ${({ styles }) => styles && styles};
`;

type TInputContainer = {
  styles?: Interpolation<React.CSSProperties>;
};
export const InputContainer = styled.div<TInputContainer>`
  display: grid;
  grid-template-columns: 24px auto;
  gap: ${({ theme }) => theme.spacing.S8};
  margin-bottom: ${({ theme }) => theme.spacing.S4};

  ${SRadio}:checked + ${SLabel} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  ${({ styles }) => styles && styles};
`;

function RadioButton({
  id,
  name,
  label,
  className,
  disabled,
  errorMessage,
  value,
  checked,
  onChange,
  onFocus,
  onBlur,
  hasError,
  required,
  metaCallback,
  radioButtonStyles,
  labelStyles,
  children,
  tabIndex,
}: IRadioButton) {
  const alwaysSetId = id ? id : `${name}-${value}`;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const val = event.currentTarget.value;
    onChange && onChange(val);
  }

  useEffect(() => {
    metaCallback && metaCallback();
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  return (
    <div className={className}>
      <InputContainer styles={radioButtonStyles}>
        <SRadio
          type="radio"
          name={name}
          id={alwaysSetId}
          disabled={disabled}
          hasError={hasError}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          checked={checked}
          required={required}
          tabIndex={tabIndex}
        />
        <SLabel
          htmlFor={alwaysSetId}
          disabled={disabled}
          hasError={hasError}
          styles={labelStyles}
        >
          {label}
        </SLabel>
      </InputContainer>
      {children}
      {errorMessage && (
        <SubText>
          <ErrorMessage text={errorMessage} />
        </SubText>
      )}
    </div>
  );
}

export { RadioButton };
