import styled from 'styled-components';

import { media } from 'helpers/breakpoints';

const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacing.S4};

  ${media.large} {
    padding-top: ${({ theme }) => theme.spacing.S8};
  }
`;

export { Container };
