import { useMakeModelContext } from 'components/SearchPage/features/makeModel/MakeModelContext';
import { useMakeModelShared } from 'components/SearchPage/features/makeModel/hooks/MakeModelShared.hook';

const useMakeModelWriteState = (args: { section: string }) => {
  const { section } = args;

  const { makeModels, setMakeModels } = useMakeModelContext();

  const { getModels } = useMakeModelShared({ section });

  const selectMake = (makeValue: string) => {
    const includesMake = makeModels?.some((item) => item.make === makeValue);
    if (includesMake) {
      clearMake(makeValue);
    } else if (!makeValue) {
      const filteredMakeModels = makeModels?.filter(
        (item) => item.isEditable !== true,
      );
      setMakeModels([...(filteredMakeModels ?? [])]);
    } else {
      const filteredMakeModels = makeModels?.filter(
        (item) => item.isEditable !== true,
      );

      getModels(makeValue);

      setMakeModels([
        ...(filteredMakeModels ?? []),
        { make: makeValue, isEditable: true },
      ]);
    }
  };

  const selectModel = (makeValue: string, option: string) => {
    const filteredMakes = makeModels?.filter((item) => item.make !== makeValue);

    if (!option) {
      setMakeModels([
        ...(filteredMakes ?? []),
        {
          make: makeValue,
          isEditable: true,
        },
      ]);
    } else {
      const selectedModels = makeModels
        ?.find((item) => item.make === makeValue)
        ?.model?.filter((item) => item);

      const modelValue = selectedModels?.find((item) => item === option)
        ? selectedModels.filter((item) => item !== option)
        : [...(selectedModels ?? []), option];

      setMakeModels([
        ...(filteredMakes ?? []),
        {
          make: makeValue,
          model: modelValue,
          isEditable: true,
        },
      ]);
    }
  };

  const clearMake = (makeValue: string) => {
    const filteredMakes = makeModels?.filter(({ make }) => make !== makeValue);

    setMakeModels(
      filteredMakes && filteredMakes.length > 0 ? filteredMakes : undefined,
    );
  };

  const onAddSelection = () => {
    const mappedMakeModels = makeModels?.map((item) => {
      return {
        ...item,
        isEditable: false,
      };
    });
    setMakeModels(mappedMakeModels);
  };

  return {
    selectMake,
    selectModel,
    onAddSelection,
  };
};

export { useMakeModelWriteState };
