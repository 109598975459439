import styled, { css } from 'styled-components';

import { ViewType } from 'components/SearchPage/SearchPage.typed';
import { media } from 'helpers/breakpoints';

const List = styled.ul<{ viewType?: ViewType }>`
  display: grid;
  grid-template-columns: ${({ viewType }) =>
    viewType === 'grid' ? 'repeat(2, 1fr)' : '1fr'};
  grid-gap: ${({ theme }) => theme.spacing.M16};
  margin-bottom: ${({ theme }) => theme.spacing.M24};

  ${media.medium} {
    grid-template-columns: ${({ viewType }) =>
      viewType === 'grid' ? 'repeat(3, 1fr)' : '1fr'};
    grid-gap: ${({ theme }) => theme.spacing.M24};
  }
`;

const ListItemFullWidth = styled.li<{ hidden?: boolean }>`
  grid-column: 1/-1;
  overflow: hidden;

  &:empty {
    display: none;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

const ListItem = styled.li`
  /* Collapse flex child to allow text truncation, see https://css-tricks.com/flexbox-truncated-text/ */
  min-width: 0;
`;

export { List, ListItem, ListItemFullWidth };
