import { useEffect, useState, useCallback, useRef } from 'react';
import { useFiltersState } from 'features/filters/Filters.context';
import {
  truncateOptionsForAccordion,
  getTextWidths,
} from 'features/filters/helpers/filterUtils';
import type { FilterHookProps } from 'features/filters/components/Filter/Filter.typed';

const formatSelectedOptions = (
  selectedOptionsString: string | string[] | undefined,
  isPriceFilter: boolean,
): string[] => {
  if (!selectedOptionsString) return [];
  if (Array.isArray(selectedOptionsString)) return selectedOptionsString;
  if (isPriceFilter) {
    return [selectedOptionsString];
  }
  return selectedOptionsString.split(', ');
};

const useFilter = (props: FilterHookProps) => {
  const { item, ref, displayText } = props;

  const { isFilterModalOpen } = useFiltersState();

  const [totalWidthAvailable, setTotalWidthAvailable] = useState<number>(240);
  const previousWidthRef = useRef<number>(240);
  const selectedOptionsString = displayText(item);

  const WIDTH_FOR_ICON_AND_TEXT =
    item.name === 'location' ? 40 : isFilterModalOpen ? 110 : 100;
  const isPriceFilter = item.filterType.name === 'PriceInputRange';

  const selectedOptions = formatSelectedOptions(
    selectedOptionsString,
    isPriceFilter,
  );

  const selectedOptionsWidths = getTextWidths(selectedOptions);

  const selectedOptionDisplayText = selectedOptions
    ? truncateOptionsForAccordion(
        selectedOptions,
        selectedOptionsWidths,
        totalWidthAvailable - WIDTH_FOR_ICON_AND_TEXT,
        item.name,
      )
    : '';

  const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
    if (entries.length > 0) {
      const newWidth = entries[0].contentRect.width;
      if (newWidth !== previousWidthRef.current) {
        setTotalWidthAvailable(newWidth);
        previousWidthRef.current = newWidth;
      }
    }
  }, []);

  useEffect(() => {
    const currentRef = ref.current;
    const resizeObserver = new ResizeObserver(handleResize);

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      resizeObserver.disconnect();
    };
  }, [ref, handleResize]);

  return {
    selectedOptions,
    selectedOptionDisplayText,
  };
};

export { useFilter };
