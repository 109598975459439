import { MultiSelectImageProps } from 'features/filters/components/MultiSelectImage/MultiSelectImage.typed';
import * as Styled from 'features/filters/components/MultiSelectImage/MultiSelectImage.styled';
import React from 'react';
import { ImageCheckbox } from 'features/filters/components/MultiSelectImage/components/ImageCheckbox';

const MultiSelectImage = (props: MultiSelectImageProps) => {
  const { options, onSelectOption, selectedOptions = [], label } = props;

  return (
    <Styled.OptionsList role="group" aria-label={label}>
      {options.map((option) => {
        const { label, value, imgSrc } = option;
        const isSelected = !!selectedOptions.find(
          (selectedOption) => selectedOption === value,
        );
        return (
          <ImageCheckbox
            key={`option-${value}`}
            value={value}
            label={label}
            isSelected={isSelected}
            imgSrc={imgSrc}
            onChange={() => onSelectOption(value, !isSelected)}
          />
        );
      })}
    </Styled.OptionsList>
  );
};

export { MultiSelectImage };
