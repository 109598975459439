import styled from 'styled-components';

import { Link } from 'components/Toolkit/Button/Link';
import { OverflowFade } from 'components/Toolkit/OverflowFade/OverflowFade';

export type TBuyingCarTips = {
  baseUrl: string;
  blogUrl: string;
  youtubeUrl: string;
};

const Heading = styled.p`
  ${({ theme }) => theme.fontSize.L18};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
`;

const SubHeading = styled.p`
  display: inline-block;
  ${({ theme }) => theme.fontSize.M16};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
`;
const Description = styled.p`
  ${({ theme }) => theme.fontSize.M16};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

function BuyingCarTips({ baseUrl, blogUrl, youtubeUrl }: TBuyingCarTips) {
  return (
    <div>
      <Heading>Tips for buying a car</Heading>
      <OverflowFade
        mobileContainerMaxHeight={288}
        desktopContainerMaxHeight={264}
        seeLessText="Read Less"
        overflowText="Read More"
        hasChevron={true}
      >
        <SubHeading>
          When buying a new or used car, how do you choose from all the cars for
          sale in Ireland?
        </SubHeading>
        <Description>
          You can find all cars for sale in Ireland from all of Ireland's
          Trusted Car Dealerships on DoneDeal{' '}
          <Link
            ofType="SECONDARY"
            inline
            href="/cars?source=trade"
            target="_blank"
            underline
          >
            here
          </Link>
          . This allows you to easily get an overview of all of the offers
          across all cars for sale in Ireland from car dealers{' '}
          <Link
            ofType="SECONDARY"
            inline
            href="/cars?source=trade"
            target="_blank"
            underline
          >
            in one place
          </Link>
          . Browse premium cars for sale in Ireland with warranties, finance
          options, and a free basic history check upfront wherever you see the
          greenlight tag.
          <br />
          <br />
          If you are buying a second-hand or used car, the price of the car is
          an obvious area to make savings, but don't forget benefits-in-kind
          like free car servicing, extended warranties or, a guaranteed NCT
          pass. Consider your overall running costs too like motor tax and car
          Insurance which are essential when driving cars in Ireland.
        </Description>
        <SubHeading>Trading in your car?</SubHeading>
        <Description>
          See how much your current car is worth by using our{' '}
          <Link
            ofType="SECONDARY"
            inline
            href={`${baseUrl}/value-my-car`}
            underline
          >
            car valuation tool.
          </Link>{' '}
        </Description>
        <SubHeading>
          Make sure you run a car history check before you buy a used or
          second-hand car.
        </SubHeading>
        <Description>
          You can get a free basic car history check upfront, wherever you see
          the 'greenlight verified' tag on car ad listings. Browse cars with the
          Greenlight Verified tag{' '}
          <Link
            ofType="SECONDARY"
            inline
            href="/cars?verifications=greenlightVerified"
            target="_blank"
            underline
          >
            here
          </Link>
          . For a more in depth advanced car history check, see{' '}
          <Link
            ofType="SECONDARY"
            inline
            href={`${baseUrl}/greenlight-history-check`}
            target="_blank"
            underline
          >
            here
          </Link>
          .
        </Description>
        <SubHeading>
          Look at Car Reviews and tips for Confident Car buying.
        </SubHeading>
        <Description>
          We review premium cars on our YouTube Channel{' '}
          <Link
            ofType="SECONDARY"
            inline
            href={youtubeUrl}
            target="_blank"
            rel="nofollow noreferrer"
            underline
          >
            here
          </Link>{' '}
          and provide buying guides for confident car buying on our blog{' '}
          <Link
            ofType="SECONDARY"
            inline
            href={`${blogUrl}/?utm_source=donedeal.ie&utm_medium=link&utm_campaign=Social&utm_content=community%2Bbox`}
            target="_blank"
            underline
          >
            here
          </Link>
          , where you'll find plenty of independent reviews and tips for car
          buying.
        </Description>
      </OverflowFade>
    </div>
  );
}

export { BuyingCarTips };
