import styled from 'styled-components';
import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';

export const SCheckbox = styled(Checkbox)`
  pointer-events: none;
  min-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
