import * as LayoutSelectorStyle from 'components/SearchPage/components/LayoutSelector/LayoutSelector.styled';
import { GridIc, ListIc, MenuIc, GridOutlineIc } from '@dsch/dd-icons';
import { Menu } from 'components/Toolkit/Menu/Menu';
import { SimpleDropdown } from 'components/Toolkit/SimpleDropdown/SimpleDropdown';
import { LayoutSelectorProps } from 'components/SearchPage/components/LayoutSelector/LayoutSelector.typed';

const LayoutSelector = (props: LayoutSelectorProps) => {
  const { viewType, setViewType } = props;
  const isListLayout = viewType === 'list';
  return (
    <LayoutSelectorStyle.ViewTypeButtons>
      <LayoutSelectorStyle.Desktop>
        <LayoutSelectorStyle.ViewTypeButton
          size="SMALL"
          active={isListLayout}
          title="List View"
          onClick={() => setViewType('list')}
        >
          <ListIc />
        </LayoutSelectorStyle.ViewTypeButton>
        <LayoutSelectorStyle.ViewTypeButton
          size="SMALL"
          active={!isListLayout}
          title="Grid View"
          onClick={() => setViewType('grid')}
        >
          <GridIc />
        </LayoutSelectorStyle.ViewTypeButton>
      </LayoutSelectorStyle.Desktop>
      <LayoutSelectorStyle.Mobile>
        <SimpleDropdown
          text={
            <>
              {isListLayout ? (
                <MenuIc width={20} height={20} />
              ) : (
                <GridOutlineIc width={20} height={20} />
              )}
              <LayoutSelectorStyle.Text data-testid="layout-selector">
                {isListLayout ? 'List View' : 'Grid View'}
              </LayoutSelectorStyle.Text>
            </>
          }
          variant="DEFAULT"
          showArrow={false}
          align={'left'}
        >
          <Menu
            icons={true}
            items={[
              {
                icon: (
                  <LayoutSelectorStyle.WrapperIcon>
                    <MenuIc width={16} height={16} />
                  </LayoutSelectorStyle.WrapperIcon>
                ),
                displayName: 'List View',
                onClick: () => setViewType('list'),
                dataTracking: 'layout-list',
              },
              {
                icon: (
                  <LayoutSelectorStyle.WrapperIcon>
                    <GridOutlineIc width={16} height={16} />
                  </LayoutSelectorStyle.WrapperIcon>
                ),
                displayName: 'Grid View',
                onClick: () => setViewType('grid'),
                dataTracking: 'layout-grid',
              },
            ]}
          />
        </SimpleDropdown>
      </LayoutSelectorStyle.Mobile>
    </LayoutSelectorStyle.ViewTypeButtons>
  );
};

export { LayoutSelector };
