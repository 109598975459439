import React from 'react';
import type { ImageCheckboxProps } from 'features/filters/components/MultiSelectImage/components/ImageCheckbox.typed';
import * as Styled from 'features/filters/components/MultiSelectImage/components/ImageCheckbox.styled';

const ImageCheckbox = (props: ImageCheckboxProps) => {
  const { value, isSelected, onChange, label, imgSrc } = props;

  return (
    <Styled.Checkbox
      key={`option-${value}`}
      checked={isSelected}
      onChange={({ currentTarget: { checked } }) => {
        onChange(value, checked);
      }}
    >
      {imgSrc && <Styled.Image src={imgSrc} alt={label} />}
      <Styled.Text isSelected={isSelected}>{label}</Styled.Text>
    </Styled.Checkbox>
  );
};

export { ImageCheckbox };
