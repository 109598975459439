import styled from 'styled-components';
import Modal from 'react-modal';

import { CloseOutlineIc } from '@dsch/dd-icons';

import { Loading } from 'components/Toolkit/Loading/Loading';
import { Button } from 'components/Toolkit/Button';

const StyledSavedSearchesModal = styled(Modal)`
  width: 320px;
  max-height: 546px;
  padding: ${({ theme }) => theme.spacing.M24};
  position: fixed;
  left: calc(50%);
  top: calc(50%);
  transform: translateY(100%) translateX(-50%);
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.WHITE};t;
  opacity: 0;

  .ReactModal__Overlay--after-open & {
    opacity: 1;
    transition: all 0.2s ease-out;
    transform: translateY(-50%) translateX(-50%);
  }
  .ReactModal__Overlay--before-close & {
    opacity: 0;
    transition: all 0.2s ease-in;
    transform: translateY(100%) translateX(-50%);
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadingText = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.S8};
  ${({ theme }) => theme.fontSize.L18};
`;

const Saving = styled(Loading)`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

const CloseButton = styled(CloseOutlineIc)`
  cursor: pointer;
`;

const Prompt = styled.p`
  color: ${({ theme }) => theme.colors.BLACK};
`;

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const TagListItem = styled.li`
  margin-right: ${({ theme }) => theme.spacing.S8};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;

const SavedSearchButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Heading,
  HeadingText,
  Saving,
  CloseButton,
  Prompt,
  TagList,
  TagListItem,
  SavedSearchButton,
  StyledSavedSearchesModal,
};
