import { useRouter } from 'next/router';

import { useQuickFilterContext } from 'components/SearchPage/features/QuickFilters/QuickFilters.context';

import { useFilterProps } from 'components/SearchPage/features/QuickFilters/FilterProps.hook';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useMakeModelFilter } from 'components/SearchPage/features/QuickFilters/components/FilterBottomSheet/MakeModelFilter/MakeModelFilter.hook';
import { useURLData } from 'components/SearchPage/features/QuickFilters/URLData.hook';
import { useLocationFilter } from 'components/SearchPage/features/QuickFilters/components/FilterBottomSheet/LocationFilter/LocationFilter.hook';
import { useValidPriceFilter } from 'components/SearchPage/features/QuickFilters/components/FilterBottomSheet/ValidPriceFilter/ValidPriceFilter.hook';

import { QUICK_FILTERS_MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES } from 'components/SearchPage/features/QuickFilters/QuickFilters.constants';

import { asString, asFrom, asTo } from 'utils/query-parameters';
import { camelToSnakeCase } from 'helpers/formatting';

import type { UseFilterBottomSheetArgs } from 'components/SearchPage/features/QuickFilters/components/FilterBottomSheet/FilterBottomSheet.typed';

const useFilterBottomSheet = (props: UseFilterBottomSheetArgs) => {
  const { askToClose } = props;
  const {
    onChangeFilterValue,
    currentFilter,
    selectedFilterQueryParam,
    selectedFilterValue,
  } = useQuickFilterContext();

  const { handleSelectLocation, locationConfiguration, clearLocation } =
    useLocationFilter();

  const {
    setValidPrice,
    clearValidPrice,
    containsValidPrice,
    getValidPriceQueryParam,
  } = useValidPriceFilter();

  const { query } = useRouter();

  const { getFilterProps } = useFilterProps({
    onChangeFilterValue,
    filterQueryValues: { ...query, ...selectedFilterQueryParam },
  });

  const {
    currentMake,
    currentModel,
    formattedFilterName,
    SEOFilterQueryValue,
  } = useURLData(currentFilter);

  const { handleSelectMakeModels, makeModelConfiguration, clearMakeModels } =
    useMakeModelFilter({ SEOFilterQueryValue });

  const { updateURL, updateMakeModels } = useURLStateManagement();

  const { onChange, defaultQueryParam } = getFilterProps(
    currentFilter?.filterType.name ?? '',
    currentFilter?.name ?? '',
  );

  const appliedFilterID = currentFilter?.name
    ? `quick_filter_applied_${camelToSnakeCase(currentFilter?.name)}`
    : '';

  const applySEOParam =
    currentFilter &&
    (!SEOFilterQueryValue || formattedFilterName === currentFilter?.name) &&
    QUICK_FILTERS_MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES.includes(
      currentFilter?.name,
    ) &&
    currentMake &&
    currentModel;

  const onClickDone = () => {
    const valueAsPathParameter =
      selectedFilterQueryParam && currentFilter
        ? selectedFilterQueryParam[currentFilter.name]
        : undefined;

    if (applySEOParam) {
      handleSEOPathParameter(valueAsPathParameter);
    } else if (
      currentFilter?.name === 'make' &&
      typeof valueAsPathParameter === 'string'
    ) {
      const makeModels = valueAsPathParameter
        ? [{ make: valueAsPathParameter }]
        : undefined;

      updateMakeModels({ makeModels });
    } else if (currentFilter?.name === 'location') {
      handleSelectLocation();
    } else if (currentFilter?.name === 'makeModel') {
      handleSelectMakeModels();
    } else if (
      currentFilter?.name === 'price' &&
      currentFilter?.variant === 'DONEDEAL_PRICE_CHECKBOX'
    ) {
      updateURL({
        queryParams: {
          ...selectedFilterQueryParam,
          ...getValidPriceQueryParam(),
        },
      });
    } else {
      updateURL({ queryParams: selectedFilterQueryParam });
    }

    askToClose();
    scroll(0, 0);
  };

  const handleSEOPathParameter = (
    SEOfilterQueryValue?: string | Array<string> | number,
  ) => {
    const SEORangeFromValue =
      selectedFilterQueryParam && currentFilter
        ? asString(selectedFilterQueryParam[`${currentFilter.name}_from`])
        : undefined;

    const SEORangeToValue =
      selectedFilterQueryParam && currentFilter
        ? asString(selectedFilterQueryParam[`${currentFilter.name}_to`])
        : undefined;

    if (currentFilter?.searchQueryGroup === 'filters') {
      handleSEOPathParameterFilter(currentFilter?.name, SEOfilterQueryValue);
    } else if (currentFilter?.searchQueryGroup === 'ranges') {
      handleSEOPathParameterRange(
        currentFilter?.name,
        SEORangeFromValue,
        SEORangeToValue,
      );
    }
  };

  const handleSEOPathParameterFilter = (
    filterName: string,
    queryValue?: string | Array<string> | number,
  ) => {
    if (typeof queryValue === 'string') {
      updateURL({
        pathParam: queryValue,
        queryParams: { [filterName]: '' },
      });
    } else {
      updateURL({
        pathParam: '',
        queryParams: selectedFilterQueryParam,
      });
    }
  };

  const handleSEOPathParameterRange = (
    filterName: string,
    from?: string,
    to?: string,
  ) => {
    if (from && to && from === to) {
      updateURL({
        pathParam: from,
        queryParams: { [asFrom(filterName)]: '', [asTo(filterName)]: '' },
      });
    } else {
      updateURL({
        pathParam: '',
        queryParams: selectedFilterQueryParam,
      });
    }
  };

  const onClickClear = () => {
    if (currentFilter?.name === 'location') {
      clearLocation();
    } else if (currentFilter?.name === 'makeModel') {
      clearMakeModels();
    } else if (
      currentFilter?.name === 'price' &&
      currentFilter?.variant === 'DONEDEAL_PRICE_CHECKBOX'
    ) {
      onChangeFilterValue({
        queryParam: defaultQueryParam,
        filterValue: '',
      });
      clearValidPrice();
    } else {
      onChangeFilterValue({
        queryParam: defaultQueryParam,
        filterValue: '',
      });
    }
  };

  const validPriceConfiguration = {
    onSelectAdsWithValidPrice: setValidPrice,
    containsValidPrice,
  };

  return {
    onClickDone,
    onClickClear,
    onChange,
    value: selectedFilterValue,
    locationConfiguration,
    makeModelConfiguration,
    validPriceConfiguration,
    appliedFilterID,
  };
};

export { useFilterBottomSheet };
