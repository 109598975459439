import { Label } from 'components/Toolkit/Inputs/Label';
import { MakeModel } from 'components/SearchPage/features/makeModel/MakeModel';

import { Spacer } from 'helpers/genericStyles';

import type { DisplayContainerConfig } from 'components/Support/DisplayContainer/DisplayContainer';

import type { MakeModelFilterProps } from 'features/filters/components/MakeModelV2/MakeModelFilter.typed';

const MakeModelFilter = (props: MakeModelFilterProps) => {
  const {
    displayLabel = true,
    selectMake,
    selectModel,
    onAddSelection,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
    makeOptions,
    heightVariant,
  } = props;

  const mWebConfig: DisplayContainerConfig = {
    small: 'block',
    medium: 'block',
    large: 'none',
  };
  const webConfig: DisplayContainerConfig = {
    small: 'none',
    medium: 'none',
    large: 'block',
  };

  return (
    <div>
      {displayLabel && <Label>Make / Model</Label>}
      <MakeModel
        maxHeight="424px"
        maxTextContainerWidthWeb="228px"
        mWebConfig={mWebConfig}
        webConfig={webConfig}
        selectMake={selectMake}
        selectModel={selectModel}
        onAddSelection={onAddSelection}
        maximumAmountOfMakeModelGroupsToBeDisplayed={
          maximumAmountOfMakeModelGroupsToBeDisplayed
        }
        makeOptions={makeOptions}
        heightVariant={heightVariant}
      />
      <Spacer marginBottom="S4" />
    </div>
  );
};

export { MakeModelFilter };
