import type {
  IGetAdsRequestFilter,
  IGetAdsRequestRange,
} from 'api/types/searchPageApiTypes';

import type { Breadcrumb } from 'components/ToolkitV2/Breadcrumbs/Breadcrumbs.typed';

import { capitalizeFirstLetter } from 'helpers/string';

export type TypePrice = 'price' | 'pricePerMonth';

const getRangeUrlKey = (
  name: string,
  rangeKey: string,
  currency?: string,
  typePrice?: TypePrice,
) => {
  const newName = typePrice === 'pricePerMonth' ? `${name}PerMonth` : name;

  return currency === 'GBP'
    ? `sterling${capitalizeFirstLetter(name)}_${rangeKey}`
    : `${newName}_${rangeKey}`;
};

const buildUrl = (baseUrl: string) => {
  const url =
    baseUrl.slice(-1) === '&' || baseUrl.slice(-1) === '?'
      ? baseUrl.slice(0, -1)
      : baseUrl;

  return `/${url}`;
};

export const reduceRangesForURLQuery = (
  combined: {},
  range: IGetAdsRequestRange,
) => {
  return range.to || range.from || range.currency
    ? {
        ...combined,
        ...(range.from && {
          [getRangeUrlKey(range.name, 'from', range.currency, range.typePrice)]:
            range.from,
        }),
        ...(range.to && {
          [getRangeUrlKey(range.name, 'to', range.currency, range.typePrice)]:
            range.to,
        }),
      }
    : combined;
};

export const reduceFiltersForURLQuery = (
  combined: {},
  filter: IGetAdsRequestFilter,
) => {
  return filter.values
    ? { ...combined, [filter.name]: filter.values }
    : combined;
};

const withMakeModel = (
  url: string,
  section: string,
  paramsMakeModel: {
    makeValue: string;
    modelValue: string[];
  }[],
) => {
  let urlWithMakeModel = url;
  let breadcrumbMakeModel: Array<Breadcrumb> = [];

  const paramsMakeLength = paramsMakeModel.filter(
    (item) => item.makeValue,
  ).length;

  const paramsModel = paramsMakeModel
    .map((item) => item.modelValue)
    .filter((item) => item.length);

  const paramsModelLength = paramsModel.length;

  if (paramsMakeModel && paramsMakeLength === 1 && paramsModelLength === 0) {
    const make = paramsMakeModel[0].makeValue;
    const notAllMakes = make !== 'All Makes';
    urlWithMakeModel = notAllMakes ? `${section}/${make}?` : `${section}?`;
    notAllMakes &&
      breadcrumbMakeModel.push({
        href: buildUrl(urlWithMakeModel),
        displayName: make,
      });
  } else if (
    paramsMakeModel &&
    paramsMakeLength === 1 &&
    paramsModelLength === 1 &&
    paramsModel[0].length === 1
  ) {
    const make = paramsMakeModel[0].makeValue;
    const model = paramsMakeModel[0].modelValue[0];
    urlWithMakeModel = `${section}/${make}/${model}?`;
    breadcrumbMakeModel.push(
      {
        href: buildUrl(`${section}/${make}?`),
        displayName: make,
      },
      {
        href: buildUrl(urlWithMakeModel),
        displayName: model,
      },
    );
  } else {
    let makeModelUrl = '';
    let makeNum: number = 0;
    let modelNum: number = 0;

    paramsMakeModel &&
      paramsMakeModel.forEach((makeModelItem) => {
        const hasModel = makeModelItem.modelValue.length > 0;
        const hasMake =
          makeModelItem.makeValue.length > 0 &&
          makeModelItem.makeValue !== 'All Makes';

        if (hasModel) {
          makeModelUrl = `${
            makeModelItem.makeValue
          };model:${makeModelItem.modelValue.map((modelItem) => {
            modelNum++;
            return modelItem ? modelItem : '';
          })}`;
        } else {
          makeModelUrl = makeModelItem.makeValue;
        }

        if (hasMake) {
          urlWithMakeModel += `make=${makeModelUrl}&`;
          makeNum++;
        }
      });

    if (makeNum === 1 && modelNum > 0) {
      breadcrumbMakeModel.push(
        {
          displayName: paramsMakeModel[0].makeValue,
          href: buildUrl(`${section}/${paramsMakeModel[0].makeValue}`),
        },
        {
          displayName: `${modelNum} Models`,
        },
      );
    } else if (makeNum > 0 && modelNum === 0) {
      breadcrumbMakeModel.push({
        displayName: `${makeNum} Makes`,
      });
    } else if (makeNum !== 0 && modelNum !== 0) {
      breadcrumbMakeModel.push({
        displayName: `${makeNum} Makes, ${modelNum} Models`,
      });
    }
  }
  return {
    baseUrl: urlWithMakeModel,
    breadcrumbMakeModel,
  };
};

const getFiltersUrl = (
  section: string,
  searchParams: {
    start?: string | undefined;
    words?: string | undefined;
    sort?: string | undefined;
    userId?: string | string[] | undefined;
  },
  filters: IGetAdsRequestFilter[],
  filtersMakeModel: {
    makeValue: string;
    modelValue: string[];
  }[],
  ranges: IGetAdsRequestRange[],
  countyTown?: string | null,
  radius?: string | null,
  county?: string | null,
) => {
  let { baseUrl } = withMakeModel(
    `${section}?`,
    section,
    filtersMakeModel.filter((item) => item.makeValue !== ''),
  );

  let urlThirdParam = '';
  const params = {
    area: county ?? '',
    ...searchParams,
    ...(filters.length && filters.reduce(reduceFiltersForURLQuery, {})),
    ...(ranges.length && ranges.reduce(reduceRangesForURLQuery, {})),
    countyTown,
    radius: countyTown ? radius : [],
  };
  const isFriendlyMakeModel = baseUrl.split('/').length === 3;
  const filterMake = filters.filter((item) => item.name === 'make');
  const isFriendlyMake = !isFriendlyMakeModel && filterMake.length > 0;

  // @ts-ignore
  const { area, fuelType, year_to, year_from, bodyType, colour, transmission } =
    params;

  const isArea = Boolean((area && area.length === 1) || county);
  const isFuelType = fuelType && fuelType.length === 1;
  const isBodyType = bodyType && bodyType.length === 1;
  const isColour = colour && colour.length === 1;
  const isTransmission = transmission && transmission.length === 1;

  const isYear =
    (Number(year_to) && year_to) === (Number(year_from) && year_from);

  let paramsNoMakeModel = Object.entries({
    ...params,
  });

  if (isFriendlyMakeModel) {
    const friendlyBaseUrl = baseUrl.slice(0, -1);

    function formatUrl(path: string, param: string) {
      baseUrl = `${friendlyBaseUrl}/${path}?`;
      urlThirdParam = param;
    }
    if (isYear) {
      formatUrl(year_to, 'year');
    } else if (isArea) {
      formatUrl(area, 'area');
    } else if (isFuelType) {
      formatUrl(fuelType[0], 'fuelType');
    } else if (isTransmission) {
      formatUrl(transmission[0], 'transmission');
    } else if (isBodyType) {
      formatUrl(bodyType[0], 'bodyType');
    } else if (isColour) {
      formatUrl(colour[0], 'colour');
    }
  } else if (isFriendlyMake) {
    const friendlyBaseUrl = baseUrl.slice(0, -1);
    const friendlyMake = filterMake[0].values[0];

    baseUrl = friendlyMake
      ? `${friendlyBaseUrl}/${friendlyMake}?`
      : `${friendlyBaseUrl}?`;
    urlThirdParam = 'make';
  }

  paramsNoMakeModel.forEach(([key, value]) => {
    const isKeyInFriendlyUrl =
      urlThirdParam !== '' && key.search(urlThirdParam) === 0;
    if (
      value &&
      value.length > 0 &&
      key !== urlThirdParam &&
      !isKeyInFriendlyUrl
    ) {
      if (Array.isArray(value)) {
        value.forEach((itemValue) => {
          baseUrl += `${key}=${itemValue}&`;
        });
      } else {
        baseUrl += `${key}=${value}&`;
      }
    }
  });

  return buildUrl(baseUrl);
};

const getUrlWithMakeModelEditable = (url: string, id: string) => {
  const separator = url.includes('?') ? '&' : '?';
  const updatedUrlAs = `${url}${separator}${encodeURIComponent(
    'makeModelEditable',
  )}=${encodeURIComponent(id)}`;
  return updatedUrlAs;
};

export { getFiltersUrl, getUrlWithMakeModelEditable };
