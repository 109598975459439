import { Label } from 'components/Toolkit/Inputs/Label';
import type { SectionBrowserProps } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.typed';
import { SectionBrowser } from 'components/SearchPage/components/SectionBrowser/SectionBrowser';
import { Spacer } from 'components/SearchPage/styles';

const SectionBrowserPanel = (props: SectionBrowserProps) => {
  return (
    <div>
      <Label>Section</Label>
      <SectionBrowser collapsible {...props} />
      <Spacer />
    </div>
  );
};

export { SectionBrowserPanel };
