import type { ParsedUrlQuery } from 'querystring';

import { asString } from 'utils/query-parameters';

import {
  getMultiOptionDisplay,
  getQuickFilterMultiOptionDisplayVariant,
  getFilterMultiOptionDisplayVariant,
  getPriceDisplay,
  getRangeDisplay,
  getSingleOptionDisplay,
} from 'components/SearchPage/helpers/formatting';

import type {
  ISearchPageFilter,
  ISearchPageFilterValue,
} from 'api/types/searchPageApiTypes';

const useFiltersFormatting = () => {
  const getFilterCopy = (
    filter: ISearchPageFilter,
    query: ParsedUrlQuery,
    quickFilters?: boolean,
  ) => {
    const rangeLabels = {
      from: 'From',
      to: 'To',
    };

    switch (filter.filterType.name) {
      case 'ListSingleSelect':
        return getFiltersDisplay(filter.values, query[filter.name]);
      case 'ListMultiSelect': {
        if (quickFilters) {
          return getListMultiSelectDisplay(filter, query);
        }
        return getFiltersListMultiSelectDisplay(filter, query);
      }
      case 'PriceInputRange':
        return getPriceDisplay(
          {
            price_from: asString(query.price_from || query.pricePerMonth_from),
            price_to: asString(query.price_to || query.pricePerMonth_to),
            sterlingPrice_from: asString(query.sterlingPrice_from),
            sterlingPrice_to: asString(query.sterlingPrice_to),
          },
          quickFilters ? {} : rangeLabels,
        );

      case 'DropDownRange': {
        const fromValues = filter.values ? filter.values[0].from ?? [] : [];
        const toValues = filter.values ? filter.values[0].to ?? [] : [];

        const from = getFiltersDisplay(
          fromValues,
          query[`${filter.name}_from`],
        );

        const to = getFiltersDisplay(toValues, query[`${filter.name}_to`]);

        return getRangeDisplay({ from, to }, quickFilters ? {} : rangeLabels);
      }
      case 'CheckBox':
        return getFiltersDisplay(filter.values, query[filter.name]);
      case 'TextInputRange': {
        return getRangeDisplay(
          {
            from: asString(query[`${filter.name}_from`]),
            to: asString(query[`${filter.name}_to`]),
          },
          quickFilters ? {} : rangeLabels,
        );
      }
      case 'SingleSelectRange': {
        if (quickFilters) {
          return getRangeDisplay({
            from: asString(query[`${filter.name}_from`]),
          });
        }

        const queryValueFrom = query[`${filter.name}_from`];
        const queryValueTo = query[`${filter.name}_to`];
        const queryValue = queryValueFrom ?? queryValueTo;
        const queryType = queryValueFrom ? 'MIN' : 'MAX';

        return getFiltersDisplay(filter.values, queryValue, queryType);
      }

      default:
        return undefined;
    }
  };

  const getFiltersDisplay = (
    values: Array<ISearchPageFilterValue> | null,
    queryValue: string | Array<string> | undefined,
    queryType?: 'MIN' | 'MAX',
  ) => {
    const target = asString(queryValue);
    return getSingleOptionDisplay(values ?? [], target, queryType);
  };

  const getListMultiSelectDisplay = (
    filter: ISearchPageFilter,
    query: ParsedUrlQuery,
  ) => {
    if (
      filter.variant === 'DONEDEAL_TAG' ||
      filter.variant === 'DONEDEAL_CHECKBOX' ||
      filter.variant === 'DONEDEAL_IMAGE'
    ) {
      return getQuickFilterMultiDisplayVariant(
        filter.values,
        query[filter.name],
        filter.displayName,
      );
    } else {
      return query[filter.name]
        ? getFilterMultiDisplay(filter.values ?? [], query[filter.name])
        : filter.displayName;
    }
  };

  const getFiltersListMultiSelectDisplay = (
    filter: ISearchPageFilter,
    query: ParsedUrlQuery,
  ) => {
    if (
      filter.variant === 'DONEDEAL_TAG' ||
      filter.variant === 'DONEDEAL_CHECKBOX' ||
      filter.variant === 'DONEDEAL_IMAGE'
    ) {
      return getFiltersMultiDisplayVariant(filter.values, query[filter.name]);
    } else {
      return query[filter.name]
        ? getFilterMultiDisplay(filter.values ?? [], query[filter.name])
        : undefined;
    }
  };

  const getQuickFilterMultiDisplayVariant = (
    values: Array<ISearchPageFilterValue> | null,
    queryValue: string | Array<string> | undefined,
    filterDisplayName: string,
  ) => {
    if (values) {
      const displayValue = Array.isArray(queryValue)
        ? getQuickFilterMultiOptionDisplayVariant(
            values,
            queryValue,
            filterDisplayName,
          )
        : getFiltersDisplay(values, queryValue);
      return displayValue;
    } else return undefined;
  };

  const getFiltersMultiDisplayVariant = (
    values: Array<ISearchPageFilterValue> | null,
    queryValue: string | Array<string> | undefined,
  ) => {
    if (values) {
      const displayValue = Array.isArray(queryValue)
        ? getFilterMultiOptionDisplayVariant(values, queryValue)
        : getFiltersDisplay(values, queryValue);
      return displayValue;
    } else return undefined;
  };

  const getFilterMultiDisplay = (
    values: Array<ISearchPageFilterValue> | null,
    queryValue?: string | Array<string>,
  ) => {
    if (values && queryValue) {
      const displayValue = Array.isArray(queryValue)
        ? getMultiOptionDisplay(values, queryValue)
        : getMultiOptionDisplay(values, [queryValue]);
      return displayValue;
    } else return undefined;
  };

  return {
    getFilterCopy,
  };
};

export { useFiltersFormatting };
