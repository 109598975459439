import styled from 'styled-components';

import { Card } from 'components/ToolkitV2/CardV2/Card';
import { WithLink } from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink'; // TODO: move out of card directory

import { media } from 'helpers/breakpoints';

const CarouselCard = styled(Card)`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.CONTAINER};
  overflow: hidden;
  height: 100%;
  width: 200px;

  ${media.medium} {
    width: 243px;
  }
`;

const CardTitle = styled.p`
  ${({ theme }) => theme.fontSize.M16};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MotorsLogo = styled.img`
  height: 16px;
  width: 106px;
  margin-bottom: ${({ theme }) => theme.spacing.S4};

  ${media.medium} {
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacing.S12};
  }
`;

const LogoLink = styled(WithLink)`
  display: flex;
  align-items: center;
`;

export { CarouselCard, CardTitle, MotorsLogo, LogoLink };
