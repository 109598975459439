import type {
  ISearchPageFilter,
  ISearchPageFilterValue,
} from 'api/types/searchPageApiTypes';

import { formatNumber } from 'helpers/formatting';
import { asString } from 'utils/query-parameters';

const getSingleOptionDisplay = (
  values: Array<ISearchPageFilterValue>,
  target?: string,
  queryType?: string,
) => {
  const normalizeValue = (value?: string) =>
    value ? value.replace('+', ' ') : '';

  const matchingValue = values
    ? values.find(
        ({ value, type }) =>
          normalizeValue(value) === target &&
          (type === queryType || type === undefined),
      )
    : undefined;

  return matchingValue?.displayName;
};

const getMultiOptionDisplay = (
  values: Array<ISearchPageFilterValue>,
  targets: Array<string>,
) => {
  const matchingDisplayValues = getMatchingValues(values, targets);

  return displayValueWithCount(matchingDisplayValues);
};

const getMatchingValues = (
  values: Array<ISearchPageFilterValue>,
  targets: Array<string>,
) => {
  const matchingDisplayValues = values.filter(({ value }) =>
    targets.some((target) => value === target),
  );

  return matchingDisplayValues;
};

const displayValueWithCount = (values: Array<ISearchPageFilterValue>) => {
  const [firstValue] = values;
  const multipleValuesText = firstValue
    ? `${firstValue.displayName} (+${values.length - 1})`
    : undefined;
  const singleValueText = firstValue ? firstValue.displayName : '';
  const formattedText = `${
    values.length > 1 ? multipleValuesText : singleValueText
  }`;

  return firstValue ? formattedText : undefined;
};

const getQuickFilterMultiOptionDisplayVariant = (
  values: Array<ISearchPageFilterValue>,
  targets: Array<string>,
  name: string,
) => {
  const matchingDisplayValues = getMatchingValues(values, targets);

  return displayValueWithCountVariant(matchingDisplayValues, name);
};

const getFilterMultiOptionDisplayVariant = (
  values: Array<ISearchPageFilterValue>,
  targets: Array<string>,
) => {
  const matchingDisplayValues = getMatchingValues(values, targets);

  const displayValues = matchingDisplayValues
    .map(({ displayName }) => displayName)
    .join(', ');

  return displayValues;
};

const displayValueWithCountVariant = (
  values: Array<ISearchPageFilterValue>,
  name: string,
) => {
  const [firstValue] = values;
  const multipleValuesText = `${values.length} ${name}s`;
  const singleValueText = firstValue ? firstValue.displayName : '';
  const formattedText = `${
    values.length > 1 ? multipleValuesText : singleValueText
  }`;

  return firstValue ? formattedText : undefined;
};

const getPriceDisplay = (
  args: {
    price_from?: string;
    price_to?: string;
    sterlingPrice_from?: string;
    sterlingPrice_to?: string;
  },
  labels: { from?: string; to?: string } = { from: 'Min', to: 'Max' },
) => {
  const { price_from, price_to, sterlingPrice_from, sterlingPrice_to } = args;

  if (price_from || price_to) {
    const formattedFrom = formatPrice('€', price_from);
    const formattedTo = formatPrice('€', price_to);

    return getRangeDisplay({ from: formattedFrom, to: formattedTo }, labels);
  } else if (sterlingPrice_from || sterlingPrice_to) {
    const formattedFrom = formatPrice('£', sterlingPrice_from);
    const formattedTo = formatPrice('£', sterlingPrice_to);

    return getRangeDisplay({ from: formattedFrom, to: formattedTo }, labels);
  } else return undefined;
};

const formatPrice = (currency: '€' | '£', value?: string) => {
  return value ? `${currency}${formatNumber(parseInt(value))}` : undefined;
};

const getRangeDisplay = (
  args: { from?: string; to?: string },
  labels: { from?: string; to?: string } = { from: 'Min', to: 'Max' },
) => {
  const { from, to } = args;
  const { from: fromLabel = 'Min', to: toLabel = 'Max' } = labels;

  if (from && to) {
    if (from === to) {
      return from;
    } else return `${from} - ${to}`;
  } else if (from) {
    return `${fromLabel} ${from}`;
  } else if (to) {
    return `${toLabel} ${to}`;
  } else return undefined;
};

const formatMakeAndModelQueries = (makeQuery?: string | Array<string>) => {
  if (typeof makeQuery === 'string') {
    const { make, models } = getMakeAndModelValues(makeQuery);
    return `${make}${formatModels(models)}`;
  } else if (Array.isArray(makeQuery)) {
    let modelAggregate: Array<string> = [];

    makeQuery.forEach((query) => {
      const { models } = getMakeAndModelValues(query);
      modelAggregate.push(...models);
    });

    return `${makeQuery.length} Makes${formatModels(modelAggregate)}`;
  } else return undefined;
};

const getMakeAndModelValues = (makesAndModelsQueryValue: string) => {
  const [make, modelsJoined] = makesAndModelsQueryValue.split(';model:');
  const modelsSplit = modelsJoined ? modelsJoined.split(',') : [];
  return { make, models: modelsSplit };
};

const formatModels = (models: Array<string>) => {
  const plural = models.length > 1 ? 's' : '';
  return models.length > 0 ? `, ${models.length} Model${plural}` : '';
};
const formatMakeAndModelForFilters = (
  makeQuery: string,
  models: string[] = [],
): string => {
  if (typeof makeQuery === 'string' && makeQuery.includes(';model:')) {
    const { make, models } = getMakeAndModelValues(makeQuery);
    return formatMakeAndModelForFilters(make, models);
  }

  if (!models.length) {
    return makeQuery;
  }

  const [firstModel, ...remainingModels] = models;
  const formattedMakeModel = `${makeQuery} ${firstModel}`;

  if (!remainingModels.length) {
    return formattedMakeModel;
  }

  return `${formattedMakeModel} (+${remainingModels.length})`;
};

const formatMakeAndModelQueriesForFilters = (makeQuery?: string | string[]) => {
  if (typeof makeQuery === 'string') {
    const { make, models } = getMakeAndModelValues(makeQuery);
    return formatMakeAndModelForFilters(make, models);
  }

  if (Array.isArray(makeQuery)) {
    const formattedEntries = makeQuery
      .map((entry) => formatMakeAndModelForFilters(entry))
      .filter(Boolean);
    const additionalMakesCount = makeQuery.length - formattedEntries.length;

    if (additionalMakesCount > 0) {
      formattedEntries.push(`(+${additionalMakesCount})`);
    }

    return formattedEntries;
  }

  return undefined;
};

const validateFilterValue = (
  filterName: string,
  filterValue: string,
  filtersData: Array<ISearchPageFilter>,
) => {
  const filter = filtersData.find(({ name }) => name === filterName);

  const values = filter?.values;
  const group = filter?.searchQueryGroup;
  const toValues = filter?.values?.[0]?.to;
  const fromValues = filter?.values?.[0]?.from;

  if (group === 'filters') {
    return values?.find(({ value }) => value === filterValue)?.value;
  }
  if (group === 'ranges') {
    const to = toValues?.find(({ value }) => value === filterValue)?.value;
    const from = fromValues?.find(({ value }) => value === filterValue)?.value;
    const fallback = values?.find(({ value }) => value === filterValue)?.value;

    return to ?? from ?? fallback;
  }
};

const getFilterValue = (
  filtersData: Array<ISearchPageFilter>,
  name: string,
  value?: string | Array<string>,
  formattedFilterName?: string,
  filterValue?: string,
) => {
  if (filterValue && name === formattedFilterName) {
    return [filterValue];
  }
  if (value) {
    if (Array.isArray(value)) {
      return value.flatMap(
        (item) => validateFilterValue(name, item, filtersData) ?? [],
      );
    }
    const validatedValue = validateFilterValue(name, value, filtersData);
    return validatedValue ? [validatedValue] : undefined;
  }
};

const getRangeValue = (
  filtersData: Array<ISearchPageFilter>,
  name: string,
  fromValue?: string | Array<string>,
  toValue?: string | Array<string>,
  formattedFilterName?: string,
  filterValue?: string,
) => {
  if (filterValue && name === formattedFilterName) {
    return [filterValue];
  }

  if (fromValue || toValue) {
    const from = asString(fromValue);
    const to = asString(toValue);
    const validatedFrom = from && validateFilterValue(name, from, filtersData);
    const validatedTo = to && validateFilterValue(name, to, filtersData);

    return validatedFrom || validatedTo
      ? [
          ...(validatedFrom ? [validatedFrom] : []),
          ...(validatedTo ? [validatedTo] : []),
        ]
      : undefined;
  }
};

export {
  getSingleOptionDisplay,
  getMultiOptionDisplay,
  getQuickFilterMultiOptionDisplayVariant,
  getFilterMultiOptionDisplayVariant,
  getPriceDisplay,
  getRangeDisplay,
  formatMakeAndModelQueries,
  formatMakeAndModelQueriesForFilters,
  formatMakeAndModelForFilters,
  getFilterValue,
  getRangeValue,
  validateFilterValue,
};
