import { useLocationContext } from 'features/location/Location.context';

import { useLocationShared } from 'features/location/hooks/LocationShared/LocationShared.hook';

import type {
  HandleSelectListItemArgs,
  LevelOneSelectionHandlerArgs,
  LevelTwoSelectionHandlerArgs,
  UpdateCountyTownArgs,
  UseLocationWriteURLArgs,
} from 'features/location/hooks/LocationWriteURL/LocationWriteURL.hook.typed';

import {
  DEFAULT_COUNTY_TOWN_RADIUS,
  DEFAULT_PRESELECT,
} from 'features/location/Location.constants';
import { LEVELS } from 'features/location/Location.typed';

import {
  findCountyByValue,
  findRadiusByValue,
} from 'features/location/helpers';

const useLocationWriteURL = (args: UseLocationWriteURLArgs) => {
  const { countyPathParam, applySEOPathParam, URLCallback, areaQueryValue } =
    args;

  const { countyList, closeDisplay, radiusList } = useLocationContext();

  const { handleCountySelect, handleReset } = useLocationShared();

  const URLCountyData = countyPathParam
    ? findCountyByValue(countyList, countyPathParam)
    : undefined;
  const countyPathParamValue = URLCountyData?.value;
  const makeModelCountyPath = Boolean(countyPathParamValue);

  const makeModelWithEmptyOrCountyThirdFilterParam = applySEOPathParam;

  const emptyCoorindinates = { latitude: '', longitude: '' };

  const defaultRadius = findRadiusByValue(
    DEFAULT_COUNTY_TOWN_RADIUS,
    radiusList,
  );

  const handleSelectListItem = (args: HandleSelectListItemArgs) => {
    const {
      county,
      countyDisplayName,
      countyTown,
      latitude,
      longitude,
      level,
      radius = defaultRadius,
    } = args;

    const defaultSelected = county === DEFAULT_PRESELECT.value;
    const sameCountySelected =
      county === (areaQueryValue || countyPathParamValue);

    switch (level) {
      case LEVELS.LEVEL_ONE:
        return levelOneSelectionHandler({
          defaultSelected,
          makeModelCountyPath,
          emptyCoorindinates,
          sameCountySelected,
          countyDisplayName,
          county,
        });
      case LEVELS.LEVEL_TWO:
        return levelTwoSelectionHandler({
          latitude,
          longitude,
          county,
          countyTown,
          defaultSelected,
          level,
          radius,
        });
      default:
        return;
    }
  };

  const levelOneSelectionHandler = (args: LevelOneSelectionHandlerArgs) => {
    const {
      defaultSelected,
      makeModelCountyPath,
      sameCountySelected,
      countyDisplayName,
      county,
    } = args;
    if (defaultSelected) {
      closeDisplay();
      if (makeModelCountyPath) {
        URLCallback({
          pathParam: '',
          queryParams: { countyTown: '', radius: '' },
          hiddenQueryParams: emptyCoorindinates,
        });
      } else {
        URLCallback({
          queryParams: { area: '', countyTown: '', radius: '' },
          hiddenQueryParams: emptyCoorindinates,
        });
      }
    } else if (sameCountySelected) {
      const countyData = {
        displayName: countyDisplayName ?? '',
        value: county ?? '',
      };

      handleCountySelect(countyData);
    } else if (makeModelWithEmptyOrCountyThirdFilterParam) {
      URLCallback({
        pathParam: county,
        queryParams: {
          countyTown: '',
          radius: '',
        },
        hiddenQueryParams: emptyCoorindinates,
      });
    } else {
      URLCallback({
        queryParams: {
          area: county,
          countyTown: '',
          radius: '',
        },
        hiddenQueryParams: emptyCoorindinates,
      });
    }
  };

  const levelTwoSelectionHandler = (args: LevelTwoSelectionHandlerArgs) => {
    const {
      latitude,
      longitude,
      county,
      countyTown,
      defaultSelected,
      level,
      radius,
    } = args;
    const hiddenParameters = {
      latitude: latitude ?? '',
      longitude: longitude ?? '',
    };

    const allAreasSelected =
      !countyTown && county == (areaQueryValue ?? countyPathParamValue);

    if (defaultSelected) {
      URLCallback({
        queryParams: { countyTown: '', radius: '' },
        hiddenQueryParams: emptyCoorindinates,
      });
      handleReset(level);
    } else if (allAreasSelected) {
      URLCallback({
        queryParams: { countyTown: '', radius: '' },
        hiddenQueryParams: emptyCoorindinates,
      });
      closeDisplay();
    } else if (countyTown) {
      updateCountyTown({
        county,
        countyTown,
        radius,
        hiddenParameters,
      });
      closeDisplay();
    } else {
      updateCountyTown({
        county,
        countyTown: '',
        radius: '',
        hiddenParameters: emptyCoorindinates,
      });
      closeDisplay();
      handleReset(level);
    }
  };

  const updateCountyTown = (args: UpdateCountyTownArgs) => {
    const { county, countyTown, radius, hiddenParameters } = args;

    if (makeModelWithEmptyOrCountyThirdFilterParam) {
      URLCallback({
        pathParam: county,
        queryParams: { countyTown, radius },
        hiddenQueryParams: hiddenParameters,
      });
    } else {
      URLCallback({
        queryParams: { area: county, countyTown, radius },
        hiddenQueryParams: hiddenParameters,
      });
    }
  };

  return {
    handleSelectListItem,
  };
};

export { useLocationWriteURL };
