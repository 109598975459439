import styled from 'styled-components';

import { ShieldCheckmark2Ic } from '@dsch/dd-icons';
import { media } from 'helpers/breakpoints';

const CardWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.LARGE};
  height: 100%;
  overflow: hidden;
`;

const ImageSaveAd = styled.div`
  display: block;

  ${media.large} {
    display: none;
  }
`;

const BodySaveAd = styled.div<{ isGrid: boolean }>`
  display: ${({ isGrid }) => (isGrid ? 'none' : 'block')};

  ${media.large} {
    display: block;
  }
`;

const SShieldCheckmark2Ic = styled(ShieldCheckmark2Ic)`
  margin-left: ${({ theme }) => theme.spacing.M16};
  color: ${({ theme }) => theme.colors.GREEN};
`;

const CardLink = styled.a`
  height: 100%;
`;

export { CardWrapper, ImageSaveAd, BodySaveAd, SShieldCheckmark2Ic, CardLink };
