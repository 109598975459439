import { Currency } from 'types';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';
import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';
import { PriceInputRange } from 'features/filters/components/PriceInputRange/PriceInputRange';
import { Spacer } from 'helpers/genericStyles';

export interface PriceInputRangeWithCheckboxProps {
  value?: {
    from: string | undefined;
    to: string | undefined;
    currency: Currency | undefined;
  };
  onChangePrice: (
    currency: Currency,
    from: string | undefined,
    to: string | undefined,
  ) => void;
  label?: string | null;
  checkboxLabel?: string;
  willUseSubText?: boolean;
  heightVariant?: HeightVariant;
  validPriceConfiguration?: {
    onSelectAdsWithValidPrice: (containsValidPrice: boolean) => void;
    containsValidPrice: boolean;
  };
}

const PriceInputRangeWithCheckbox = (
  props: PriceInputRangeWithCheckboxProps,
) => {
  const { validPriceConfiguration, checkboxLabel } = props;
  return (
    <>
      <PriceInputRange {...props} />
      <Spacer marginBottom={'M16'} />
      {!!validPriceConfiguration && (
        <Checkbox
          checked={validPriceConfiguration.containsValidPrice}
          onChange={validPriceConfiguration.onSelectAdsWithValidPrice}
          label={checkboxLabel}
          willUseSubText={false}
        />
      )}
    </>
  );
};

export { PriceInputRangeWithCheckbox };
