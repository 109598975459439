import { useState } from 'react';

import { SEARCH_PAGE_AD_SENSE_CONFIG } from 'configs/adSenseConfig';
import { SEARCH_PAGE_DFP_CONFIG } from 'configs/dfpConfig';
import { adSenseListingItems, dfpListingItems } from 'constants/searchPage';

import { useAdSense } from 'hooks/UseAdSense';
import { useDFP } from 'hooks/UseDFP';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';

import type { UnitProps } from 'components/Toolkit/DFP';
import type { IAdSenseUnit } from 'types';
import type { IAdSense, IDFP } from 'api/types/searchPageApiTypes';
import type { ViewType } from 'components/SearchPage/SearchPage.typed';

export interface IuseSearchPageAdUnitsProps {
  dfpSettings: IDFP;
  adSenseSettings: IAdSense;
}

export enum AD_UNIT_TYPE {
  AD_SENSE = 'AD_SENSE',
  DFP = 'DFP',
}

type AdUnitAdSense = {
  type: AD_UNIT_TYPE.AD_SENSE;
  data: IAdSenseUnit;
  hidden?: boolean;
};

type AdUnitDFP = {
  type: AD_UNIT_TYPE.DFP;
  data: UnitProps;
  hidden?: boolean;
};

export type AdUnit = AdUnitAdSense | AdUnitDFP;

function useSearchPageAdUnits(props: IuseSearchPageAdUnitsProps) {
  const {
    currentSectionQueryValue: section,
    keywordQueryValue: keywordSearchTerm,
  } = useURLStateManagement();
  const { dfpSettings, adSenseSettings } = props;
  const [hiddenDFPIds, setHiddenDFPIds] = useState<string[]>([]);
  const [hiddenAdSenseIds, setHiddenAdSenseIds] = useState<string[]>([]);

  const adSenseEnabled =
    Boolean(keywordSearchTerm) ||
    !adSenseListingItems.EXCLUDED_SECTIONS.includes(section);

  const adSense = useAdSense({
    units: [
      SEARCH_PAGE_AD_SENSE_CONFIG.bottom,
      SEARCH_PAGE_AD_SENSE_CONFIG.listing,
    ],
    dependencies: [keywordSearchTerm, section],
    onLoadContainer: onAdSenseUnitLoad,
    options: adSenseSettings,
  });
  const dfp = useDFP(dfpSettings);

  function refresh() {
    adSense.refresh();
    dfp.refresh();
  }

  function onAdSenseUnitLoad(id: string, success: boolean) {
    const itemIndex = hiddenAdSenseIds.findIndex((item) => item === id);
    if (success && itemIndex >= 0) {
      // It loaded and it is set to be hidden
      setHiddenAdSenseIds(hiddenAdSenseIds.filter((item) => item !== id));
    } else if (!success && itemIndex < 0) {
      // It didn't load and not currently hidden
      setHiddenAdSenseIds([...hiddenAdSenseIds, id]);
    }
  }

  function onDFPUnitLoad({
    event,
    slotId,
  }: {
    event: { isEmpty: boolean };
    slotId: string;
  }) {
    const itemIndex = hiddenDFPIds.findIndex((item) => item === slotId);
    if (!event.isEmpty && itemIndex >= 0) {
      // It loaded and it is set to be hidden
      setHiddenDFPIds(hiddenDFPIds.filter((item) => item !== slotId));
    } else if (event.isEmpty && itemIndex < 0) {
      // It didn't load and not currently hidden
      setHiddenDFPIds([...hiddenDFPIds, slotId]);
    }
  }

  function getListingUnit(
    index: number,
    viewType: ViewType | undefined,
  ): AdUnit | undefined {
    const mapAdSense =
      viewType === 'grid'
        ? adSenseListingItems.LISTING_GRID_VIEW
        : adSenseListingItems.LISTING_LIST_VIEW;
    const mapDFP =
      viewType === 'grid'
        ? dfpListingItems.LISTING_GRID_VIEW
        : dfpListingItems.LISTING_LIST_VIEW;

    if (adSenseEnabled) {
      if (mapAdSense.has(index)) {
        const data = mapAdSense.get(index)!;
        return {
          type: AD_UNIT_TYPE.AD_SENSE,
          data,
          hidden: hiddenAdSenseIds.includes(data.container),
        };
      }
    }

    if (mapDFP.has(index)) {
      const data = mapDFP.get(index)!;
      return {
        type: AD_UNIT_TYPE.DFP,
        data,
        hidden: hiddenDFPIds.includes(data.slotId),
      };
    }
  }

  function getBottomUnit(): AdUnit {
    if (adSenseEnabled) {
      return {
        type: AD_UNIT_TYPE.AD_SENSE,
        data: SEARCH_PAGE_AD_SENSE_CONFIG.bottom,
      };
    }
    return {
      type: AD_UNIT_TYPE.DFP,
      data: SEARCH_PAGE_DFP_CONFIG.dd_search_bottom,
    };
  }

  function getTopUnit(): UnitProps {
    return SEARCH_PAGE_DFP_CONFIG.dd_search_top;
  }

  function getSidebarUnit(): UnitProps {
    return SEARCH_PAGE_DFP_CONFIG.dd_search_left_skyscraper;
  }

  return {
    refresh,
    getListingUnit,
    getBottomUnit,
    getTopUnit,
    getSidebarUnit,
    onDFPUnitLoad,
  };
}

export { useSearchPageAdUnits };
