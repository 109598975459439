import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { donedealGateway } from 'api/api-utils/commonApiUtils';

import { useLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/hooks/useLastSearch';
import { useUserContext } from 'contexts/UserContext';
import { useAuthOptions } from 'hooks/useAuthOptions';

import { setAuthorizationHeader } from 'api/api-utils/authenticate';

import type { SavedSearch } from 'types';

import { rg4js } from 'helpers/raygun';

import type { PreviousSearchesArgs } from 'features/PreviousSearches/PreviousSearches.typed';

const usePreviousSearches = (args: PreviousSearchesArgs) => {
  const { onSearchCallback, page } = args;

  const { status, accessToken, handleLogin } = useAuthOptions();
  const { lastSearchData } = useLastSearch();
  const { user } = useUserContext();

  /*
   * We set this to true when opening the modal and false on close of the modal
   * to avoid fetching when the modal is on screen, or when there is an error.
   *
   * If there is an error, we set this to true only if the user decides to
   * refetch data again.
   *
   * This limits network requests to when the user wishes to see the data in the
   * interest of performance.
   * */
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isPreviousSearchesModalOpen, setIsPreviousSearchesModalOpen] =
    useState(false);

  const showSearch = Boolean(lastSearchData);
  const isEnabled = status === 'authenticated' || showSearch;
  const isLoading = status === 'loading';

  const getSavedSearches = async (
    url: string,
    token: string,
    userId: number,
  ): Promise<Array<SavedSearch> | null> => {
    try {
      const { data } = await donedealGateway.get(url, {
        headers: {
          Authorization: setAuthorizationHeader(token),
          'X-Identity-UserId': userId,
        },
      });

      return data;
    } catch (error) {
      rg4js('send', {
        error: new Error('Error fetching saved searches'),
        tags: [page],
        customData: {
          message: error.message || 'client_error',
        },
      });
      return null;
    }
  };

  const {
    isPending,
    data: savedSearchesData,
    refetch: onSavedSearchesError,
  } = useQuery({
    queryKey: ['previous-searches-modal-saved-searches'],
    queryFn: () => {
      if (accessToken && user?.id) {
        return getSavedSearches(
          '/ddapi/v1/users/savedsearches?page=0&size=3',
          accessToken,
          user?.id,
        );
      }
    },
    retry: false,
    /*
     * Should only fetch when user clicks on button to display previous searches modal
     * and not when the component mounts
     */
    enabled: shouldFetch,
  });

  const isSavedSearchesLoading = isPending && status === 'authenticated';
  const savedSearchesError =
    !isPending && savedSearchesData === null && status === 'authenticated';
  const savedSearches =
    savedSearchesData && savedSearchesData.length > 0
      ? savedSearchesData
      : undefined;

  const onClick = () => {
    if (isEnabled) {
      setIsPreviousSearchesModalOpen(true);
    }
    if (status === 'authenticated') {
      setShouldFetch(true);
    }
  };

  const askToClose = () => {
    setShouldFetch(false);
    setIsPreviousSearchesModalOpen(false);
  };

  const login = () => handleLogin();

  const onSearch = () => {
    askToClose();
    onSearchCallback && onSearchCallback();
  };

  return {
    isLoading,
    isDisabled: !isEnabled,
    onClick,
    isSavedSearchesLoading,
    savedSearches,
    askToClose,
    savedSearchesError,
    onSavedSearchesError,
    isOpen: isPreviousSearchesModalOpen,
    login,
    status,
    onSearch,
    lastSearchData,
  };
};

export { usePreviousSearches };
