import { useSearchPageState } from 'components/SearchPage/context/SearchPageContext';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';

import { rg4js } from 'helpers/raygun';

import type { PaginationEvent } from 'types';

const usePagination = () => {
  const { updateURL, paginationQueryValue } = useURLStateManagement();
  const {
    paging: { totalPages, pageSize, totalResults },
  } = useSearchPageState();

  const currentPage = Number(paginationQueryValue) / pageSize + 1;

  const onUpdate = (event: PaginationEvent) => {
    const { from } = event;

    const start = from < 0 ? '' : from;

    updateURL({
      queryParams: {
        start: `${start}`,
      },
    });

    if (from < 0) {
      rg4js('send', {
        error: new Error('Negative pagination value from pagination change'),
        customData: {
          start: from,
          referer: document.referrer,
          page: window.location.href,
          functionName: 'onUpdate',
        },
      });
    }
  };

  const goToStart = () => updateURL({ queryParams: { start: '' } });

  const goToEnd = () =>
    updateURL({
      queryParams: { start: `${(totalPages - 1) * 30}` },
    });

  useOnUpdateOnly(() => {
    scroll({ top: 0 });
  }, [paginationQueryValue]);

  return {
    currentPage,
    onUpdate,
    goToStart,
    goToEnd,
    totalPages,
    totalResults,
    pageSize,
  };
};

export { usePagination };
