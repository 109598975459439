import { DisplayContainer } from 'components/Support/DisplayContainer/DisplayContainer';

import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import { MakeModelProps } from 'components/SearchPage/features/makeModel/MakeModel.typed';
import { useMakeModelContext } from 'components/SearchPage/features/makeModel/MakeModelContext';
import { CustomSelect } from 'components/Toolkit/Inputs/CustomSelect';
import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';

import { ListMultiSelect } from 'features/filters/components/ListMultiSelect/ListMultiSelect';
import { AddButton } from 'components/SearchPage/features/makeModel/components/AddButton';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';

import { Spacer } from 'helpers/genericStyles';
import { useMakeModel } from 'components/SearchPage/features/makeModel/MakeModel.hook';
import { Selected } from 'components/SearchPage/features/makeModel/components/Selected/Selected';

const MakeModel = (props: MakeModelProps) => {
  const {
    mWebConfig,
    webConfig,
    selectMake: onSelectMake,
    selectModel: onSelectModel,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
    onAddSelection,
    makeOptions,
    heightVariant,
  } = props;

  const {
    isMakeModelBottomSheetOpen,
    isExpanded,
    setIsExpanded,
    makeModels,
    modelOptions,
  } = useMakeModelContext();

  const {
    addMake,
    clearMake,
    openBottomSheet,
    closeBottomSheet,
    makeValue,
    selectedModelOptions,
    addModel,
    clearModels,
    modelPlaceholder,
    editableMakeModelGroupAvailable,
    isAddAnotherDisabled,
    isModelFilterDisabled,
    mappedMakeOptions,
  } = useMakeModel({
    onSelectMake,
    onSelectModel,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
    makeOptions,
  });

  return (
    <div data-testid="make-model-dropdowns">
      {makeModels && makeModels.length > 0 && (
        <Selected
          makeModels={makeModels}
          clearSelection={clearMake}
          heightVariant={heightVariant}
        />
      )}
      {editableMakeModelGroupAvailable && (
        <>
          <DisplayContainer config={mWebConfig}>
            <InputButton
              isActive={isMakeModelBottomSheetOpen}
              value={makeValue}
              onClick={openBottomSheet}
              placeholder="Make"
              heightVariant={heightVariant}
            />
            <BottomSheet
              headerText="Make"
              askToClose={closeBottomSheet}
              isOpen={isMakeModelBottomSheetOpen}
              height="75vh"
            >
              <CustomSelect listData={mappedMakeOptions} onSelect={addMake} />
            </BottomSheet>
          </DisplayContainer>
          <DisplayContainer config={webConfig}>
            <Dropdown
              data-testid="desktop-make-dropdown"
              expanded={isExpanded}
              setExpanded={setIsExpanded}
              displayedValue={makeValue}
              placeholder="Make"
              heightVariant={heightVariant}
            >
              <CustomSelect listData={mappedMakeOptions} onSelect={addMake} />
            </Dropdown>
          </DisplayContainer>
          <Spacer marginBottom="M16" />
          {/* Model bottom sheet and dropdown combined */}
          <ListMultiSelect
            name="model_select"
            options={modelOptions ?? [{ value: '', label: 'All Models' }]}
            placeholder={modelPlaceholder}
            onSelectOption={addModel}
            onClear={clearModels}
            isDisabled={isModelFilterDisabled}
            selectedOptions={selectedModelOptions}
            heightVariant={heightVariant}
          />
          <Spacer marginBottom="M16" />
          <AddButton
            disabled={isAddAnotherDisabled}
            onClick={onAddSelection}
            size={heightVariant === 'LARGE' ? 'LARGE' : 'SMALL'}
          />
        </>
      )}
    </div>
  );
};

export { MakeModel };
