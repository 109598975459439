import { useLocationContext } from 'features/location/Location.context';

import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';

import { handlePushToDataLayerForLocationSearch } from 'features/location/helpers/Tracking';
import { findCountyByValue } from 'features/location/helpers';

const useLocationSearchTracking = () => {
  const {
    carFilterQueryValue,
    areaQueryValue,
    countyTownQueryValue,
    radiusQueryValue,
  } = useURLStateManagement();

  const {
    isLocationBottomSheetOpen,
    isExpanded: isLocationDropdownExpanded,
    countyList,
  } = useLocationContext();

  const countyPathParam = findCountyByValue(countyList, carFilterQueryValue);
  const county = countyPathParam?.value || areaQueryValue;
  const countyDisplayName = county ? `Co. ${county}` : '';

  useOnUpdateOnly(() => {
    if (!isLocationBottomSheetOpen && county) {
      handlePushToDataLayerForLocationSearch({
        county: countyDisplayName,
        countyTown: countyTownQueryValue,
        radius: radiusQueryValue,
      });
    }
  }, [isLocationBottomSheetOpen]);

  useOnUpdateOnly(() => {
    if (!isLocationDropdownExpanded && county) {
      handlePushToDataLayerForLocationSearch({
        county: countyDisplayName,
        countyTown: countyTownQueryValue,
        radius: radiusQueryValue,
      });
    }
  }, [isLocationDropdownExpanded]);
};

export { useLocationSearchTracking };
