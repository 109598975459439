import styled from 'styled-components';
import { Accordion as AccordionBase } from 'components/ToolkitV2/Accordion/Accordion';

const Accordion = styled(AccordionBase)``;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.M24};
`;

const FilterListItem = styled.li`
  :last-child {
    ${Accordion} {
      border-bottom: 0;
    }
  }
`;

const Description = styled.span<{
  isOpen: boolean;
  hasDescription: boolean;
}>`
  display: block;
  height: ${({ isOpen, hasDescription }) =>
    hasDescription ? (isOpen ? '0' : '24px') : '0'};
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  transition: ${({ isOpen }) =>
    isOpen
      ? 'height 0.2s ease, opacity ease 0.1s'
      : 'height 0.2s ease, opacity 0.1s ease 0.1s'};
`;

export { Accordion, FilterWrapper, FilterListItem, Description };
