import { RadioTabs } from 'components/Toolkit/Inputs/RadioTabs';
import { ListSingleSelectNativeOption } from 'features/filters/components/ListSingleSelectNative/ListSingleSelectNative';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';

export interface IListSingleSelectTagProps {
  name: string;
  label?: string;
  value: string;
  options: ListSingleSelectNativeOption[];
  onChange: (value: string) => void;
  className?: string;
  heightVariant?: HeightVariant;
}

function ListSingleSelectTag(props: IListSingleSelectTagProps) {
  const { options, label, name, className, onChange, value, heightVariant } =
    props;
  return (
    <>
      {options && (
        <RadioTabs
          value={value}
          name={name}
          onChange={onChange}
          tabLabel={label}
          options={options.map(({ value, label }) => ({
            value,
            displayValue: label,
          }))}
          className={className}
          heightVariant={heightVariant}
        />
      )}
    </>
  );
}

export { ListSingleSelectTag };
