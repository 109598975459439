import { useRouter } from 'next/router';

import { useMakeModelContext } from 'components/SearchPage/features/makeModel/MakeModelContext';
import { useMakeModelWriteState } from 'components/SearchPage/features/makeModel/hooks/MakeModelWriteState.hook';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';

import { asString } from 'utils/query-parameters';
import { useFiltersState } from 'features/filters/Filters.context';
import { getFilterPathParameterData } from 'helpers/seo/searchParams';

import type { MakeModelFilterProps } from 'features/filters/components/MakeModelV2/MakeModelFilter.typed';

import { MAXIMUM_AMOUNT_OF_MAKE_MODEL_GROUPS_TO_BE_DISPLAYED } from 'components/SearchPage/features/QuickFilters/Filters.constants';

type UseMakeModelFilterArgs = { SEOFilterQueryValue?: string };

const useMakeModelFilter = (args: UseMakeModelFilterArgs) => {
  const { SEOFilterQueryValue } = args;
  const { filtersData } = useFiltersState();

  const { formattedFilterName: filterName } = getFilterPathParameterData(
    filtersData,
    SEOFilterQueryValue,
  );

  const searchQueryGroup =
    filtersData.find((item) => item.name === filterName)?.searchQueryGroup ??
    'filters';

  const {
    query: { section },
  } = useRouter();

  const currentSection = asString(section) ?? 'cars';

  const { makeModels, clearMakeModels } = useMakeModelContext();

  const { selectMake, selectModel, onAddSelection } = useMakeModelWriteState({
    section: currentSection,
  });

  const { updateMakeModels } = useURLStateManagement();

  const makeModelConfiguration: MakeModelFilterProps = {
    displayLabel: false,
    selectMake,
    selectModel,
    onAddSelection,
    maximumAmountOfMakeModelGroupsToBeDisplayed:
      MAXIMUM_AMOUNT_OF_MAKE_MODEL_GROUPS_TO_BE_DISPLAYED,
  };

  const editableIndex = makeModels?.findIndex((item) => item.isEditable);
  const updatedMakeModelEditable =
    typeof editableIndex === 'number'
      ? `make_model_${editableIndex}`
      : undefined;

  const handleSelectMakeModels = () => {
    updateMakeModels({
      makeModels,
      filterName,
      searchQueryGroup,
      makeModelEditable: updatedMakeModelEditable,
    });
  };

  return {
    handleSelectMakeModels,
    makeModelConfiguration,
    clearMakeModels,
  };
};

export { useMakeModelFilter };
