import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';

import * as Skeleton from 'components/Dashboard/styles/skeleton';

import { Card } from 'components/ToolkitV2/CardV2/Card';

import type { SkeletonSearchCardProps } from 'components/SearchPage/components/Listings/components/SkeletonSearchCard/SkeletonSearchCard.typed';

function SkeletonSearchCard(props: SkeletonSearchCardProps) {
  const { isGrid } = props;

  const metaDataLength = isGrid ? 2 : 3;

  const orientationConfig = {
    orientationSmall: ORIENTATION.VERTICAL,
    orientationMedium: isGrid ? ORIENTATION.VERTICAL : ORIENTATION.HORIZONTAL,
    orientationLarge: isGrid ? ORIENTATION.VERTICAL : ORIENTATION.HORIZONTAL,
  };

  return (
    <Skeleton.Container>
      {!isGrid && <Skeleton.Header />}
      <Card
        {...orientationConfig}
        image={
          <Skeleton.CardImage
            aspectRatioAsAPercentage="75%"
            {...orientationConfig}
            widthHorizontalMedium={320}
            heightHorizontalMedium={240}
          />
        }
        header={
          <div>
            <Skeleton.BasicContainer
              mWidth="100%"
              mHeight="16px"
              height="18px"
              mb={['S2', 'S4']}
            />
            <Skeleton.MetaDataContainerVariant>
              {Array.apply(null, Array(metaDataLength)).map(
                (_item: null, index: number) => {
                  return (
                    <Skeleton.MetaDataContainerListItem
                      key={`skeleton-browsing-history-card-meta-data-${index}`}
                    >
                      <Skeleton.BasicContainer
                        mWidth="48px"
                        mHeight="12px"
                        height="14px"
                      />
                      {index < metaDataLength - 1 ? (
                        <Skeleton.Dot />
                      ) : undefined}
                    </Skeleton.MetaDataContainerListItem>
                  );
                },
              )}
            </Skeleton.MetaDataContainerVariant>
          </div>
        }
        paddingSmall={isGrid ? 'S4' : 'S8'}
        paddingMedium={isGrid ? 'S8' : 'M16'}
        fillContainer={false}
        gapSmall={isGrid ? 'S4' : 'S8'}
        gapMedium={isGrid ? 'M32' : undefined}
        footer={
          <Skeleton.BasicContainer
            mWidth="31px"
            mHeight="12px"
            width="64px"
            height="24px"
          />
        }
      />
    </Skeleton.Container>
  );
}

export { SkeletonSearchCard };
