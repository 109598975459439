import styled from 'styled-components';

import { Button } from 'components/Toolkit/Button/Button';

const SelectButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M16}`};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export { SelectButton };
