function getPlatform() {
  let platform = 'Desktop';
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent,
    )
  ) {
    platform = 'Mobile Web';
  }
  return platform;
}

export { getPlatform };
