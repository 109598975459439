import type { HeightVariant } from 'components/Toolkit/Inputs/types';
import { Select } from 'components/Toolkit/Inputs/Select';

export type ListSingleSelectNativeOption = {
  value: string;
  label: string;
};

export interface IListSingleSelectNativeProps {
  name: string;
  label?: string;
  value: string;
  options: ListSingleSelectNativeOption[];
  onChange: (value: string) => void;
  className?: string;
  heightVariant?: HeightVariant;
  willUseSubText?: boolean;
}

function ListSingleSelectNative({
  options,
  label,
  name,
  className,
  onChange,
  value,
  heightVariant = 'DEFAULT',
  willUseSubText = true,
}: IListSingleSelectNativeProps) {
  return (
    <>
      {options && (
        <Select
          label={label}
          className={className}
          onChange={onChange}
          name={name}
          value={value}
          options={options}
          heightVariant={heightVariant}
          willUseSubText={willUseSubText}
          placeholderAsOption={true}
        />
      )}
    </>
  );
}

export { ListSingleSelectNative };
