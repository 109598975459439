import { Fragment } from 'react';

import { CloseOutlineIc } from '@dsch/dd-icons';

import { SelectButton } from 'components/SearchPage/features/makeModel/components/Selected/Selected.styled';

import type { MakeModel } from 'components/SearchPage/features/makeModel/MakeModel.typed';

import { Spacer } from 'helpers/genericStyles';
import { theme } from 'helpers/Theme';
import { accessibleOnClick } from 'utils/Accessibility';
import { HeightVariant } from 'components/Toolkit/Inputs/types';

type SelectedProps = {
  makeModels: Array<MakeModel>;
  clearSelection: (makeValue: string) => void;
  heightVariant?: HeightVariant;
};

const Selected = (props: SelectedProps) => {
  const { makeModels, clearSelection, heightVariant } = props;

  return (
    <>
      {makeModels.map(
        ({ make, model, isEditable }) =>
          make &&
          !isEditable && (
            <Fragment key={make}>
              <SelectButton
                ofType="SECONDARY"
                size={heightVariant === 'LARGE' ? 'LARGE' : 'SMALL'}
              >
                {make}&nbsp;
                {model && model.length > 0 ? model[0] : ' (All Models)'}
                {model && model.length > 1 ? ` (+${model.length - 1})` : ''}
                <CloseOutlineIc
                  height={16}
                  width={16}
                  color={theme.colors.GREY_DARKER}
                  {...accessibleOnClick(
                    () => clearSelection(make),
                    'button',
                    0,
                    'close',
                  )}
                />
              </SelectButton>
              <Spacer marginBottom="M16" />
            </Fragment>
          ),
      )}
    </>
  );
};

export { Selected };
