import { GTMEvent } from 'services/gtmService';
import type { NotificationFrequency } from 'components/SearchPage/hooks/useSavedSearch/useSavedSearch.typed';
import { pushToDataLayer } from 'services/gtmService';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';

enum Events {
  SEARCH_PAGE = 'search-page',
  CREATE_SAVED_AD = 'create_saved_ad',
  DELETE_SAVED_AD = 'delete_saved_ad',
  CREATE_SAVED_SEARCH = 'create_saved_search',
}

const GTM_Alert_Types = {
  DAILY: 'daily_alerts',
  INSTANT: 'instant_alerts',
  OFF: 'no_alerts',
};

const useSearchPageGTM = (gtmData: GTMEvent) => {
  const pushData = (event: string, customData?: GTMEvent) => {
    if (customData) {
      pushToDataLayer({ ...gtmData, ...customData, event });
    } else {
      pushToDataLayer({ ...gtmData, event });
    }
  };

  const searchPageEvent = (data?: GTMEvent) => {
    try {
      pushData(Events.SEARCH_PAGE, data);
    } catch (e) {
      rg4js('send', {
        error: new Error('GTM'),
        tags: [PAGE.SEARCH],
        customData: {
          data: data || null,
          functionName: 'searchPageEvent',
          info: e,
        },
      });
    }
  };

  const createSavedAdEvent = (adId: number, data?: GTMEvent) => {
    try {
      pushData(Events.CREATE_SAVED_AD, { savedAdId: [adId], ...data });
    } catch (e) {
      rg4js('send', {
        error: new Error('GTM'),
        customData: {
          adId: adId,
          data: data || null,
          functionName: 'createSavedAdEvent',
          info: e,
        },
      });
    }
  };

  const deleteSavedAdEvent = (data?: GTMEvent) => {
    try {
      pushData(Events.DELETE_SAVED_AD, data);
    } catch (e) {
      rg4js('send', {
        error: new Error('GTM'),
        customData: {
          data: data || null,
          functionName: 'deleteSavedAdEvent',
          info: e,
        },
      });
    }
  };

  const createSavedSearchEvent = (
    alertType: NotificationFrequency,
    data?: GTMEvent,
  ) => {
    try {
      pushData(Events.CREATE_SAVED_SEARCH, {
        alertType: [GTM_Alert_Types[alertType]],
        ...data,
      });
    } catch (e) {
      rg4js('send', {
        error: new Error('GTM'),
        customData: {
          data: data || null,
          alertType: alertType,
          functionName: 'createSavedSearchEvent',
          info: e,
        },
      });
    }
  };

  return {
    searchPageEvent,
    createSavedAdEvent,
    deleteSavedAdEvent,
    createSavedSearchEvent,
  };
};

export { useSearchPageGTM };
