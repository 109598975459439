import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';

import { useEffect, useState } from 'react';

const useValidPriceFilter = () => {
  const { containsValidPriceQueryValue } = useURLStateManagement();
  const [containsValidPrice, setContainsValidPrice] = useState(
    containsValidPriceQueryValue === 'true',
  );

  useEffect(() => {
    setContainsValidPrice(containsValidPriceQueryValue === 'true');
  }, [containsValidPriceQueryValue, setContainsValidPrice]);

  const clearValidPrice = () => {
    setContainsValidPrice(false);
  };

  const setValidPrice = (value: boolean) => {
    setContainsValidPrice(value);
  };

  return {
    setValidPrice,
    clearValidPrice,
    containsValidPrice,
    getValidPriceQueryParam: () => ({
      containsValidPrice: containsValidPrice ? 'true' : undefined,
    }),
  };
};

export { useValidPriceFilter };
