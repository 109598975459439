import type { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import type { Param } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';

enum ACTION_TYPE {
  SELECT_QUICK_FILTER = 'SELECT_QUICK_FILTER',
  ON_CLOSE = 'ON_CLOSE',
  ON_CHANGE_FILTER_VALUE = 'ON_CHANGE_FILTER_VALUE',
  ON_CLOSE_FILTER_DISPLAY = 'ON_CLOSE_FILTER_DISPLAY',
}

type SelectQuickFilterType = {
  type: ACTION_TYPE.SELECT_QUICK_FILTER;
  payload: ISearchPageFilter;
};

type OnCloseFilterDisplayType = {
  type: ACTION_TYPE.ON_CLOSE_FILTER_DISPLAY;
};

type OnChangeFilterValueType = {
  type: ACTION_TYPE.ON_CHANGE_FILTER_VALUE;
  payload: {
    queryParam: Param;
    filterValue: string | Array<string> | undefined;
  };
};

type StateType = {
  isFilterBottomSheetOpen: boolean;
  currentFilter?: ISearchPageFilter;
  selectedFilterValue?: string | Array<string>;
  selectedFilterQueryParam?: Param;
};

type ActionType =
  | SelectQuickFilterType
  | OnCloseFilterDisplayType
  | OnChangeFilterValueType;

export { ACTION_TYPE };
export type { StateType, ActionType };
