/* Filter types that may be path parameters when a make and model is applied.
Referenced to transform query parameters to path parameters and vice versa
on make model update */
const MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES = [
  'year',
  'bodyType',
  'fuelType',
  'colour',
  'transmission',
  'area',
];

export { MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES };
