import { Ad, ISearchPageAds } from 'api/types/searchPageApiTypes';
import getConfig from 'next/config';
const {
  publicRuntimeConfig: { DISPLAY_NEW_AD_CARDS },
} = getConfig();

export const getVehicleSchemaLD = (
  adsV2: Ad[],
  ads: ISearchPageAds[],
  section: string,
  title: string,
) => {
  const formatPriceWithoutComma = (price: string) => price.replace(',', '');
  const formatPriceToNumber = (price: string) =>
    Number(formatPriceWithoutComma(price));

  const sortAds =
    ads &&
    ads.length > 0 &&
    ads
      .filter((item) => item.price)
      .sort(
        (a, b) => formatPriceToNumber(a.price) - formatPriceToNumber(b.price),
      );

  const sortAdsV2 =
    adsV2 &&
    adsV2.length > 0 &&
    adsV2
      .filter((item) => item.priceInfo?.price)
      .sort(
        (a, b) =>
          formatPriceToNumber(a.priceInfo?.price) -
          formatPriceToNumber(b.priceInfo?.price),
      );

  const getAdsMinPrice = () =>
    sortAds && sortAds[0] && formatPriceWithoutComma(sortAds[0].price);

  const getAdsMinPriceV2 = () =>
    sortAdsV2 &&
    sortAdsV2[0] &&
    formatPriceWithoutComma(sortAdsV2[0].priceInfo?.price);

  const getAdsMaxPrice = () =>
    sortAds &&
    sortAds.length > 0 &&
    sortAds[sortAds.length - 1] &&
    formatPriceWithoutComma(sortAds[sortAds.length - 1].price);

  const getAdsMaxPriceV2 = () =>
    sortAdsV2 &&
    sortAdsV2.length > 0 &&
    sortAdsV2[sortAdsV2.length - 1] &&
    formatPriceWithoutComma(sortAdsV2[sortAdsV2.length - 1].priceInfo?.price);

  const getCondition = () => {
    return section === 'new-cars' ? 'New' : 'UsedCondition';
  };

  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Vehicle',
    name: title,
    model: '',
    itemCondition: getCondition(),
    brand: {
      '@type': 'Thing',
      name: '',
    },
    offers: {
      '@type': 'AggregateOffer',
      lowPrice:
        DISPLAY_NEW_AD_CARDS === 'true' ? getAdsMinPriceV2() : getAdsMinPrice(),
      highPrice:
        DISPLAY_NEW_AD_CARDS === 'true' ? getAdsMaxPriceV2() : getAdsMaxPrice(),
      priceCurrency: 'EUR',
      availability: 'In Stock',
    },
  });
};
