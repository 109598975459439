const mapMakeOptions = (
  all?: Array<{ value?: string; displayName?: string }>,
  popular?: Array<{ value?: string; displayName?: string }>,
) => {
  const allOptions = all?.map((item) => {
    return {
      value: item.value ?? '',
      displayName: item.displayName ?? '',
    };
  });

  const popularOptions = popular?.map((item) => {
    return {
      value: item.value ?? '',
      displayName: item.displayName ?? '',
    };
  });

  const options = [
    { value: '', displayName: 'All Makes' },
    ...(popularOptions && popularOptions.length > 0
      ? [
          { value: '', displayName: 'Popular' },
          ...popularOptions,
          { value: '', displayName: 'All Makes' },
        ]
      : []),
    ...(allOptions ?? []),
  ];

  return options;
};

const mapDisabledOptions = (
  options: Array<{ value?: string; displayName?: string }>,
  selectedMakes?: Array<string>,
) => {
  return options.map((item) => {
    return {
      value: item.value ?? '',
      displayName: item.displayName ?? '',
      isDisabled: item.value ? selectedMakes?.includes(item.value) : false,
    };
  });
};

const mapModelOptions = (
  all?: Array<{ value?: string; displayName?: string }>,
  popular?: Array<{ value?: string; displayName?: string }>,
) => {
  const mappedOptions = [
    { value: '', displayName: 'All Models' },
    ...(popular && popular.length > 0
      ? [
          { value: '', displayName: 'Popular' },
          ...popular,
          { value: '', displayName: 'All Models' },
        ]
      : []),
    ...(all ?? []),
  ].map((item) => {
    return {
      value: item.value,
      label: item.displayName,
    };
  });

  return mappedOptions;
};

const mapMakeModelQuery = (makeModelValue: string, isEditable?: boolean) => {
  const [make, model] = makeModelValue.split(';');
  const modelValue = model ? model.split('model:') : [];

  const mappedMakeAndModels = {
    make,
    model: modelValue[1]?.split(','),
    isEditable,
  };

  return mappedMakeAndModels;
};

/*
 *  We may map make model values on the server. In this case, we pass type-compliant
 *  null values rather than undefined
 */
const mapServerMakeModels = (
  make?: string,
  model?: string,
  makeQuery?: string | Array<string> | null,
): Array<{ make: string; model: Array<string> | null }> | undefined => {
  if (make) {
    if (model) {
      return [{ make, model: [model] }];
    }
    return [{ make, model: null }];
  }
  if (Array.isArray(makeQuery)) {
    return makeQuery.map((item) => {
      const mappedQuery = mapMakeModelQuery(item, false);
      return { ...mappedQuery, model: mappedQuery.model ?? null };
    });
  }
  if (typeof makeQuery === 'string') {
    const mappedQuery = mapMakeModelQuery(makeQuery, false);
    return mappedQuery
      ? [{ ...mappedQuery, model: mappedQuery.model ?? null }]
      : undefined;
  }
  return undefined;
};

export {
  mapMakeOptions,
  mapDisabledOptions,
  mapModelOptions,
  mapMakeModelQuery,
  mapServerMakeModels,
};
