import { searchPageApi } from 'api/searchPageApi';

import { useMakeModelContext } from 'components/SearchPage/features/makeModel/MakeModelContext';

import { mapModelOptions } from 'components/SearchPage/features/makeModel/MakeModel.map';

import { fireToast } from 'helpers/Toasts';

type MakeModelSharedArgs = { section: string };

const useMakeModelShared = (args: MakeModelSharedArgs) => {
  const { section } = args;

  const { addModelOptions } = useMakeModelContext();

  const getModels = async (makeValue: string) => {
    try {
      const {
        data: { all, popular },
      } = await searchPageApi.getModelByMake(section, makeValue);
      const mappedOptions = mapModelOptions(all, popular);
      addModelOptions(mappedOptions);
    } catch (_error) {
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
    }
  };

  return {
    getModels,
  };
};

export { useMakeModelShared };
