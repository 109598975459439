import { useCallback, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { savedSearchApi } from 'api/savedSearches';

import { useSearchPageState } from 'components/SearchPage/context/SearchPageContext';
import { useUserContext } from 'contexts/UserContext';
import { useFiltersState } from 'features/filters/Filters.context';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { useBrowserStorage } from 'hooks/UseBrowserStorage';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { useFilterCount } from 'components/SearchPage/hooks/useFilterCount/useFilterCount';

import { Link } from 'components/Toolkit/Button/Link';

import { fireToast } from 'helpers/Toasts';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { formatAuthorizationHeader } from 'helpers/auth';
import { findCountyByValue } from 'features/location/helpers';
import { API_CLIENT_TIMEOUT } from 'utils';
import { USER_MENU } from 'components/Layouts/components/UserMenu';
import { PAGE_SIZE } from 'components/SearchPage/constants';

import { asString } from 'utils/query-parameters';
import {
  generateGetAdsRequestParams,
  getSectionParams,
} from 'helpers/seo/searchParams';
import {
  formatMakeModelQueryName,
  generateSaveSearchTerms,
} from 'components/SearchPage/hooks/useSavedSearch/formatting';
import {
  mapGeoFilter,
  mapGeoFilterRequest,
  mapLegacyAreaFilterQueryValues,
  mapRangesRequest,
} from 'features/filters/Filters.mapper';
import { formatSearchTerms } from 'helpers/formatSearchTerms';

import {
  NotificationFrequency,
  NOTIFICATION_FREQUENCY,
  SAVED_SEARCH_STATES,
} from 'components/SearchPage/hooks/useSavedSearch/useSavedSearch.typed';
import type { SavedSearchStates } from 'components/SearchPage/hooks/useSavedSearch/useSavedSearch.typed';

const useSavedSearch = (sectionDisplayName: string) => {
  const { user, setIsSavedSearchModalOpen, setIsInstantAlertAvailable } =
    useUserContext();
  const { sortFields, filtersData } = useFiltersState();
  const { handleLogin, status, accessToken } = useAuthOptions();
  const { query } = useRouter();
  const MODAL_SESSION_STORAGE_ITEM = 'search-results-saved-search-modal';
  const { section, ...rest } = query;

  const [currentSection, make, model, SEOFilter] = Array.isArray(section)
    ? section
    : [];
  const {
    make: makeQueryValue,
    words,
    sort,
    countyTown: countyTownQueryValue,
    area: areaQueryValue,
    radius: radiusQueryValue,
    latitude: latitudeQueryValue,
    longitude: longitudeQueryValue,
    ...filtersAndRanges
  } = rest;
  const formattedSection = asString(currentSection) ?? 'all';
  const keywordSearchTerm = asString(words) ?? '';
  const sortValue = asString(sort) ?? sortFields?.[0]?.name ?? '';
  const carFilterQueryValue = asString(SEOFilter);
  const countyTown = asString(countyTownQueryValue) ?? null;
  const area = asString(areaQueryValue) ?? null;
  const radius = asString(radiusQueryValue);
  const latitude = asString(latitudeQueryValue);
  const longitude = asString(longitudeQueryValue);

  const SEARCH_TERMS_LENGTH = 5;
  const CREATE_SAVED_SEARCH_API_TIMEOUT = 500;
  const SESSION_STORAGE_SIGNIN_KEY = 'signin';

  const { saved: searchPageSaved } = useSearchPageState();
  const { count } = useFilterCount();

  const [savedState, setSavedState] = useState<SavedSearchStates>(
    searchPageSaved ? SAVED_SEARCH_STATES.SAVED : SAVED_SEARCH_STATES.UNSAVED,
  );

  const saved = savedState === SAVED_SEARCH_STATES.SAVED;
  const saving = savedState === SAVED_SEARCH_STATES.LOADING;
  const isSavedSearchDisabled = saved || count === 0;

  const {
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem,
  } = useBrowserStorage();

  const generateSavedSearchName = useCallback(() => {
    if (make) {
      const modelDisplay = ` ${model}`;
      return `${make}${model ? modelDisplay : ''} in ${sectionDisplayName}`;
    } else if (makeQueryValue) {
      return `${formatMakeModelQueryName(
        makeQueryValue,
      )} in ${sectionDisplayName}`;
    } else if (keywordSearchTerm) {
      return `${keywordSearchTerm} in ${sectionDisplayName}`;
    } else {
      return sectionDisplayName;
    }
  }, [sectionDisplayName, keywordSearchTerm, make, model, makeQueryValue]);

  const initialName = generateSavedSearchName();

  const [savedSearchName, setSavedSearchName] = useState(initialName);
  const [selected, setSelected] = useState<NotificationFrequency>(
    NOTIFICATION_FREQUENCY.INSTANT,
  );

  const { isMakeModel, makeModelParams, countyList, sectionQueryParams } =
    getSectionParams(filtersData, query);

  const countyPathParam = findCountyByValue(countyList, carFilterQueryValue);

  const county = countyPathParam?.value ?? area;

  const savedSearchTerms = generateSaveSearchTerms({
    sectionDisplayName,
    make,
    model,
    carFilterQueryValue,
    makeQueryValue,
    countyPathParam,
    keywordSearchTerm,
    county,
    countyTown,
    radius,
    filtersAndRanges,
    filtersData,
    sortValue,
    sortFields,
    searchTermsLength: SEARCH_TERMS_LENGTH,
  });

  const mapSearchPayload = useCallback(() => {
    const locationData = mapGeoFilter({
      radius,
      latitude,
      longitude,
      county,
      countyTown,
    });

    const geoFilter = locationData?.geoFilter;

    const updatedQueryValues = mapLegacyAreaFilterQueryValues(
      query,
      geoFilter?.county,
      geoFilter?.countyTown,
    );

    const getAdsRequestParams = generateGetAdsRequestParams(
      currentSection,
      {
        ...updatedQueryValues,
        ...sectionQueryParams,
      },
      filtersData,
      query.userId,
    );

    const payload = {
      decayFunctions: [],
      geoFilter: mapGeoFilterRequest(geoFilter),
      ...getAdsRequestParams,
      ...(getAdsRequestParams.ranges
        ? { ranges: mapRangesRequest(getAdsRequestParams.ranges) }
        : {}),
      ...(isMakeModel && {
        makeModelFilters: makeModelParams,
      }),
      sort: sortValue,
      terms: keywordSearchTerm,
      sections: [formattedSection],
      scoringRanges: [],
      scoringFilters: [],
      notFilters: [],
      paging: { pageSize: PAGE_SIZE, from: 0 },
    };

    return payload;
  }, [
    county,
    countyTown,
    currentSection,
    filtersData,
    formattedSection,
    keywordSearchTerm,
    latitude,
    longitude,
    query,
    radius,
    sortValue,
    isMakeModel,
    makeModelParams,
    sectionQueryParams,
  ]);

  const savedSearchPrecheck = useCallback(
    async (userId: string) => {
      try {
        const { data } = await savedSearchApi.searchPagePreCheck(
          userId,
          mapSearchPayload(),
          formatAuthorizationHeader(accessToken),
          API_CLIENT_TIMEOUT,
        );
        if (data.instantAlert) {
          setIsInstantAlertAvailable(true);
          setSelected(NOTIFICATION_FREQUENCY.INSTANT);
        } else {
          setIsInstantAlertAvailable(false);
          setSelected(NOTIFICATION_FREQUENCY.DAILY);
        }
        setIsSavedSearchModalOpen(true);
      } catch (error) {
        rg4js('send', {
          error: new Error('Error handling handleOnClickSave'),
          tags: [PAGE.SEARCH],
          customData: {
            message: error.message || 'client_error',
          },
        });
        fireToast({
          type: 'ERROR',
          text: 'Oops! Something went wrong, please try again later',
        });
      }
    },
    [
      accessToken,
      setIsInstantAlertAvailable,
      setIsSavedSearchModalOpen,
      mapSearchPayload,
    ],
  );

  const handleOnClickSave = useCallback(async () => {
    if (user?.id && status === 'authenticated') {
      savedSearchPrecheck(user.id.toString());
    } else {
      setSessionStorageItem(
        SESSION_STORAGE_SIGNIN_KEY,
        MODAL_SESSION_STORAGE_ITEM,
      );
      handleLogin();
    }
  }, [
    user?.id,
    status,
    handleLogin,
    setSessionStorageItem,
    savedSearchPrecheck,
  ]);

  const createSavedSearch = async (args: {
    name: string;
    terms: Array<string>;
    notifications: NotificationFrequency;
    userId: number;
  }) => {
    const { name, terms, notifications, userId } = args;

    const payload = mapSearchPayload();

    try {
      setSavedState(SAVED_SEARCH_STATES.LOADING);
      await savedSearchApi.createSavedSearch(
        {
          name,
          description: formatSearchTerms(terms),
          frequency: notifications,
          search: payload,
        },
        userId.toString(),
        CREATE_SAVED_SEARCH_API_TIMEOUT,
        formatAuthorizationHeader(accessToken),
        API_CLIENT_TIMEOUT,
      );
      setSavedState(SAVED_SEARCH_STATES.SAVED);
      setIsSavedSearchModalOpen(false);
      fireToast({
        type: 'SUCCESS',
        text: (
          <>
            Search Saved! Remember you can manage your preferences in&nbsp;
            <Link
              href={`/dashboard/${USER_MENU.SEARCHES}`}
              ofType="SECONDARY"
              inline
              underline
            >
              My DoneDeal
            </Link>
          </>
        ),
      });
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling createSavedSearch'),
        tags: [PAGE.SEARCH],
        customData: {
          message: error.message || 'client_error',
        },
      });
      fireToast({
        type: 'ERROR',
        text: 'Oops! something went wrong, please try again later',
      });
      setSavedState(SAVED_SEARCH_STATES.UNSAVED);
    }
  };

  useOnUpdateOnly(() => {
    if (saved) {
      setSavedState(SAVED_SEARCH_STATES.UNSAVED);
    }
  }, [query]);

  useMemo(() => {
    setSavedSearchName(generateSavedSearchName());
  }, [generateSavedSearchName]);

  /*
   * If the user tries to save a search while logged out, we record this and open
   * the modal when they are returned, successfully authenticated, to the page
   */
  useEffect(() => {
    if (
      getSessionStorageItem(SESSION_STORAGE_SIGNIN_KEY) ===
      MODAL_SESSION_STORAGE_ITEM
    ) {
      if (status === 'authenticated' && user?.id) {
        savedSearchPrecheck(user.id.toString());
      }
      if (status !== 'loading') {
        removeSessionStorageItem('signin');
      }
    }
  }, [
    getSessionStorageItem,
    removeSessionStorageItem,
    savedSearchPrecheck,
    status,
    user?.id,
  ]);

  return {
    handleOnClickSave,
    savedSearchTerms,
    isSavedSearchDisabled,
    savedSearchName,
    setSavedSearchName,
    createSavedSearch,
    saved,
    saving,
    selected,
    setSelected,
  };
};

export { useSavedSearch };
