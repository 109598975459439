import { pushToDataLayer } from 'services/gtmService';

function useRecommendedAdsCarousel() {
  const handlePushToDataLayerOnClick = (position: number) => {
    pushToDataLayer({
      event: 'search_results_carousel_ad_click',
      ad_position: position,
    });
  };

  return { handlePushToDataLayerOnClick };
}

export { useRecommendedAdsCarousel };
