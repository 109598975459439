import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import type { Param } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';

import { asFrom, asString, asTo } from 'utils/query-parameters';

import { MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES } from 'components/SearchPage/features/QuickFilters/Filters.constants';

type HandleSEOParameterArgs = {
  filterName?: string;
  filterGroup?: string;
  /**
   * A reference to the filter as a key and its value formatted as a query.
   * Note that the format of the reference may differ from the
   * name of the filter, especically for ranges
   * e.g. the name may be `year` but the key is `year_from` or `year_to`
   */
  queryParams?: Param;
};

/*
 * A hook used for formatting values of those filters used for SEO
 */
const useSEOUpdates = () => {
  const { updateURL, carFilterQueryValue, makeQueryValue, modelQueryValue } =
    useURLStateManagement();

  const checkForSEOFilter = (
    filterName?: string | Array<string>,
    SEOFilterName?: string,
  ) =>
    Boolean(
      (!carFilterQueryValue || SEOFilterName === filterName) &&
        typeof filterName === 'string' &&
        MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES.includes(filterName) &&
        makeQueryValue &&
        modelQueryValue,
    );

  const handleSEOPathParameter = (args: HandleSEOParameterArgs) => {
    const { queryParams, filterGroup, filterName } = args;

    const SEOFilterQueryValue =
      queryParams && filterName ? queryParams[filterName] : '';

    const SEORangeFromValue =
      queryParams && filterName
        ? asString(queryParams[`${filterName}_from`])
        : '';

    const SEORangeToValue =
      queryParams && filterName
        ? asString(queryParams[`${filterName}_to`])
        : '';

    if (filterGroup === 'filters') {
      handleSEOPathParameterFilter(
        filterName,
        queryParams,
        SEOFilterQueryValue,
      );
    } else if (filterGroup === 'ranges') {
      handleSEOPathParameterRange(
        filterName,
        SEORangeFromValue,
        SEORangeToValue,
        queryParams,
      );
    }
  };

  const handleSEOPathParameterFilter = (
    filterName?: string,
    queryParams?: Param,
    SEOFilterQueryValue?: string | Array<string> | number,
  ) => {
    if (typeof SEOFilterQueryValue === 'string' && filterName) {
      updateURL({
        pathParam: SEOFilterQueryValue,
        queryParams: { [filterName]: '' },
      });
    } else {
      updateURL({
        pathParam: '',
        queryParams,
      });
    }
  };

  const handleSEOPathParameterRange = (
    filterName?: string,
    from?: string,
    to?: string,
    queryParams?: Param,
  ) => {
    if (from && to && from === to && filterName) {
      updateURL({
        pathParam: from,
        queryParams: { [asFrom(filterName)]: '', [asTo(filterName)]: '' },
      });
    } else {
      updateURL({
        pathParam: '',
        queryParams,
      });
    }
  };

  return {
    checkForSEOFilter,
    handleSEOPathParameter,
  };
};

export { useSEOUpdates };
export type { HandleSEOParameterArgs };
