import { useRouter } from 'next/router';
import type { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';

import { MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES } from 'features/filters/Filters.constants';

import { serialise } from 'helpers/formatSearchTerms';

export type Query = string | ParsedUrlQueryInput | null | undefined;
export type Param = { [key: string]: string | Array<string> | undefined };

const useURLStateManagement = () => {
  const { query, push } = useRouter();
  const {
    section,
    sort,
    words,
    start,
    area,
    countyTown,
    radius,
    latitude,
    longitude,
    containsValidPrice,
    ...filtersQuery
  } = query;

  const [
    currentSectionQueryValue,
    makeQueryValue,
    modelQueryValue,
    carFilterQueryValue,
  ] = Array.isArray(section) ? section : [];

  const makePathParameter = makeQueryValue ? `/${makeQueryValue}` : undefined;
  const modelPathParameter =
    makeQueryValue && modelQueryValue ? `/${modelQueryValue}` : undefined;
  const carFilterPathParameter = carFilterQueryValue
    ? `/${carFilterQueryValue}`
    : undefined;

  const formatQueryValue = (queryValue?: string | string[]) => {
    return queryValue
      ? Array.isArray(queryValue)
        ? queryValue[0]
        : queryValue
      : '';
  };

  const sortQueryValue = formatQueryValue(sort);
  const keywordQueryValue = formatQueryValue(words);
  const paginationQueryValue = formatQueryValue(start);
  const radiusQueryValue = formatQueryValue(radius);
  const countyTownQueryValue = formatQueryValue(countyTown);
  const areaQueryValue = formatQueryValue(area);
  const latitudeQueryValue = formatQueryValue(latitude);
  const longitudeQueryValue = formatQueryValue(longitude);
  const containsValidPriceQueryValue = formatQueryValue(containsValidPrice);

  const filterQueryValues = Object.keys(filtersQuery).map((queryKey) => {
    return filtersQuery[queryKey];
  });

  /** Return an object that contains all the keys in
   * @param combinedFilterQueries with truthy values */
  const removeEmptyKeys = (combinedFilterQueries: Param) => {
    return Object.keys(combinedFilterQueries).reduce(
      (accumulatedQueries: Param, key) => {
        if (combinedFilterQueries[key]) {
          accumulatedQueries[key] = combinedFilterQueries[key];
        }
        return accumulatedQueries;
      },
      {},
    );
  };

  /** Return an object that contains all the key value pairs in
   * @param queryKeyValuePairs that do not appear in
   * @param hiddenQueryParams */
  const filterQueryKeyValuePairsForDisplay = (
    queryKeyValuePairs: Param,
    hiddenQueryParams?: Param,
  ) => {
    return Object.keys(queryKeyValuePairs).reduce(
      (accumulatedQueries: Param, key) => {
        if (
          hiddenQueryParams &&
          !Object.keys(hiddenQueryParams).includes(key)
        ) {
          accumulatedQueries[key] = queryKeyValuePairs[key];
        }
        return accumulatedQueries;
      },
      {},
    );
  };
  const updateURL = ({
    queryParams,
    pathParam,
    hiddenQueryParams,
  }: {
    queryParams?: Param;
    pathParam?: string;
    hiddenQueryParams?: Param;
  }) => {
    const { fallbackFilterName, fallbackFilterValue } =
      retrieveFallbackSEOFilterValue();

    const fallbackSEOFilterValue = fallbackFilterValue
      ? `/${fallbackFilterValue}`
      : '';

    const removePathParameter =
      typeof pathParam === 'string' && pathParam.length == 0;

    const updatedCarFilterPathParameter = removePathParameter
      ? fallbackSEOFilterValue
      : (pathParam && `/${pathParam}`) || carFilterPathParameter;

    const pathname = `/${currentSectionQueryValue}${makePathParameter ?? ''}${
      modelPathParameter ?? ''
    }${updatedCarFilterPathParameter ?? ''}`;

    const { section, latitude, longitude, makeModelEditable, ...rest } = query;

    /* If a make and model is applied, the path parameter is being removed
    and a query value that may be a SEO path parameter is available (fallbackFilterName),
    we remove that query since it has already been updated to be a path parameter */
    const formattedQueryValues =
      makePathParameter &&
      modelPathParameter &&
      removePathParameter &&
      fallbackFilterName &&
      fallbackFilterValue
        ? removeQuery(rest, fallbackFilterName)
        : rest;

    const combinedFilterQueries: Param = {
      latitude,
      longitude,
      makeModelEditable,
      ...formattedQueryValues,
      ...hiddenQueryParams,
      start: '',
      ...queryParams,
    };

    const filteredQueryKeyValuePairsForTruthyValues = removeEmptyKeys(
      combinedFilterQueries,
    );

    const displayQuery = filterQueryKeyValuePairsForDisplay(
      filteredQueryKeyValuePairsForTruthyValues,
      {
        latitude,
        longitude,
        makeModelEditable,
        ...hiddenQueryParams,
      },
    );

    const url = `${pathname}${
      Object.keys(filteredQueryKeyValuePairsForTruthyValues).length
        ? serialise(filteredQueryKeyValuePairsForTruthyValues)
        : ''
    }`;
    const as = `${pathname}${
      Object.keys(displayQuery).length ? serialise(displayQuery) : ''
    }`;

    push(url, as, {
      shallow: true,
    });
  };

  const resetURL = () => {
    const pathname = `/${currentSectionQueryValue}`;

    push(
      {
        pathname,
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  /** Update make and model values by (shallowly) pushing to the URL,
   * updating the path parameter if required. If no make or model is provided
   * then the path parameter must be converted to a query parameter, which requires the
   * name of that filter.
   * @param makeModels the values to be displayed in the URL
   * @param filterName the name of the filter whose value is the current SEO path paramter
   * @param searchQueryGroup the type of filter applied as a path parameter. Determines how the value should
   * be formatted if the filter used as a path parameter needs to be converted to a query
   **/
  const updateMakeModels = (args: {
    makeModels?: Array<{
      make?: string;
      model?: Array<string>;
    }>;
    filterName?: string;
    searchQueryGroup?: string;
    makeModelEditable?: string;
  }) => {
    const { filterName, makeModels, searchQueryGroup, makeModelEditable } =
      args;

    const { makePathParameter, modelPathParameter } =
      formatMakeModelPathParamters(makeModels);

    const { SEOQueryName, SEOQueryValue, SEOPathParameterAsQuery } =
      SEOFormatting({
        make: makePathParameter,
        model: modelPathParameter,
        filterName,
        searchQueryGroup,
      });

    const formattedQueryParameterAsSEOPathParameter = SEOQueryValue
      ? `/${SEOQueryValue}`
      : '';

    const updatedFilterPathParameter =
      makePathParameter && modelPathParameter
        ? carFilterPathParameter ?? formattedQueryParameterAsSEOPathParameter
        : '';

    const pathname = `/${currentSectionQueryValue}${makePathParameter}${modelPathParameter}${updatedFilterPathParameter}`;

    const mappedMakeModels = makeModels?.map((makeModel) => {
      const formattedModels =
        makeModel.model && makeModel.model.length > 0
          ? formatModelQuery(makeModel.model)
          : '';

      return `${makeModel.make}${formattedModels}`;
    });

    const { section, ...rest } = query;
    const spreadQueryWithMakeModelEditable = {
      ...rest,
      ...(makeModelEditable && makeModels && makeModels.length > 0
        ? { makeModelEditable }
        : { makeModelEditable: undefined }),
    };

    const formattedQueryWithMakeModel =
      makePathParameter || !makeModels
        ? removeQuery(spreadQueryWithMakeModelEditable, 'make')
        : {
            ...spreadQueryWithMakeModelEditable,
            make: mappedMakeModels,
          };

    const formattedQueryWithMakeModelFilterValue = SEOPathParameterAsQuery
      ? { ...formattedQueryWithMakeModel, ...SEOPathParameterAsQuery }
      : removeQuery(formattedQueryWithMakeModel, SEOQueryName);

    const displayQuery = filterQueryKeyValuePairsForDisplay(
      formattedQueryWithMakeModelFilterValue,
      {
        latitude: latitudeQueryValue,
        longitude: longitudeQueryValue,
        makeModelEditable,
      },
    );

    const url = `${pathname}${
      Object.keys(formattedQueryWithMakeModelFilterValue).length
        ? serialise(formattedQueryWithMakeModelFilterValue)
        : ''
    }`;
    const as = `${pathname}${
      Object.keys(displayQuery).length ? serialise(displayQuery) : ''
    }`;

    push(url, as, {
      shallow: true,
    });
  };

  const SEOFormatting = (args: {
    make: string;
    model?: string;
    filterName?: string;
    searchQueryGroup?: string;
  }) => {
    const { make, model, filterName, searchQueryGroup = 'filters' } = args;

    const formatSEOPathParameterAsQuery = Boolean(
      makeQueryValue && modelQueryValue && (!make || !model),
    );
    const addSEOPathParameter = Boolean(
      (makeQueryValue ?? make) && (modelQueryValue ?? model),
    );

    const rangeQuery = {
      [`${filterName}_from`]: carFilterQueryValue,
      [`${filterName}_to`]: carFilterQueryValue,
    };
    const filterQuery = filterName
      ? { [filterName]: carFilterQueryValue }
      : undefined;
    const pathAsQuery =
      searchQueryGroup === 'ranges' ? rangeQuery : filterQuery;

    const SEOPathParameterAsQuery =
      formatSEOPathParameterAsQuery && filterName ? pathAsQuery : undefined;

    const { queryName: SEOQueryName, queryValue: SEOQueryValue } =
      formatSEOPathParameterAsQuery || addSEOPathParameter
        ? filterForQueryParameter(MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES)
        : { queryName: '', queryValue: '' };

    // Verify that a valid SEO Query name and value have been returned
    const validatedSEOQueryName =
      typeof SEOQueryValue === 'string' ? SEOQueryName : undefined;
    const validatedSEOQueryValue =
      typeof SEOQueryValue === 'string' ? SEOQueryValue : undefined;

    return {
      SEOQueryName: validatedSEOQueryName,
      SEOQueryValue: validatedSEOQueryValue,
      SEOPathParameterAsQuery,
    };
  };

  const filterForQueryParameter = (targets: Array<string>) => {
    const [queryName] = Object.keys(query).filter((key) => {
      return targets.find((target) => target === key);
    });

    return { queryName: queryName, queryValue: query[queryName] };
  };

  const removeQuery = (query: ParsedUrlQuery, target?: string) => {
    const updatedQuery = { ...query };
    if (target) {
      delete updatedQuery[target];
    }

    return updatedQuery;
  };

  const retrieveFallbackSEOFilterValue = () => {
    const fallbackKey = Object.keys(query).find((key) =>
      MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES.includes(key),
    );
    const fallbackFilterName = fallbackKey ?? '';
    const unformattedFallbackFilterValue = fallbackKey
      ? query[fallbackKey]
      : '';
    const fallbackFilterValue =
      typeof unformattedFallbackFilterValue === 'string'
        ? unformattedFallbackFilterValue
        : '';

    return {
      fallbackFilterName,
      fallbackFilterValue,
    };
  };

  const formatMakeModelPathParamters = (
    makeModels?: Array<{
      make?: string;
      model?: Array<string>;
    }>,
  ) => {
    const displayMakePathParameter =
      makeModels?.length === 1 &&
      makeModels[0].make &&
      (!makeModels?.[0].model || makeModels[0].model?.length <= 1);

    const displayModelParameter =
      makeModels?.length === 1 && makeModels[0].model?.length === 1;

    const makePathParameter = displayMakePathParameter
      ? `/${makeModels[0].make}`
      : '';

    const modelPathParameter = displayModelParameter
      ? `/${makeModels[0].model?.[0]}`
      : '';

    return { makePathParameter, modelPathParameter };
  };

  const formatModelQuery = (model: Array<string>) => {
    return `;model:${model.join(',')}`;
  };

  return {
    currentSectionQueryValue,
    makeQueryValue,
    modelQueryValue,
    carFilterQueryValue,
    filterQueryValues,
    sortQueryValue,
    keywordQueryValue,
    paginationQueryValue,
    areaQueryValue,
    countyTownQueryValue,
    radiusQueryValue,
    latitudeQueryValue,
    longitudeQueryValue,
    containsValidPriceQueryValue,
    updateURL,
    updateMakeModels,
    resetURL,
  };
};

export { useURLStateManagement };
