import { useState } from 'react';

import { SectionBrowser } from 'components/SearchPage/components/SectionBrowser/SectionBrowser';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';

import type { SectionBrowserProps } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.typed';

const SectionBrowserDropdown = (props: SectionBrowserProps) => {
  const {
    currentSection: { displayName },
  } = props;

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState<boolean>(false);

  const openBottomSheet = () => setIsBottomSheetOpen(true);
  const closeBottomSheet = () => setIsBottomSheetOpen(false);

  return (
    <div>
      <InputButton
        onClick={openBottomSheet}
        placeholder={displayName}
        isActive={isBottomSheetOpen}
      />
      <BottomSheet
        headerText={displayName}
        onApply={closeBottomSheet}
        askToClose={closeBottomSheet}
        isOpen={isBottomSheetOpen}
      >
        <SectionBrowser
          collapsible={false}
          onSelect={closeBottomSheet}
          {...props}
        />
      </BottomSheet>
    </div>
  );
};

export { SectionBrowserDropdown };
