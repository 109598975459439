import type { UseSortParams } from 'components/SearchPage/components/Sort/Sort.typed';

const useSort = (params: UseSortParams) => {
  const { updateURL, selected, sortFields } = params;
  const [fallbackOption] = sortFields;

  const selectedValueDisplay =
    sortFields.find(({ name }) => name === selected)?.displayName ??
    fallbackOption.displayName;

  const labelText = `Sort by: ${selectedValueDisplay}`;
  const labelMobileElement = selectedValueDisplay;

  const items = sortFields.map(({ name, displayName }) => ({
    onClick: () => updateURL({ queryParams: { sort: name } }),
    displayName,
  }));

  const mobileItems = items.map((item) => ({
    ...item,
    dataTracking: `sort-${item.displayName}`,
  }));

  return { labelText, labelMobileElement, items, mobileItems };
};

export { useSort };
