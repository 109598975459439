import { useRef } from 'react';
import { InfoTitle } from 'components/Toolkit/InfoTitle/InfoTitle';
import { useFilter } from 'features/filters/components/Filter/Filter.hook';
import { useFiltersDispatch } from 'features/filters/Filters.context';
import * as Styled from 'features/filters/components/Filter/Filter.styled';
import { Spacer } from 'helpers/genericStyles';
import type { FilterProps } from 'features/filters/components/Filter/Filter.typed';
import { FiltersV2 } from 'features/filters/FiltersV2';

const Filter = (props: FilterProps) => {
  const {
    item,
    locationConfiguration,
    makeModelConfiguration,
    validPriceConfiguration,
    sectionName,
    onChange,
    filterValue,
    toggleAccordionState,
    isOpen,
    showDivider,
  } = props;
  const { displayText } = useFiltersDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const { selectedOptions, selectedOptionDisplayText } = useFilter({
    item,
    ref,
    displayText,
  });

  return (
    <Styled.FilterListItem>
      <Styled.Accordion
        key={item.id}
        ref={ref}
        header={
          <InfoTitle
            title={item.displayName}
            description={
              selectedOptionDisplayText && (
                <Styled.Description
                  isOpen={isOpen}
                  hasDescription={!!selectedOptions.length}
                >
                  {selectedOptionDisplayText}
                </Styled.Description>
              )
            }
          />
        }
        headerSpacer={<Spacer marginBottom={isOpen ? 'S8' : 'M16'} />}
        bodySpacer={<Spacer />}
        isOpen={isOpen}
        onChange={() => toggleAccordionState(item.name)}
        showDivider={showDivider}
        content={
          <Styled.FilterWrapper>
            <FiltersV2
              filter={{ ...item, onChange, value: filterValue }}
              locationConfiguration={locationConfiguration}
              makeModelConfiguration={makeModelConfiguration}
              validPriceConfiguration={validPriceConfiguration}
              sectionName={sectionName}
            />
          </Styled.FilterWrapper>
        }
      />
    </Styled.FilterListItem>
  );
};

export { Filter };
