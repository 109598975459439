import styled, { css } from 'styled-components';
import { GhostButton } from 'components/Toolkit/Button';
import { media } from 'helpers/breakpoints';

export const ViewTypeButtons = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.S4};
`;

export const Mobile = styled.div`
  position: relative;
  display: flex;
  ${media.medium} {
    display: none;
  }
`;

export const Desktop = styled.div`
  display: none;
  ${media.medium} {
    display: flex;
  }
`;

export const WrapperIcon = styled.div`
  margin-right: ${({ theme }) => `${theme.spacing.S4}`};
`;

export const Text = styled.div`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  cursor: pointer;
  margin-left: ${({ theme }) => `${theme.spacing.S8}`};
`;

export const ViewTypeButton = styled(GhostButton)<{ active: boolean }>`
  color: ${({ theme }) => theme.colors.GREY_LIGHT};
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.BLUE};
    `};
`;
