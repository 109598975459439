const CAR_FILTER_LIMIT = 9;
const COMMERCIALS_FILTER_LIMIT = 6;
const FILTER_LIMIT = 3;

/* Filter types that may be path parameters if a make and model
is already applied, excluding area. Referenced in order to determine
when to format values as path parameters on push to URL */
const MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES = [
  'year',
  'bodyType',
  'fuelType',
  'colour',
  'transmission',
];

const MAXIMUM_AMOUNT_OF_MAKE_MODEL_GROUPS_TO_BE_DISPLAYED = 3;

export {
  CAR_FILTER_LIMIT,
  COMMERCIALS_FILTER_LIMIT,
  FILTER_LIMIT,
  MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES,
  MAXIMUM_AMOUNT_OF_MAKE_MODEL_GROUPS_TO_BE_DISPLAYED,
};
