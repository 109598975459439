import type { QuickFilterProps } from 'components/SearchPage/features/QuickFilters/components/QuickFilter/QuickFilter.typed';

import { useQuickFilter } from 'components/SearchPage/features/QuickFilters/components/QuickFilter/QuickFilter.hook';

import * as Styled from 'components/SearchPage/features/QuickFilters/components/QuickFilter/QuickFilter.styled';

const QuickFilter = (props: QuickFilterProps) => {
  const { filter, displayText } = props;

  const { ref, onClick, quickFilterID } = useQuickFilter({
    filter,
  });

  const isSelected = displayText !== filter.displayName;

  return (
    <Styled.FilterButton
      ofType="SECONDARY"
      onClick={onClick}
      inline
      ref={ref}
      isSelected={isSelected}
      data-tracking={quickFilterID}
      data-testid={quickFilterID}
    >
      {displayText}
    </Styled.FilterButton>
  );
};

export { QuickFilter };
