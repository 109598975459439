import styled from 'styled-components';
import { CheckboxBase } from 'components/ToolkitV2/Inputs/CheckboxBase/CheckboxBase';

const Checkbox = styled(CheckboxBase)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.S4};
  padding: ${({
    theme: {
      spacing: { S12, S8 },
    },
  }) => `${S12} ${S8}`};
  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.GREY_LIGHTER : theme.colors.WHITE};
  border: ${({ theme, checked }) =>
    checked
      ? `2px solid ${theme.colors.GREY_DARKER}`
      : `1px solid ${theme.colors.GREY}`};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }
  &:has(:focus-visible) {
    outline: 2px solid ${({ theme }) => theme.colors.BLUE};
  }
`;

const Image = styled.img`
  height: 24px;
  max-width: unset;
`;

const Text = styled.p<{ isSelected: boolean }>`
  ${({ theme: { fontWeight, fontSize, colors }, isSelected }) => `
  ${fontSize.M16}
  font-weight: ${isSelected ? fontWeight.bold : fontWeight.regular};
  color: ${colors.GREY_DARKER};
  text-wrap: nowrap;
  `};
`;

export { Checkbox, Image, Text };
