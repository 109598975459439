import styled from 'styled-components';

import { media } from 'helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.M32};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.OFFWHITE};

  ${media.medium} {
    padding-top: ${({ theme }) => theme.spacing.M24};
    padding-bottom: ${({ theme }) => theme.spacing.M16};
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

export const Simg = styled.img`
  width: 64px;
  height: 64px;
  margin-right: ${({ theme }) => theme.spacing.S12};
  ${media.large} {
    width: 88px;
    height: 88px;
    margin-right: ${({ theme }) => theme.spacing.M24};
    margin-left: ${({ theme }) => theme.spacing.M16};
  }
`;

export const Header = styled.h2`
  ${({ theme }) => theme.fontSize.L18};
  margin-bottom: ${({ theme }) => theme.spacing.S4};
  ${media.large} {
    margin-bottom: ${({ theme }) => theme.spacing.S8};
    ${({ theme }) => theme.fontSize.H32};
  }
`;

export const SubText = styled.p`
  ${({ theme }) => theme.fontSize.M16};

  ${media.large} {
    ${({ theme }) => theme.fontSize.L18};
  }
`;
