import { SEARCH_PAGE_AD_SENSE_CONFIG } from 'configs/adSenseConfig';
import { SEARCH_PAGE_DFP_CONFIG } from 'configs/dfpConfig';
import type { UnitProps } from 'components/Toolkit/DFP';

export const MOTOR_SECTIONS = [
  'motor',
  'cars',
  'commercials',
  'plantmachinery',
  'vintagecars',
  'motorbikes',
  'damagedcars',
  'boats',
  'boatextras',
  'campers',
  'caravans',
  'carextras',
  'alloyswheels',
  'tyres',
  'caraccessories',
  'carparts',
  'coaches',
  'modifiedcars',
  'motorbikeextras',
  'othermotor',
  'quads',
  'rallycars',
  'scooters',
  'trailers',
  'trucks',
  'vintagebikes',
];

export const dfpListingItems = {
  LISTING_LIST_VIEW: new Map<number, UnitProps>([
    [4, SEARCH_PAGE_DFP_CONFIG.dd_search_5],
    [6, SEARCH_PAGE_DFP_CONFIG.dd_search_native],
    [9, SEARCH_PAGE_DFP_CONFIG.dd_search_10_dfp],
    [15, SEARCH_PAGE_DFP_CONFIG.dd_search_14],
    [21, SEARCH_PAGE_DFP_CONFIG.dd_search_22],
  ]),
  LISTING_GRID_VIEW: new Map<number, UnitProps>([
    [6, SEARCH_PAGE_DFP_CONFIG.dd_search_native],
    [12, SEARCH_PAGE_DFP_CONFIG.dd_search_5],
    [18, SEARCH_PAGE_DFP_CONFIG.dd_search_10_dfp],
    [24, SEARCH_PAGE_DFP_CONFIG.dd_search_14],
  ]),
};

export const adSenseListingItems = {
  EXCLUDED_SECTIONS: ['all', 'motor'],
  LISTING_LIST_VIEW: new Map([[9, SEARCH_PAGE_AD_SENSE_CONFIG.listing]]),
  LISTING_GRID_VIEW: new Map([[18, SEARCH_PAGE_AD_SENSE_CONFIG.listing]]),
};
