import styled from 'styled-components';

import { StarOutlineIc } from '@dsch/dd-icons';

const SaveIcon = styled(StarOutlineIc)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.GREY : theme.colors.WHITE};
`;

export { SaveIcon };
