import { useReducer } from 'react';

import {
  ACTION_TYPE,
  MakeModel,
  UseMakeModelReducerProps,
} from 'components/SearchPage/features/makeModel/MakeModel.typed';
import type {
  ActionType,
  StateType,
} from 'components/SearchPage/features/makeModel/MakeModel.typed';

import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';

const makeModelReducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case ACTION_TYPE.SET_IS_EXPANDED:
      return {
        ...state,
        isExpanded: action.payload,
      };
    case ACTION_TYPE.SET_IS_MAKE_MODEL_BOTTOM_SHEET_OPEN:
      return {
        ...state,
        isMakeModelBottomSheetOpen: action.payload,
      };
    case ACTION_TYPE.HANDLE_CLOSE:
      return {
        ...state,
      };
    case ACTION_TYPE.CLEAR_MAKE_MODELS:
      return {
        ...state,
        makeModels: undefined,
        modelOptions: undefined,
      };
    case ACTION_TYPE.CLOSE_DISPLAY:
      return {
        ...state,
        isExpanded: false,
        isMakeModelBottomSheetOpen: false,
      };
    case ACTION_TYPE.ADD_MODEL_OPTIONS:
      return {
        ...state,
        modelOptions: action.payload,
        isExpanded: false,
        isMakeModelBottomSheetOpen: false,
      };
    case ACTION_TYPE.SET_MAKE_MODELS:
      return {
        ...state,
        makeModels: action.payload,
      };
    default:
      return state;
  }
};

const initialState: StateType = {
  isExpanded: false,
  isMakeModelBottomSheetOpen: false,
};

const useMakeModelReducer = (props: UseMakeModelReducerProps) => {
  const { initialMakeModels } = props;

  const [state, dispatch] = useReducer(makeModelReducer, {
    ...initialState,
    makeModels: initialMakeModels,
  });

  const setIsExpanded = (isExpanded: boolean) =>
    dispatch({
      type: ACTION_TYPE.SET_IS_EXPANDED,
      payload: isExpanded,
    });

  const setIsMakeModelBottomSheetOpen = (isMakeModelBottomSheetOpen: boolean) =>
    dispatch({
      type: ACTION_TYPE.SET_IS_MAKE_MODEL_BOTTOM_SHEET_OPEN,
      payload: isMakeModelBottomSheetOpen,
    });

  const handleClose = (areas: ListItem[]) =>
    dispatch({
      type: ACTION_TYPE.HANDLE_CLOSE,
      payload: areas,
    });

  const closeDisplay = () =>
    dispatch({
      type: ACTION_TYPE.CLOSE_DISPLAY,
    });

  const clearMakeModels = () => {
    dispatch({
      type: ACTION_TYPE.CLEAR_MAKE_MODELS,
    });
  };

  const addModelOptions = (
    models?: Array<{
      value: string;
      label: string;
    }>,
  ) => {
    dispatch({
      type: ACTION_TYPE.ADD_MODEL_OPTIONS,
      payload: models,
    });
  };

  const setMakeModels = (makeModels?: Array<MakeModel>) => {
    dispatch({
      type: ACTION_TYPE.SET_MAKE_MODELS,
      payload: makeModels,
    });
  };

  return {
    isExpanded: state.isExpanded,
    isMakeModelBottomSheetOpen: state.isMakeModelBottomSheetOpen,
    makeModels: state.makeModels,
    modelOptions: state.modelOptions,
    setIsExpanded,
    setIsMakeModelBottomSheetOpen,
    handleClose,
    clearMakeModels,
    closeDisplay,
    addModelOptions,
    setMakeModels,
  };
};

export { useMakeModelReducer };
