import { useRouter } from 'next/router';

import { useSearchPageState } from 'components/SearchPage/context/SearchPageContext';

import type { SearchPageBreadcrumbs } from 'api/types/searchPageApiTypes';
import type { Breadcrumb } from 'components/ToolkitV2/Breadcrumbs/Breadcrumbs.typed';

import { formatMakeAndModelQueries } from 'components/SearchPage/helpers/formatting';

const useBreadcrumbs = () => {
  const { baseUrl, breadcrumbs } = useSearchPageState();

  const {
    query: { make, section },
  } = useRouter();

  const [
    currentSection,
    makePathParameter,
    modelPathParameter,
    carFilterPathParameter,
  ] = Array.isArray(section) ? section : [];

  const FILTER_TERM = 'Cars & Motor';

  /* We are trying to fix a legacy issue with how the site was set up originally.
  Cars and motor was a logical category, however it is not ideal for SEO. 

  We remove motors because it is not an important page
  and cars is an important page. We want all of the cars pages
  to link to cars to create a "subsection" of the site for cars. 

  Linking to motors does not bring us directly to those pages. We would
  prefer to reserve as much of the "crawl budget" as possible for cars pages.

  These pages are ultimately what we consider the most important and where we want
  to direct traffic from search engines. Crawlers will spend more time on these 
  pages and may then rank them higher in search results. */
  const filterBreadcrumbs = (breadcrumbs: Array<SearchPageBreadcrumbs>) => {
    return breadcrumbs.filter(
      (item, index) =>
        item.displayName !== FILTER_TERM || index === breadcrumbs.length - 1,
    );
  };

  /** Removing the last link is standard practice because breadcrumbs
  are not supposed to allow you to navigate to the page that you are on,
  for many reasons, including being bad UX.

  There are some breadcrumbs that might be non-standard, where 
  the final link is not to the current page, and so in these cases
  it might make sense to link to it. 
  
  The main rule is "never link to the current page" */
  const removeLinkFromLastBreadcrumb = (breadcrumbs: Array<Breadcrumb>) => [
    ...breadcrumbs.slice(0, -1),
    {
      ...breadcrumbs[breadcrumbs.length - 1],
      href: undefined,
    },
  ];

  const mapBreadcrumbs = (breadcrumbs: Array<SearchPageBreadcrumbs>) => {
    const baseBreadcrumbs = [
      { displayName: 'Home', href: '/' },
      ...(breadcrumbs &&
        filterBreadcrumbs(breadcrumbs).map(({ name, displayName }) => ({
          href: `/${name}`,
          displayName,
        }))),
    ];

    const SEOParameters = makePathParameter
      ? [makePathParameter, modelPathParameter, carFilterPathParameter].filter(
          (item) => item,
        )
      : undefined;

    const SEOBreadcrumbs =
      SEOParameters && !make
        ? SEOParameters.map((param, index) => {
            return {
              href: `/${currentSection}/${SEOParameters.slice(
                0,
                index + 1,
              ).join('/')}`,
              displayName: param,
            };
          })
        : [];

    const multipleMakesAndModels =
      make && !makePathParameter ? formatMakeAndModelQueries(make) : undefined;

    const multipleMakesAndModelsBreadcrumb = multipleMakesAndModels
      ? [{ displayName: multipleMakesAndModels }]
      : [];

    const combinedBreadcrumbs: Array<Breadcrumb> = [
      ...baseBreadcrumbs,
      ...SEOBreadcrumbs,
      ...multipleMakesAndModelsBreadcrumb,
    ];

    return combinedBreadcrumbs;
  };

  const mappedBreadcrumbs = mapBreadcrumbs(breadcrumbs);
  const formattedBreadcrumbs = removeLinkFromLastBreadcrumb(mappedBreadcrumbs);

  const breadcrumbJsonLdItems = mappedBreadcrumbs.map(
    ({ displayName, href }, index) => {
      return {
        position: index + 1,
        name: displayName,
        item: `${baseUrl}${href ?? ''}`,
      };
    },
  );

  return {
    formattedBreadcrumbs,
    breadcrumbJsonLdItems,
  };
};

export { useBreadcrumbs };
