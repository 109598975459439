import { useLocationReadURL } from 'features/location/hooks/LocationReadURL/LocationReadURL.hook';
import { useLocationWriteURL } from 'features/location/hooks/LocationWriteURL/LocationWriteURL.hook';

import { LEVELS } from 'features/location/Location.typed';
import type { CountyData } from 'features/location/Location.typed';
import { separateValues } from 'utils/CombineAndSeparateValues';
import { useLocationContext } from 'features/location/Location.context';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { findCountyByValue } from 'features/location/helpers';
import type { UseLocationReadAndWriteURLArgs } from 'features/location/hooks/LocationReadAndWriteURL/LocationReadAndWriteURL.hook.typed';

const useLocationReadAndWriteURL = (args: UseLocationReadAndWriteURLArgs) => {
  const {
    areaQueryValue,
    countyTownQueryValue,
    SEOPathParam,
    radiusQueryValue,
    URLCallback,
    applySEOPathParam,
  } = args;

  const { level, countyList } = useLocationContext();

  const includesCountyPathParam = findCountyByValue(
    countyList,
    SEOPathParam,
  )?.value;

  const applyCountyPathParam = Boolean(
    applySEOPathParam && (!SEOPathParam || includesCountyPathParam),
  );

  const countyPathParam = includesCountyPathParam ? SEOPathParam : undefined;
  const countyQueryValue = areaQueryValue !== '' ? areaQueryValue : undefined;

  const { handleCountyQueryValueChange, handleCountyTownQueryValueChange } =
    useLocationReadURL({
      countyPathParam,
      countyQueryValue,
      countyTownQueryValue,
    });

  const { handleSelectListItem } = useLocationWriteURL({
    countyPathParam,
    applySEOPathParam: applyCountyPathParam,
    URLCallback,
    areaQueryValue,
  });

  const handleSelect = (countyData: CountyData) => {
    const { displayName, value } = countyData;

    const [latitude, longitude, countyTown, countyValue] =
      value && level === LEVELS.LEVEL_TWO ? separateValues(value) : [];

    const county = level == LEVELS.LEVEL_TWO ? countyValue : value;

    handleSelectListItem({
      latitude,
      longitude,
      county: county ?? value,
      countyTown,
      countyDisplayName: displayName,
      level,
    });
  };

  useOnUpdateOnly(() => {
    if (!countyTownQueryValue) {
      /** If there is no county town query value available, we proceed with the regular
       * process of selecting a new county on change of the county URL parameter, which may
       * be a query or a path parameter. Changes to the county town are handled below.
       */
      handleCountyQueryValueChange();
    }
  }, [areaQueryValue, includesCountyPathParam]);

  useOnUpdateOnly(() => {
    handleCountyTownQueryValueChange(radiusQueryValue);
  }, [countyTownQueryValue, radiusQueryValue]);

  return { handleSelect, applyCountyPathParam, countyPathParam };
};

export { useLocationReadAndWriteURL };
