import type { StickyFooterProps } from 'components/ToolkitV2/StickyFooter/StickyFooter.typed';

import { Container } from 'components/ToolkitV2/StickyFooter/StickyFooter.styled';

const StickyFooter = (props: StickyFooterProps) => {
  const { children, className } = props;

  return <Container className={className}>{children}</Container>;
};

export { StickyFooter };
