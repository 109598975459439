import { truncateSearchTerms } from 'helpers/formatSearchTerms';
import { thousandSeparated } from 'helpers/formatting';
import { asString } from 'utils/query-parameters';

import type {
  ISearchPageFilter,
  ISearchPageFilterValue,
  ISortField,
  TSearchPageFilterValueCategory,
} from 'api/types/searchPageApiTypes';
import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import type { ParsedUrlQuery } from 'querystring';

const formatMakeModelQueryName = (makeModelValue?: string | Array<string>) => {
  if (typeof makeModelValue === 'string') {
    const [make] = makeModelValue.split(';model:');
    return make;
  } else if (Array.isArray(makeModelValue)) {
    const [make] = makeModelValue[0].split(';');
    const countDisplay =
      makeModelValue.length - 1 > 0 ? ` (+${makeModelValue.length - 1})` : '';

    return `${make}${countDisplay}`;
  } else return '';
};

const formatMakeModelQueryTerm = (makeModelValue: string) => {
  const [make, models] = makeModelValue.split(';model:');
  const [model, ...rest] = models ? models.split(',') : [];
  const additionalModels = rest.length > 0 ? ` (+${rest.length})` : '';
  const withModels = model ? ` ${model}${additionalModels}` : ' (All Models)';
  return `${make}${withModels}`;
};

const generateSaveSearchTerms = (args: {
  sectionDisplayName: string;
  make: string;
  model: string;
  carFilterQueryValue?: string;
  makeQueryValue?: string | Array<string>;
  countyPathParam?: ListItem;
  keywordSearchTerm: string;
  county: string | null;
  countyTown: string | null;
  radius?: string;
  filtersAndRanges: ParsedUrlQuery;
  filtersData: Array<ISearchPageFilter>;
  sortValue: string;
  sortFields: Array<ISortField>;
  searchTermsLength: number;
}) => {
  const {
    sectionDisplayName,
    make,
    model,
    carFilterQueryValue,
    makeQueryValue,
    countyPathParam,
    keywordSearchTerm,
    county,
    countyTown,
    radius,
    filtersAndRanges,
    filtersData,
    sortValue,
    sortFields,
    searchTermsLength,
  } = args;
  const terms: Array<string> = [sectionDisplayName];
  const callback = (term: string) => terms.push(term);

  if (make || makeQueryValue) {
    formatMakeModels({
      make,
      model,
      carFilterQueryValue,
      countyPathParam,
      makeQueryValue,
      callback,
    });
  }

  if (keywordSearchTerm) {
    terms.push(`'${keywordSearchTerm}'`);
  }

  if (county) {
    if (countyTown && radius) {
      terms.push(`${county}, ${countyTown} (+${radius}km)`);
    } else terms.push(`${county} (All Areas)`);
  }

  if (filtersAndRanges) {
    formatFiltersAndRanges({ filtersAndRanges, filtersData, callback });
  }

  if (sortValue) {
    const sortDisplayName = sortFields.find((item) => item.name === sortValue);
    terms.push(`${sortDisplayName?.displayName}`);
  }

  return truncateSearchTerms(terms, searchTermsLength);
};

const formatMakeModels = (args: {
  make: string;
  model: string;
  carFilterQueryValue?: string;
  makeQueryValue?: string | Array<string>;
  countyPathParam?: ListItem;
  callback: (term: string) => void;
}) => {
  const {
    make,
    model,
    carFilterQueryValue,
    countyPathParam,
    makeQueryValue,
    callback,
  } = args;
  if (make) {
    const modelDisplay = model || '(All Models)';
    callback(`${make} ${modelDisplay}`);
    if (carFilterQueryValue && !countyPathParam) {
      callback(carFilterQueryValue);
    }
  } else if (makeQueryValue) {
    if (typeof makeQueryValue === 'string') {
      const formattedTerms = formatMakeModelQueryTerm(makeQueryValue);
      return callback(formattedTerms);
    }
    makeQueryValue.forEach((item) => {
      const formattedTerms = formatMakeModelQueryTerm(item);
      callback(formattedTerms);
    });
  }
};

const formatFiltersAndRanges = (args: {
  filtersAndRanges: ParsedUrlQuery;
  filtersData: Array<ISearchPageFilter>;
  callback: (term: string) => void;
}) => {
  const { filtersAndRanges, filtersData, callback } = args;
  const appliedFilters = Object.keys(filtersAndRanges);

  filtersData.forEach(
    ({ name, searchQueryGroup, values, filterType, displayName }) => {
      const isFilter = searchQueryGroup === 'filters';
      const isRange = searchQueryGroup === 'ranges';
      const filterQueryValues = filtersAndRanges[name];

      if (isFilter) {
        return formatFilters({
          appliedFilters,
          filterQueryValues,
          filterType,
          callback,
          displayName,
          name,
          values,
        });
      }
      if (isRange) {
        formatRanges({
          appliedFilters,
          callback,
          name,
          values,
          filtersAndRanges,
        });
      }
    },
  );
};

const formatFilters = (args: {
  appliedFilters: Array<string>;
  filterQueryValues?: string | Array<string>;
  callback: (term: string) => void;
  displayName: string;
  name: string;
  filterType: {
    id: number | null;
    name: string;
  };
  values: Array<ISearchPageFilterValue> | null;
}) => {
  const {
    appliedFilters,
    filterQueryValues,
    filterType,
    callback,
    displayName,
    name,
    values,
  } = args;

  if (appliedFilters.includes(name) && filterQueryValues) {
    if (filterType.name === 'CheckBox') {
      return callback(displayName);
    }
    if (typeof filterQueryValues === 'string') {
      return formatFilterDisplay(values, filterQueryValues, callback);
    }
    if (Array.isArray(filterQueryValues)) {
      for (const queryValue of filterQueryValues) {
        formatFilterDisplay(values, queryValue, callback);
      }
    }
  }
};

const formatFilterDisplay = (
  values: Array<ISearchPageFilterValue> | null,
  target: string,
  callback: (term: string) => void,
) => {
  const display = values?.find(({ value }) => value === target)?.displayName;
  if (display) callback(display);
};

const formatRanges = (args: {
  appliedFilters: Array<string>;
  callback: (term: string) => void;
  name: string;
  values: Array<ISearchPageFilterValue> | null;
  filtersAndRanges: ParsedUrlQuery;
}) => {
  const { appliedFilters, callback, name, values, filtersAndRanges } = args;

  const { from, to } = formatFromAndToQueryValues(
    appliedFilters,
    name,
    filtersAndRanges,
  );

  if (name === 'price') {
    return formatPrices({
      appliedFilters,
      filtersAndRanges,
      callback,
      from,
      to,
    });
  }

  const [fromAndToValues] = values ?? [];
  const { from: fromValues, to: toValues } = fromAndToValues ?? {};

  const fromDisplay = formatRangeDisplay(fromValues, from);
  const toDisplay = formatRangeDisplay(toValues, to);
  // For those ranges where there is only a single array of values
  const fallbackDisplay = values?.find(
    ({ value }) => value === from || value === to,
  )?.displayName;

  if (fromDisplay && fromDisplay === toDisplay) {
    return callback(fromDisplay);
  }
  if (fromDisplay && toDisplay) {
    const [updatedFromDisplay] = fromDisplay ? fromDisplay.split(' ') : [];
    return callback(`${updatedFromDisplay} - ${toDisplay}`);
  }
  if (fromDisplay) {
    return callback(`>${fromDisplay}`);
  }
  if (toDisplay) {
    return callback(`<${toDisplay}`);
  }
  if (fallbackDisplay) {
    callback(fallbackDisplay);
  }
};

const formatFromAndToQueryValues = (
  appliedFilters: Array<string>,
  name: string,
  filtersAndRanges: ParsedUrlQuery,
) => {
  const from = appliedFilters.includes(`${name}_from`)
    ? asString(filtersAndRanges[`${name}_from`])
    : undefined;
  const to = appliedFilters.includes(`${name}_to`)
    ? asString(filtersAndRanges[`${name}_to`])
    : undefined;

  return { from, to };
};

const formatRangeDisplay = (
  values?: Array<TSearchPageFilterValueCategory>,
  target?: string,
) => {
  return values?.find(({ value }) => value === target)?.displayName;
};

const formatPrices = (args: {
  appliedFilters: Array<string>;
  callback: (term: string) => void;
  filtersAndRanges: ParsedUrlQuery;
  from?: string;
  to?: string;
}) => {
  const { appliedFilters, filtersAndRanges, callback, from, to } = args;

  const {
    sterlingPriceFrom,
    sterlingPriceTo,
    pricePerMonthFrom,
    pricePerMonthTo,
  } = formatSterlingPriceAndPricePerMonthValues(
    appliedFilters,
    filtersAndRanges,
  );

  const defaultPriceFrom = from ? `€${thousandSeparated(from)}` : undefined;
  const defaultPriceTo = to ? `€${thousandSeparated(to)}` : undefined;

  const priceFrom = defaultPriceFrom ?? sterlingPriceFrom ?? pricePerMonthFrom;
  const priceTo = defaultPriceTo ?? sterlingPriceTo ?? pricePerMonthTo;

  if (priceFrom && priceFrom === priceTo) {
    return callback(thousandSeparated(priceFrom));
  }
  if (priceFrom && priceTo) {
    return callback(`${priceFrom} - ${priceTo}`);
  }
  if (priceFrom) {
    return callback(`<${priceFrom}`);
  }
  if (priceTo) {
    callback(`>${priceTo}`);
  }
};

const formatSterlingPriceAndPricePerMonthValues = (
  appliedFilters: Array<string>,
  filtersAndRanges: ParsedUrlQuery,
) => {
  const sterlingPriceFrom =
    appliedFilters.includes('sterlingPrice_from') &&
    typeof filtersAndRanges['sterlingPrice_from'] === 'string'
      ? `£${asString(
          thousandSeparated(filtersAndRanges['sterlingPrice_from']),
        )}`
      : undefined;
  const sterlingPriceTo =
    appliedFilters.includes('sterlingPrice_to') &&
    typeof filtersAndRanges['sterlingPrice_to'] === 'string'
      ? `£${asString(thousandSeparated(filtersAndRanges['sterlingPrice_to']))}`
      : undefined;

  const pricePerMonthTo =
    appliedFilters.includes('pricePerMonth_to') &&
    typeof filtersAndRanges['pricePerMonth_to'] === 'string'
      ? `€${asString(thousandSeparated(filtersAndRanges['pricePerMonth_to']))}`
      : undefined;
  const pricePerMonthFrom =
    appliedFilters.includes('pricePerMonth_from') &&
    typeof filtersAndRanges['pricePerMonth_from'] === 'string'
      ? `€${asString(
          thousandSeparated(filtersAndRanges['pricePerMonth_from']),
        )}`
      : undefined;

  return {
    sterlingPriceFrom,
    sterlingPriceTo,
    pricePerMonthFrom,
    pricePerMonthTo,
  };
};

export {
  formatMakeModelQueryName,
  formatMakeModelQueryTerm,
  generateSaveSearchTerms,
};
