import React from 'react';
import BottomSheetList from 'features/filters/components/BottomSheetList/BottomSheetList';
import type { IListMultiSelectProps } from 'features/filters/components/ListMultiSelect/ListMultiSelect.typed';
import * as Styled from 'features/filters/components/ListMultiSelect/ListMultiSelect.styled';

function ListMultiSelect(props: IListMultiSelectProps) {
  const {
    label,
    placeholder,
    options,
    selectedOptions,
    onSelectOption,
    onClear,
    name,
    isDisabled,
    renderSpacer,
    heightVariant,
  } = props;

  return (
    <BottomSheetList
      name={name}
      label={label}
      placeholder={placeholder}
      onClear={onClear}
      id={name}
      isDisabled={isDisabled}
      renderSpacer={renderSpacer}
      heightVariant={heightVariant}
    >
      {options.map((option, index) => {
        const isSelected =
          selectedOptions &&
          typeof selectedOptions.find(
            (selectedOption) => selectedOption === option.value,
          ) === 'string';
        const isHeadingItem = index > 0 && option.value === '';
        return (
          <li key={`${option.value}-${index}`}>
            {isHeadingItem ? (
              <BottomSheetList.ListHeadingItem key={index}>
                {option.label}
              </BottomSheetList.ListHeadingItem>
            ) : (
              <BottomSheetList.ListItem
                isDefault={false}
                onClick={(e) => {
                  e.preventDefault();
                  onSelectOption && onSelectOption(option.value, !!isSelected);
                }}
              >
                {option.label}
                <Styled.SCheckbox
                  id={option.value}
                  name={option.value}
                  value={option.value}
                  checked={!!isSelected}
                  willUseSubText={false}
                />
              </BottomSheetList.ListItem>
            )}
          </li>
        );
      })}
    </BottomSheetList>
  );
}

export { ListMultiSelect };
