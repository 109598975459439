import * as Styled from 'components/SearchPage/components/Listings/components/SearchCard/SearchCard.styled';

import { PlayOutlineIc, RepeatOutlineIc } from '@dsch/dd-icons';

import type { SearchCardProps } from 'components/SearchPage/components/Listings/components/SearchCard/SearchCard.typed';

import { Price } from 'components/ToolkitV2/CardV2/components/Price/Price';
import { Image } from 'components/ToolkitV2/CardV2/components/Image/Image';
import { TrustIndicatorTags } from 'components/PageWrappers/SharedComponents/TrustIndicatorTags/TrustIndicatorTags';
import { Card } from 'components/ToolkitV2/CardV2/Card';
import { BasicHeader } from 'components/ToolkitV2/CardV2/components/BasicHeader/BasicHeader';
import { TrustedDealerBadge } from 'components/PageWrappers/SharedComponents/TrustedDealerBadge/TrustedDealerBadge';
import { InfoTitleStarRating } from 'components/PageWrappers/AdDetails/components/GoogleReviews/InfoTitleStarRating/InfoTitleStarRating';
import { MediaCount } from 'components/Toolkit/Tags/MediaCount';
import { MediaHighlight } from 'components/Toolkit/Tags/MediaHighlight';
import { Spacer } from 'helpers/genericStyles';

import { formatMetaData } from 'components/ToolkitV2/CardV2/helpers/formatMetaData/formatMetaData';
import { useSaveAd } from 'components/Support/UseSaveAd/useSaveAd';

import { WithHighlight } from 'components/ToolkitV2/CardV2/enhancements/WithHighlight/WithHighlight';
import { WithImageOverlay } from 'components/ToolkitV2/CardV2/enhancements/WithImageOverlay/WithImageOverlay';
import { WithHeader } from 'components/ToolkitV2/CardV2/enhancements/WithHeader/WithHeader';
import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';

import { Desktop } from 'components/Support/Desktop/Desktop';

import { useUserContext } from 'contexts/UserContext';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { formatAuthorizationHeader } from 'helpers/auth';
import { PAGE } from 'helpers/pages';
import { renderTrustedDealerInSection } from 'helpers/trustedDealerProgram';
import { InfoTitle } from 'components/Toolkit/InfoTitle/InfoTitle';

import {
  BASIC_HEADER_TITLE_FONT_SIZE_SMALL,
  BASIC_HEADER_KEY_INFO_ITEM_FONT_SIZE_SMALL,
  PRICE_SMALL_FONT_SIZE,
} from 'components/SearchPage/components/Listings/components/SearchCard/SearchCard.constants';
import { SaveAd } from 'components/Toolkit/SaveAd/SaveAd';

function SearchCard(props: SearchCardProps) {
  const {
    item: {
      dealer,
      section,
      id,
      userSaved,
      age,
      county,
      countyTown,
      wanted,
      deliveryAvailable,
      cardPayments,
      meritsV2,
      specialOffer,
      greenlightVerified,
      manufacturerApprovedBadge,
      friendlyUrl,
      highlight,
      highlightText,
      photos,
      imageAlt,
      mediaCount,
      header,
      currency,
      price,
      priceOnRequest,
      financeSummary,
      keyInfo,
      youTubeVideoId,
      link360,
      link360Interior,
      oldPriceView,
      relativeUrl,
    },
    isGrid,
    currentSection,
    createSavedAdEvent,
    deleteSavedAdEvent,
  } = props;

  const listingUrl = relativeUrl ?? friendlyUrl;

  const displayGoogleReviews = Boolean(
    dealer?.googleReview &&
      dealer?.trustedDealer &&
      dealer?.franchiseType &&
      dealer?.name,
  );

  const displayTrustedDealer =
    dealer?.trustedDealer && renderTrustedDealerInSection(section.name);

  const dealerHeader = {
    title: dealer?.name,
    subTitle: displayGoogleReviews ? (
      <InfoTitleStarRating
        rating={dealer?.googleReview?.rating}
        maxRating={5}
        isDealer={true}
        franchiseType={dealer?.franchiseType}
        isTrader={false}
        variant="SMALL"
        styleAsLink={false}
        isFakeLink={true}
      />
    ) : (
      dealer?.franchiseType
    ),
    logo: dealer?.logo?.small,
  };

  const orientationConfig = {
    orientationSmall: ORIENTATION.VERTICAL,
    orientationMedium: isGrid ? ORIENTATION.VERTICAL : ORIENTATION.HORIZONTAL,
    orientationLarge: isGrid ? ORIENTATION.VERTICAL : ORIENTATION.HORIZONTAL,
  };

  const includesPhoto = mediaCount > 0;
  const imageSrc = photos && photos[0].small2 ? photos[0].small2 : undefined;
  const imageSrcWebp =
    photos && photos[0].small2Webp ? photos[0].small2Webp : undefined;

  const metaData = [
    ...(!isGrid && keyInfo ? [...keyInfo] : []),
    age,
    !isGrid && countyTown ? `${countyTown}, ${county}` : county,
    currentSection !== section.name ? section.displayName : undefined,
  ];

  const formattedMetaData = formatMetaData({
    metaData,
  });

  const { user } = useUserContext();
  const { accessToken, handleLogin } = useAuthOptions();
  const authorisationHeader = formatAuthorizationHeader(accessToken);

  const { onSave, isAdSaved } = useSaveAd({
    adId: id,
    userSaved: userSaved,
    section: section.name,
    sellerType: dealer ? 'DEALER' : 'PRIVATE',
    sectionName: section.name,
    userId: user?.id,
    trackCreateSaveAd: createSavedAdEvent,
    trackDeleteSaveAd: deleteSavedAdEvent,
    authorisationHeader,
    handleLogin,
  });

  const { title, subTitle, logo } = dealerHeader;

  const includesHighlight = Boolean(highlight && highlightText);
  const showBorder = Boolean(title && logo && !isGrid && !includesHighlight);

  return (
    <Styled.CardLink href={listingUrl}>
      <Styled.CardWrapper>
        <WithHeader
          isGrid={isGrid}
          elements={
            title &&
            logo && (
              <>
                <InfoTitle
                  title={title}
                  listItems={[subTitle]}
                  illustration={logo}
                  variant="SMALL"
                  shouldTruncate
                />

                {displayTrustedDealer && (
                  <TrustedDealerBadge
                    pageName={PAGE.SEARCH}
                    icon={
                      <Styled.SShieldCheckmark2Ic
                        width="24"
                        height="24"
                        data-testid="shield-icon"
                      />
                    }
                  />
                )}
              </>
            )
          }
          showBorder={showBorder}
        >
          <WithHighlight
            highlight={highlight}
            highlightText={highlightText}
            isGrid={isGrid}
          >
            <Card
              {...orientationConfig}
              image={
                <WithImageOverlay
                  bottomLeftSlot={
                    <>
                      {includesPhoto && <MediaCount mediaCount={mediaCount} />}
                      {youTubeVideoId && (
                        <MediaHighlight>
                          <PlayOutlineIc width="16" height="16" />
                        </MediaHighlight>
                      )}
                      {(link360 || link360Interior) && (
                        <MediaHighlight>
                          <RepeatOutlineIc height="16" width="16" />
                        </MediaHighlight>
                      )}
                    </>
                  }
                  bottomRightSlot={
                    isGrid ? (
                      <Styled.ImageSaveAd>
                        <SaveAd
                          toggleSave={onSave}
                          isAdSaved={isAdSaved}
                          hideText
                          variant="OVERLAY"
                        />
                      </Styled.ImageSaveAd>
                    ) : undefined
                  }
                >
                  <Image
                    src={imageSrc}
                    imgSrcWebp={imageSrcWebp}
                    alt={imageAlt}
                    widthVertical={300}
                    heightVertical={225}
                    widthHorizontalMedium={320}
                    {...orientationConfig}
                  />
                </WithImageOverlay>
              }
              header={
                <div>
                  {!isGrid && (
                    <Desktop>
                      <Spacer marginBottom="S8" />
                    </Desktop>
                  )}
                  <BasicHeader
                    title={header}
                    titleSmallFontSize={
                      isGrid ? BASIC_HEADER_TITLE_FONT_SIZE_SMALL : undefined
                    }
                    keyInfoItemSmallFontSize={
                      isGrid
                        ? BASIC_HEADER_KEY_INFO_ITEM_FONT_SIZE_SMALL
                        : undefined
                    }
                    metaData={formattedMetaData}
                  />
                </div>
              }
              body={
                !isGrid && (
                  <TrustIndicatorTags
                    deliveryAvailable={deliveryAvailable}
                    cardPayments={cardPayments}
                    meritsV2={meritsV2}
                    specialOffer={specialOffer}
                    wanted={wanted}
                    greenlightVerified={greenlightVerified}
                    manufacturerApprovedBadge={manufacturerApprovedBadge}
                  />
                )
              }
              gapSmall={isGrid ? 'M16' : 'S8'}
              gapMedium={isGrid ? 'S8' : undefined}
              paddingSmall={isGrid ? 'S4' : 'S8'}
              paddingMedium={isGrid ? 'S8' : 'M16'}
              footer={
                <>
                  <Price
                    currency={currency}
                    price={price}
                    pricePerMonth={financeSummary?.price}
                    priceOnRequest={priceOnRequest}
                    isGrid={isGrid}
                    oldPrice={
                      oldPriceView.showOldPrice ? oldPriceView.oldPrice : null
                    }
                    priceSmallFontSize={
                      isGrid ? PRICE_SMALL_FONT_SIZE : undefined
                    }
                  />
                  <Styled.BodySaveAd isGrid={isGrid}>
                    <SaveAd
                      toggleSave={onSave}
                      isAdSaved={isAdSaved}
                      hideText
                    />
                  </Styled.BodySaveAd>
                </>
              }
            />
          </WithHighlight>
        </WithHeader>
      </Styled.CardWrapper>
    </Styled.CardLink>
  );
}

export { SearchCard };
