const NOTIFICATION_FREQUENCY = {
  DAILY: 'DAILY',
  INSTANT: 'INSTANT',
  OFF: 'OFF',
} as const;

const SAVED_SEARCH_STATES = {
  SAVED: 'SAVED',
  UNSAVED: 'UNSAVED',
  LOADING: 'LOADING',
} as const;

type SavedSearchStates =
  (typeof SAVED_SEARCH_STATES)[keyof typeof SAVED_SEARCH_STATES];

type NotificationFrequency =
  (typeof NOTIFICATION_FREQUENCY)[keyof typeof NOTIFICATION_FREQUENCY];

export { NOTIFICATION_FREQUENCY, SAVED_SEARCH_STATES };
export type { SavedSearchStates, NotificationFrequency };
