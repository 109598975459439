import { AddCircleOutlineIc } from '@dsch/dd-icons';

import { Button } from 'components/Toolkit/Button/Button';
import { ButtonProps } from 'components/Toolkit/Button/SharedButton';

type AddButtonProps = ButtonProps;

const AddButton = (props: AddButtonProps) => {
  return (
    <Button ofType="SECONDARY" {...props}>
      <AddCircleOutlineIc />
      &nbsp;Add another
    </Button>
  );
};

export { AddButton };
