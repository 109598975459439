import type { ReactElement } from 'react';

import { formatNumber } from 'helpers/formatting';

import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { MOTOR_SECTIONS } from 'constants/searchPage';
import { provinces } from 'helpers/constants';

import type { ISeller } from 'api/types/sellerApiTypes';

export interface IUseSearchPageSeoProps {
  baseURL: string;
  sectionName: string;
  adTotal: number;
  areas?: string[];
  trader?: ISeller | null;
  wanted?: boolean;
  makes?: string[];
  models?: string[];
  years?: string[];
  fuels?: string[];
  bodyTypes?: string[];
  transmissions?: string[];
  colours?: string[];
  countyTown?: string | null;
}

function tidyString(value: string) {
  return value.trim().replace(/ {2,}/g, ' ');
}

function tidyURL(value: string) {
  const parts = value.split('?');
  if (parts.length <= 2) {
    return value;
  } else {
    const [first, ...rest] = parts;
    return `${first}?${rest.join('&')}`;
  }
}

function arrayToQueryParams(key: string, value: string[] | undefined): string {
  return `${key}=${value?.join(`&${key}=`)}`;
}

function getSingleValue(values: string[] | undefined) {
  return values && values.length === 1 ? values[0] : '';
}

enum MOTOR_COMBO {
  MAKE = 'MAKE',
  YEAR = 'YEAR',
  LOCATION = 'LOCATION',
  YEAR_LOCATION = 'YEAR_LOCATION',
  MAKE_MODEL = 'MAKE_MODEL',
  MAKE_YEAR = 'MAKE_YEAR',
  MAKE_LOCATION = 'MAKE_LOCATION',
  MAKE_MODEL_YEAR = 'MAKE_MODEL_YEAR',
  MAKE_MODEL_LOCATION = 'MAKE_MODEL_LOCATION',
  MAKE_YEAR_LOCATION = 'MAKE_YEAR_LOCATION',
  MAKE_MODEL_YEAR_LOCATION = 'MAKE_MODEL_YEAR_LOCATION',

  FILTER = 'FILTER',
  FILTER_YEAR = 'FILTER_YEAR',
  FILTER_LOCATION = 'FILTER_LOCATION',
  YEAR_FILTER_LOCATION = 'YEAR_FILTER_LOCATION',
  MAKE_FILTER = 'MAKE_FILTER',
  MAKE_MODEL_FILTER = 'MAKE_MODEL_FILTER',
  MAKE_YEAR_FILTER = 'MAKE_YEAR_FILTER',
  MAKE_LOCATION_FILTER = 'MAKE_LOCATION_FILTER',
  MAKE_MODEL_YEAR_FILTER = 'MAKE_MODEL_YEAR_FILTER',
  MAKE_MODEL_LOCATION_FILTER = 'MAKE_MODEL_LOCATION_FILTER',
  MAKE_YEAR_LOCATION_FILTER = 'MAKE_YEAR_LOCATION_FILTER',
  MAKE_MODEL_YEAR_LOCATION_FILTER = 'MAKE_MODEL_YEAR_LOCATION_FILTER',
}

function useSearchPageSeo(props: IUseSearchPageSeoProps) {
  const {
    currentSectionQueryValue: section,
    keywordQueryValue: keywordSearchTerm,
    radiusQueryValue,
    countyTownQueryValue: countyTown,
  } = useURLStateManagement();
  const {
    baseURL,
    areas,
    sectionName,
    adTotal,
    wanted = false,
    trader,
    makes,
    models,
    years,
    fuels,
    bodyTypes,
    colours,
    transmissions,
  } = props;
  const radius = countyTown ? radiusQueryValue : null;
  const isMotor = MOTOR_SECTIONS.includes(section);
  const isCars = section === 'cars';
  const isTractors = section === 'tractors';
  const isKeywordSearch = !!keywordSearchTerm;
  const isWanted = Boolean(wanted);
  const make = getSingleValue(makes);
  const model = getSingleValue(models);
  const year = getSingleValue(years);
  const area = getSingleValue(areas);

  const fuel = getSingleValue(fuels);
  const bodyType = getSingleValue(bodyTypes);
  const colour = getSingleValue(colours);
  const transmission =
    getSingleValue(transmissions) === 'Automatic' ? 'Automatic' : '';

  const filter = fuel || bodyType || colour || transmission;

  const singleFilterSelected =
    [fuel, bodyType, colour, transmission].filter((filterType) => filterType)
      .length === 1;

  function getMotorCombo(): MOTOR_COMBO | undefined {
    if (make && !model && !area && !year && !singleFilterSelected) {
      return MOTOR_COMBO.MAKE;
    }
    if (!make && !model && !area && year && !singleFilterSelected) {
      return MOTOR_COMBO.YEAR;
    }
    if (!make && !model && area && !year && !singleFilterSelected) {
      return MOTOR_COMBO.LOCATION;
    }
    if (!make && !model && !area && !year && singleFilterSelected) {
      return MOTOR_COMBO.FILTER;
    }
    if (!make && !model && !area && year && singleFilterSelected) {
      return MOTOR_COMBO.FILTER_YEAR;
    }
    if (!make && !model && area && !year && singleFilterSelected) {
      return MOTOR_COMBO.FILTER_LOCATION;
    }
    if (!make && !model && area && year && singleFilterSelected) {
      return MOTOR_COMBO.YEAR_FILTER_LOCATION;
    }
    if (!make && !model && area && year && !singleFilterSelected) {
      return MOTOR_COMBO.YEAR_LOCATION;
    }
    if (make && model && !area && !year && !singleFilterSelected) {
      return MOTOR_COMBO.MAKE_MODEL;
    }
    if (make && !model && !area && year && !singleFilterSelected) {
      return MOTOR_COMBO.MAKE_YEAR;
    }
    if (make && !model && area && !year && !singleFilterSelected) {
      return MOTOR_COMBO.MAKE_LOCATION;
    }
    if (make && !model && !area && !year && singleFilterSelected) {
      return MOTOR_COMBO.MAKE_FILTER;
    }
    if (make && model && !area && year && !singleFilterSelected) {
      return MOTOR_COMBO.MAKE_MODEL_YEAR;
    }
    if (make && model && area && !year && !singleFilterSelected) {
      return MOTOR_COMBO.MAKE_MODEL_LOCATION;
    }
    if (make && model && !area && !year && singleFilterSelected) {
      return MOTOR_COMBO.MAKE_MODEL_FILTER;
    }
    if (make && !model && area && year && !singleFilterSelected) {
      return MOTOR_COMBO.MAKE_YEAR_LOCATION;
    }
    if (make && !model && !area && year && singleFilterSelected) {
      return MOTOR_COMBO.MAKE_YEAR_FILTER;
    }
    if (make && !model && area && !year && singleFilterSelected) {
      return MOTOR_COMBO.MAKE_LOCATION_FILTER;
    }
    if (make && model && area && year && !singleFilterSelected) {
      return MOTOR_COMBO.MAKE_MODEL_YEAR_LOCATION;
    }
    if (make && model && !area && year && singleFilterSelected) {
      return MOTOR_COMBO.MAKE_MODEL_YEAR_FILTER;
    }
    if (make && model && area && !year && singleFilterSelected) {
      return MOTOR_COMBO.MAKE_MODEL_LOCATION_FILTER;
    }
    if (make && !model && area && year && singleFilterSelected) {
      return MOTOR_COMBO.MAKE_YEAR_LOCATION_FILTER;
    }
    if (make && model && area && year && singleFilterSelected) {
      return MOTOR_COMBO.MAKE_MODEL_YEAR_LOCATION_FILTER;
    }
  }

  function generateMotorH1(): string {
    const areaOrForSale = area ? `in ${area}` : 'For Sale';
    const areaOrIreland = area || 'Ireland';
    let body;
    switch (getMotorCombo()) {
      case MOTOR_COMBO.MAKE:
      case MOTOR_COMBO.MAKE_LOCATION:
        body = `${make}`;
        break;
      case MOTOR_COMBO.YEAR:
      case MOTOR_COMBO.YEAR_LOCATION:
        body = `${year}`;
        break;
      case MOTOR_COMBO.FILTER:
      case MOTOR_COMBO.FILTER_LOCATION:
        body = `${filter}`;
        break;
      case MOTOR_COMBO.FILTER_YEAR:
      case MOTOR_COMBO.YEAR_FILTER_LOCATION:
        body = `${year} ${filter}`;
        break;
      case MOTOR_COMBO.MAKE_MODEL:
      case MOTOR_COMBO.MAKE_MODEL_LOCATION:
        body = `${make} ${model}`;
        break;
      case MOTOR_COMBO.MAKE_YEAR:
      case MOTOR_COMBO.MAKE_YEAR_LOCATION:
        body = `${year} ${make}`;
        break;
      case MOTOR_COMBO.MAKE_FILTER:
      case MOTOR_COMBO.MAKE_LOCATION_FILTER:
        body = `${filter} ${make}`;
        break;
      case MOTOR_COMBO.MAKE_MODEL_YEAR:
      case MOTOR_COMBO.MAKE_MODEL_YEAR_LOCATION:
        body = `${year} ${make} ${model}`;
        break;
      case MOTOR_COMBO.MAKE_MODEL_FILTER:
      case MOTOR_COMBO.MAKE_MODEL_LOCATION_FILTER:
        body = `${filter} ${make} ${model}`;
        break;
      case MOTOR_COMBO.MAKE_YEAR_FILTER:
      case MOTOR_COMBO.MAKE_YEAR_LOCATION_FILTER:
        body = `${year} ${filter} ${make}`;
        break;
      case MOTOR_COMBO.MAKE_MODEL_YEAR_FILTER:
      case MOTOR_COMBO.MAKE_MODEL_YEAR_LOCATION_FILTER:
        body = `${year} ${filter} ${make} ${model}`;
        break;
      case MOTOR_COMBO.LOCATION:
      default:
        body = `${year}`;
        break;
    }
    return isCars
      ? tidyString(`New & Used ${body} ${sectionName} ${areaOrForSale}`)
      : tidyString(`${body} ${sectionName} in ${areaOrIreland}`);
  }

  function generateH1(): string {
    // Keyword Search Term must be first as it takes priority over everything else
    if (keywordSearchTerm) {
      return `${sectionName}: "${keywordSearchTerm}"`;
    }
    if (isMotor) {
      return generateMotorH1();
    }
    if (trader) {
      return `${trader.traderName}`;
    }
    const area = areas?.length === 1 ? areas[0] : 'Ireland';
    return isTractors && make
      ? `${make} ${sectionName} in ${area}`
      : `${sectionName} in ${area}`;
  }

  function generateH2(): ReactElement {
    // 79 ads for "bath" for Bathtime in Leinster (+1)
    // 93 ads for Bathtime in Leinster (+1)
    // 54 ads for Bathtime in Leinster
    // 0 ads found
    if (trader) {
      return (
        <span>
          {trader.adCount} in {trader.traderName}
        </span>
      );
    }

    if (!adTotal)
      return (
        <span>
          <strong>0 ads</strong> found
        </span>
      );

    const count = formatNumber(adTotal);
    const adOrAds = adTotal > 1 ? 'ads' : 'ad';
    const area = areas?.length
      ? `${countyTown ? `${countyTown}, ` : ''}${
          !provinces.includes(areas[0]) ? 'Co. ' : ''
        }${areas[0]}${radius ? ` (+${radius}km)` : ''}`
      : 'Ireland';
    const areaMoreOrBlank =
      areas && areas?.length > 1 ? ` (+${areas?.length - 1})` : '';

    const html = (
      <span>
        {isCars ? (
          <strong>{`${count} ${isWanted ? 'wanted ' : ''}`}</strong>
        ) : (
          <>
            <strong>{`${count} ${adOrAds} ${
              isWanted ? 'wanted ' : ''
            }`}</strong>
            for&nbsp;
          </>
        )}
        {keywordSearchTerm ? (
          <>
            <strong>"{keywordSearchTerm}"&nbsp;</strong>
            {isCars ? '' : 'for '}
          </>
        ) : (
          ''
        )}
        <strong>
          {isCars ? (
            <>
              {make && make !== 'All Makes'
                ? `${make} ${model ? `${model} ` : ''}`
                : ''}
              {adTotal === 1 ? 'car ' : 'cars '}
            </>
          ) : (
            <>{sectionName}&nbsp;</>
          )}
        </strong>
        in&nbsp;
        <strong>
          {area}
          {areaMoreOrBlank}
        </strong>
      </span>
    );

    return html;
  }

  function generatePageTitle(): string {
    // 400 Cars For Sale in Leinster, Munster
    // 1,000 Cars For Sale in Ireland
    // Cars Wanted in Ireland
    // Bathime For Sale in Leinster, Munster
    // Bathime For Sale in Ireland
    if (trader)
      return `${trader.traderName} ${trader.traderAddress} Online Shop - DoneDeal`;

    const fuelOrBlank = (!isKeywordSearch && fuel) || '';
    const colourOrBlank = (!isKeywordSearch && colour) || '';
    const bodyTypeOrBlank = (!isKeywordSearch && bodyType) || '';
    const transmissionOrBlank = (!isKeywordSearch && transmission) || '';
    const makeOrBlank = (!isKeywordSearch && make) || '';
    const modelOrBlank = (!isKeywordSearch && !!makeOrBlank && model) || '';
    const yearOrBlank = !isKeywordSearch && year ? `(${year})` : '';
    const isYearBeforeSection = Boolean(
      fuelOrBlank ||
        makeOrBlank ||
        modelOrBlank ||
        colourOrBlank ||
        bodyTypeOrBlank ||
        transmissionOrBlank,
    );
    const searchOrBlank = isKeywordSearch ? `${keywordSearchTerm} | ` : '';
    const totalOrBlank =
      isKeywordSearch && adTotal > 0 ? formatNumber(adTotal) : '';
    const adsSingularOrPlural = adTotal === 1 ? '' : 's';
    const adsOrAdsInOrBlank =
      isMotor && !isKeywordSearch
        ? ''
        : isMotor
        ? `Ad${adsSingularOrPlural} in`
        : `Ad${adsSingularOrPlural}`;
    const isAdsBeforeSection = isMotor;
    const forSaleOrWanted = wanted ? 'Wanted' : 'For Sale';
    const areaOrIreland = areas?.length ? areas.join(', ') : 'Ireland';
    const pageTitle = `${searchOrBlank} ${totalOrBlank} ${
      singleFilterSelected
        ? fuelOrBlank || colourOrBlank || bodyTypeOrBlank || transmissionOrBlank
        : ''
    } ${makeOrBlank} ${modelOrBlank} ${
      isYearBeforeSection ? yearOrBlank : ''
    } ${isAdsBeforeSection ? adsOrAdsInOrBlank : ''} ${sectionName} ${
      !isYearBeforeSection ? yearOrBlank : ''
    } ${
      !isAdsBeforeSection ? adsOrAdsInOrBlank : ''
    } ${forSaleOrWanted} in ${areaOrIreland} | DoneDeal`;
    // strip double spaces
    return tidyString(pageTitle);
  }

  function generateMotorMetaDescription() {
    const adTotalOrAll =
      adTotal === 0 || isKeywordSearch ? 'All' : formatNumber(adTotal);
    const adsInOrBlank = isKeywordSearch ? 'Ads in' : '';
    const areaOrIreland = (!isKeywordSearch && area) || 'Ireland';
    const searchOrBlank = isKeywordSearch ? keywordSearchTerm : '';
    const saleOrWanted = wanted ? 'Wanted' : 'For Sale';

    let body = '';
    if (!isKeywordSearch) {
      switch (getMotorCombo()) {
        case MOTOR_COMBO.MAKE:
        case MOTOR_COMBO.MAKE_LOCATION:
          body = `${make}`;
          break;
        case MOTOR_COMBO.YEAR:
          body = `${year}`;
          break;
        case MOTOR_COMBO.FILTER:
          body = `${filter}`;
          break;
        case MOTOR_COMBO.MAKE_MODEL:
        case MOTOR_COMBO.MAKE_MODEL_LOCATION:
          body = `${make} ${model}`;
          break;
        case MOTOR_COMBO.MAKE_YEAR:
        case MOTOR_COMBO.MAKE_YEAR_LOCATION:
          body = `${year} ${make}`;
          break;
        case MOTOR_COMBO.MAKE_FILTER:
        case MOTOR_COMBO.MAKE_LOCATION_FILTER:
          body = `${filter} ${make}`;
          break;
        case MOTOR_COMBO.MAKE_MODEL_YEAR:
        case MOTOR_COMBO.MAKE_MODEL_YEAR_LOCATION:
          body = `${year} ${make} ${model}`;
          break;
        case MOTOR_COMBO.MAKE_MODEL_FILTER:
        case MOTOR_COMBO.MAKE_MODEL_LOCATION_FILTER:
          body = ` ${filter} ${make} ${model}`;
          break;
        case MOTOR_COMBO.MAKE_YEAR_FILTER:
        case MOTOR_COMBO.MAKE_YEAR_LOCATION_FILTER:
          body = `${year} ${filter} ${make}`;
          break;
        case MOTOR_COMBO.MAKE_MODEL_YEAR_FILTER:
        case MOTOR_COMBO.MAKE_MODEL_YEAR_LOCATION_FILTER:
          body = `${year} ${filter} ${make} ${model}`;
          break;

        case MOTOR_COMBO.LOCATION:
        case MOTOR_COMBO.FILTER_YEAR:
        case MOTOR_COMBO.FILTER_LOCATION:
        case MOTOR_COMBO.YEAR_FILTER_LOCATION:
        case MOTOR_COMBO.YEAR_LOCATION:
        default:
          body = ``;
          break;
      }
    }

    return tidyString(
      `Discover ${adTotalOrAll} New & Used ${searchOrBlank} ${body} ${adsInOrBlank} ${sectionName} ${saleOrWanted} in ${areaOrIreland} on DoneDeal. Buy & Sell on Ireland's Largest ${sectionName} Marketplace.`,
    );
  }

  function generateTractorsMetaDescription() {
    const adTotalOrAll =
      adTotal === 0 || isKeywordSearch ? 'All' : formatNumber(adTotal);
    const areaOrIreland = (!isKeywordSearch && area) || 'Ireland';
    const searchOrBlank = isKeywordSearch ? keywordSearchTerm : '';
    const saleOrWanted = wanted ? 'Wanted' : 'For Sale';

    const adInOrAdsIn = adTotal === 1 && !isKeywordSearch ? 'Ad in' : 'Ads in';

    const makeOrSection = make ? `${make} ${sectionName}` : `${sectionName}`;

    return tidyString(
      `Discover ${adTotalOrAll} ${searchOrBlank} ${adInOrAdsIn} ${makeOrSection} ${saleOrWanted} in ${areaOrIreland} on DoneDeal. Buy & Sell on Ireland's Largest Tractors Marketplace.`,
    );
  }

  function generateMetaDescription(): string {
    if (isMotor) {
      return generateMotorMetaDescription();
    }
    if (isTractors) {
      return generateTractorsMetaDescription();
    }
    const adTotalOrAll =
      adTotal === 0 || isKeywordSearch ? 'All' : formatNumber(adTotal);
    const adOrAds = adTotal === 1 && !isKeywordSearch ? 'Ad' : 'Ads';
    const adInOrAdsIn = adTotal === 1 && !isKeywordSearch ? 'Ad in' : 'Ads in';
    const saleOrWanted = wanted ? 'Wanted' : 'For Sale';
    const area = areas && areas.length ? areas.join(', ') : 'Ireland';
    const keywordSearchTermOrBlank = isKeywordSearch ? keywordSearchTerm : '';
    if (trader) {
      return `${trader.traderName} has ${trader.adCount} ${adOrAds} for sale on DoneDeal`;
    }
    const metaDescription = `Discover ${adTotalOrAll} ${keywordSearchTermOrBlank} ${adInOrAdsIn} ${sectionName} ${saleOrWanted} in ${area} on DoneDeal. Buy & Sell on Ireland's Largest ${sectionName} Marketplace.`;
    return tidyString(metaDescription);
  }

  function generateMotorCanonicalURLBody(): string {
    let body = '';
    const areaQueryOrBlank = arrayToQueryParams('area', areas);

    function generateFilterQuery() {
      if (fuels) {
        return arrayToQueryParams('fuelType', fuels);
      }
      if (colours) {
        return arrayToQueryParams('colour', colours);
      }
      if (bodyTypes) {
        return arrayToQueryParams('bodyType', bodyTypes);
      }
      if (transmissions) {
        return arrayToQueryParams('transmission', transmissions);
      }
      return '';
    }

    const filterQueryOrBlank = generateFilterQuery();

    switch (getMotorCombo()) {
      case MOTOR_COMBO.MAKE:
      case MOTOR_COMBO.MAKE_YEAR:
        body = `/${make}`;
        break;
      case MOTOR_COMBO.MAKE_FILTER:
      case MOTOR_COMBO.MAKE_YEAR_FILTER:
        body = `/${make}?${filterQueryOrBlank}`;
        break;
      case MOTOR_COMBO.MAKE_LOCATION:
      case MOTOR_COMBO.MAKE_LOCATION_FILTER:
      case MOTOR_COMBO.MAKE_YEAR_LOCATION:
      case MOTOR_COMBO.MAKE_YEAR_LOCATION_FILTER:
        body = `/${make}?${areaQueryOrBlank}`;
        break;
      case MOTOR_COMBO.MAKE_MODEL:
        body = `/${make}/${model}`;
        break;
      case MOTOR_COMBO.MAKE_MODEL_YEAR:
      case MOTOR_COMBO.MAKE_MODEL_YEAR_FILTER:
      case MOTOR_COMBO.MAKE_MODEL_YEAR_LOCATION:
      case MOTOR_COMBO.MAKE_MODEL_YEAR_LOCATION_FILTER:
        body = `/${make}/${model}/${year}`;
        break;
      case MOTOR_COMBO.MAKE_MODEL_FILTER:
        body = `/${make}/${model}/${filter}`;
        break;
      case MOTOR_COMBO.MAKE_MODEL_LOCATION:
      case MOTOR_COMBO.MAKE_MODEL_LOCATION_FILTER:
        body = `/${make}/${model}/${area}`;
        break;
      case MOTOR_COMBO.LOCATION:
      case MOTOR_COMBO.FILTER_LOCATION:
      case MOTOR_COMBO.YEAR_FILTER_LOCATION:
      case MOTOR_COMBO.YEAR_LOCATION:
        body = `?${areaQueryOrBlank}`;
        break;
      case MOTOR_COMBO.FILTER:
      case MOTOR_COMBO.FILTER_YEAR:
        body = `?${filterQueryOrBlank}`;
        break;
      default:
        break;
    }
    return body;
  }

  function generateCanonicalURL(): string {
    // Motor must go first as it has its own keyword search check
    let body = '';
    if (isMotor || isTractors) {
      body += generateMotorCanonicalURLBody();
    } else if (areas && areas.length) {
      body += `?${arrayToQueryParams('area', areas)}`;
    }
    if (isKeywordSearch) {
      body += `?words=${keywordSearchTerm}`;
    }
    if (isWanted) {
      body += `?adType=wanted`;
    }
    return tidyURL(tidyString(`${baseURL}/${section}${body}`));
  }

  return {
    isMotor,
    h1: generateH1(),
    h2: generateH2(),
    pageTitle: generatePageTitle(),
    metaDescription: generateMetaDescription(),
    canonical: generateCanonicalURL(),
  };
}

export { useSearchPageSeo };
