import { createContext, useContext } from 'react';

import { useQuickFiltersReducer } from 'components/SearchPage/features/QuickFilters/QuickFilters.reducer';
import type {
  QuickFilterProviderProps,
  QuickFiltersContextData,
} from 'components/SearchPage/features/QuickFilters/QuickFilters.context.typed';

const defaultValue = {
  currentFilter: undefined,
  isFilterBottomSheetOpen: false,
  selectQuickFilter: () => null,
  onCloseFilterDisplay: () => null,
  selectedFilterQueryValue: undefined,
  selectedFilterValue: undefined,
  onChangeFilterValue: () => null,
};

const QuickFiltersContext =
  createContext<QuickFiltersContextData>(defaultValue);

const QuickFilterProvider = (props: QuickFilterProviderProps) => {
  const { children } = props;

  const values = useQuickFiltersReducer();

  return (
    <QuickFiltersContext.Provider value={values}>
      {children}
    </QuickFiltersContext.Provider>
  );
};

const useQuickFilterContext = () => useContext(QuickFiltersContext);

export { QuickFilterProvider, useQuickFilterContext };
