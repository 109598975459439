import { createContext, ReactElement, useContext, useState } from 'react';
import { useSearchPage } from 'components/SearchPage/hooks/useSearchPage';
import type { ISearchPageDispatch } from 'components/SearchPage/hooks/useSearchPage';
import type {
  ISearchPageAds,
  SearchPageBreadcrumbs,
  ISearchPagePaging,
} from 'api/types/searchPageApiTypes';
import type { ViewType } from 'components/SearchPage/SearchPage.typed';

export type SearchPageStateType = {
  baseUrl: string;
  userId?: string | string[];
  viewType: ViewType;
  keywordSearchTerm: string;
  section: string;
  sort: string;
  paging: ISearchPagePaging;
  saved: boolean;
  page: number;
  breadcrumbs: SearchPageBreadcrumbs[];
  recommendations: ISearchPageAds[];
};
export type SearchPageDispatchType = ISearchPageDispatch & {
  setViewType: (viewType: ViewType) => void;
};

export const SearchPageDispatchContext = createContext(
  {} as SearchPageDispatchType,
);

export const SearchPageStateContext = createContext({} as SearchPageStateType);

export const useSearchPageDispatch = (): SearchPageDispatchType =>
  useContext(SearchPageDispatchContext);
export const useSearchPageState = (): SearchPageStateType =>
  useContext(SearchPageStateContext);

export const SearchPageProvider = ({
  baseUrl,
  userId,
  keywordSearchTerm = '',
  section = 'all',
  sort = '',
  paging,
  viewType,
  saved,
  initialBreadcrumbs,
  initialRecommendations = [],
  children,
}: {
  baseUrl: string;
  userId?: string | string[];
  keywordSearchTerm?: string;
  section?: string;
  sort?: string;
  paging: ISearchPagePaging;
  viewType: ViewType;
  saved: boolean;
  initialBreadcrumbs: SearchPageBreadcrumbs[];
  initialRecommendations: ISearchPageAds[];
  children: ReactElement;
}): ReactElement => {
  const [viewTypeState, setViewType] = useState<ViewType>(viewType);
  const { state, dispatch } = useSearchPage(
    section,
    keywordSearchTerm,
    sort,
    paging,
    initialBreadcrumbs,
    initialRecommendations,
  );

  return (
    <SearchPageDispatchContext.Provider
      value={{
        setViewType,
        ...dispatch,
      }}
    >
      <SearchPageStateContext.Provider
        value={{
          baseUrl,
          userId,
          saved,
          viewType: viewTypeState,
          ...state,
        }}
      >
        {children}
      </SearchPageStateContext.Provider>
    </SearchPageDispatchContext.Provider>
  );
};
