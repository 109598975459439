import NextLink from 'next/link';

import { useSectionBrowser } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.hook';

import { formatNumber } from 'helpers/formatting';

import type {
  BackButtonProps,
  SectionBrowserProps,
  SectionProps,
} from 'components/SearchPage/components/SectionBrowser/SectionBrowser.typed';

import * as Styled from 'components/SearchPage/components/SectionBrowser/SectionBrowser.styled';
import { SECTION_DISPLAY_LIMIT } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.constants';

const SectionBrowser = (props: SectionBrowserProps) => {
  const {
    sections,
    currentSection,
    parentSection,
    collapsible = true,
    onSelect,
  } = props;

  const {
    collapsed,
    setCollapsed,
    shouldCollapse,
    getLink,
    showSubsections,
    filteredSubsections,
  } = useSectionBrowser({
    sections,
    collapsible,
    currentSection: currentSection.name,
  });

  return (
    <div>
      {parentSection && (
        <div>
          <BackButton
            link={getLink(parentSection.name)}
            section={parentSection}
            onClick={onSelect}
          />
          <Styled.SubsectionsHeading>Subsections</Styled.SubsectionsHeading>
        </div>
      )}
      <Styled.Collapsible
        collapsed={shouldCollapse ? collapsed : false}
        sectionDisplayLimit={SECTION_DISPLAY_LIMIT}
      >
        <ul>
          <li>
            <Section
              link={getLink(currentSection.name)}
              selected={true}
              section={currentSection}
              onClick={onSelect}
            />
          </li>
          {showSubsections &&
            filteredSubsections.map((item, index) => (
              <li key={index}>
                <Section
                  link={getLink(item.name)}
                  selected={false}
                  section={item}
                  onClick={onSelect}
                />
              </li>
            ))}
        </ul>
        {shouldCollapse && (
          <Styled.CollapseButton
            collapsed={collapsed}
            onClick={() => setCollapsed(!collapsed)}
          >
            <span>
              {collapsed
                ? `View all ${filteredSubsections.length} sections`
                : 'View less sections'}
            </span>
            <Styled.CollapseButtonIcon width={16} height={16} />
          </Styled.CollapseButton>
        )}
      </Styled.Collapsible>
    </div>
  );
};

const Section = (props: SectionProps) => {
  const { link, section, selected, onClick } = props;

  return (
    <NextLink href={link} passHref shallow legacyBehavior>
      <Styled.Item href={link} selected={selected} onClick={onClick}>
        <span>{section.displayName}</span>
        {typeof section.searchMatches !== 'undefined' && (
          <Styled.Count>{formatNumber(section.searchMatches)}</Styled.Count>
        )}
      </Styled.Item>
    </NextLink>
  );
};

const BackButton = (props: BackButtonProps) => {
  const { link, section, onClick } = props;

  return (
    <NextLink href={link} passHref shallow legacyBehavior>
      <Styled.Back href={link} onClick={onClick}>
        <Styled.BackArrow width={12} height={12} />
        <span>{section.displayName}</span>
      </Styled.Back>
    </NextLink>
  );
};

export { SectionBrowser };
