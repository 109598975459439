import styled from 'styled-components';
import { Label } from 'components/Toolkit/Inputs/Label';
import { Inputs, TextField } from 'components/Toolkit/Inputs/TextField';
import { SimpleDropdown } from 'components/Toolkit/SimpleDropdown/SimpleDropdown';
import { Menu } from 'components/Toolkit/Menu/Menu';
import { removeNonNumericCharacters } from 'helpers/forms';

import { Currency } from 'types';
import { useState } from 'react';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';

export interface IPriceInputRangeProps {
  value?: {
    from: string | undefined;
    to: string | undefined;
    currency: Currency | undefined;
  };
  onChangePrice: (
    currency: Currency,
    from: string | undefined,
    to: string | undefined,
  ) => void;
  label?: string | null;
  willUseSubText?: boolean;
  heightVariant?: HeightVariant;
  showValidPriceCheckbox?: boolean;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing.S12};
  ${Inputs} {
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

type PriceCurrency = {
  value: Currency;
  displayName: string;
  symbol: string;
};

const euro: PriceCurrency = {
  value: 'EUR',
  displayName: '€ EUR',
  symbol: '€',
};

const pound: PriceCurrency = {
  value: 'GBP',
  displayName: '£ GBP',
  symbol: '£',
};

function formatPrice(value: string | undefined, currencySymbol: string) {
  return value ? `${currencySymbol}${parseInt(value)?.toString()}` : '';
}

const PriceInputRange = (props: IPriceInputRangeProps) => {
  const {
    value,
    onChangePrice,
    label = 'Price',
    willUseSubText = true,
    heightVariant,
  } = props;

  const [currency, setCurrency] = useState<Currency>(value?.currency || 'EUR');
  const [from, setFrom] = useState<string | undefined>(value?.from);
  const [to, setTo] = useState<string | undefined>(value?.to);

  // On reset we need to update the internal to match external state
  useOnUpdateOnly(() => {
    setFrom(value?.from);
    setTo(value?.to);
  }, [value?.from, value?.to]);

  const priceCurrency = currency === 'GBP' ? pound : euro;

  const MAX_LENGTH = 10;

  const handleOnCurrencyChange = (currency: Currency) => {
    setCurrency(currency);
    onChangePrice(currency, value?.from, value?.to);
  };

  return (
    <div>
      <HeaderContainer>
        <Label htmlFor="search-price-filter-for">{label}</Label>
        <SimpleDropdown text={priceCurrency.displayName} variant="PRIMARY">
          <Menu
            items={[
              {
                displayName: euro.displayName,
                onClick: () => handleOnCurrencyChange(euro.value),
              },
              {
                displayName: pound.displayName,
                onClick: () => handleOnCurrencyChange(pound.value),
              },
            ]}
          />
        </SimpleDropdown>
      </HeaderContainer>
      <InputContainer>
        <TextField
          id="search-price-filter-for"
          type="text"
          inputMode="numeric"
          value={formatPrice(from, priceCurrency.symbol)}
          placeholder="From"
          pattern="\d*"
          onChange={(from) => {
            setFrom(removeNonNumericCharacters(from));
            onChangePrice(
              priceCurrency.value,
              removeNonNumericCharacters(from),
              to,
            );
          }}
          autoComplete="off"
          maxLength={MAX_LENGTH}
          willUseSubText={willUseSubText}
          heightVariant={heightVariant}
        />
        <TextField
          type="text"
          inputMode="numeric"
          value={formatPrice(to, priceCurrency.symbol)}
          placeholder="To"
          pattern="\d*"
          onChange={(to) => {
            setTo(removeNonNumericCharacters(to));
            onChangePrice(
              priceCurrency.value,
              from,
              removeNonNumericCharacters(to),
            );
          }}
          autoComplete="off"
          maxLength={MAX_LENGTH}
          willUseSubText={willUseSubText}
          heightVariant={heightVariant}
        />
      </InputContainer>
    </div>
  );
};

export { PriceInputRange };
