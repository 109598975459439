import {
  CarouselCard,
  CardTitle,
  MotorsLogo,
  LogoLink,
} from 'components/SearchPage/components/RecommendedAdsCarousel/RecommendedAdsCarousel.styled';
import { Carousel } from 'features/carousel/Carousel';
import { Image } from 'components/ToolkitV2/CardV2/components/Image/Image';
import { Price } from 'components/ToolkitV2/CardV2/components/Price/Price';
import { WithLink } from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink';

import { useRecommendedAdsCarousel } from 'components/SearchPage/components/RecommendedAdsCarousel/RecommendedAdsCarousel.hook';

import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';
import type { RecommendedeAdsCarouselProps } from 'components/SearchPage/components/RecommendedAdsCarousel/RecommendedAdsCarousel.typed';
import { CDN_STATIC_ASSETS } from 'utils';

const RecommendedAdsCarousel = (props: RecommendedeAdsCarouselProps) => {
  const { currentSection, recommendedAds } = props;

  const SCROLL_DISTANCE = 251; // Card width + padding
  const ACCESSIBILITY_LABEL = 'recommended_ads_carousel';
  const PRICE_FONT_SIZE_SMALL = 'B12';
  const CARS_SECTION_TITLE = 'Featured cars you may like';
  const NON_CARS_SECTION_TITLE = "Ireland's Best Selection of Dealership Cars";

  const recommendedAdsCarouselTitle =
    currentSection === 'cars' ? (
      CARS_SECTION_TITLE
    ) : (
      <>
        <LogoLink href="/cars?sellerType=pro">
          <MotorsLogo
            src={`${CDN_STATIC_ASSETS}/images/logos/donedeal-logo-motor.svg`}
            alt="A logo for DoneDeal motors"
          />
        </LogoLink>
        {NON_CARS_SECTION_TITLE}
      </>
    );

  const { handlePushToDataLayerOnClick } = useRecommendedAdsCarousel();

  return (
    <Carousel
      title={recommendedAdsCarouselTitle}
      scrollDistance={SCROLL_DISTANCE}
      accessibilityLabel={ACCESSIBILITY_LABEL}
    >
      {recommendedAds.map((ad, index) => {
        const {
          id,
          header,
          friendlyUrl,
          relativeUrl,
          photos,
          imageAlt,
          currency,
          price,
        } = ad;

        const carouselCardNumber = index + 1;
        const href = relativeUrl ?? friendlyUrl;
        const imgSrcWebp = photos?.[0].small2Webp;

        const onClick = () => handlePushToDataLayerOnClick(carouselCardNumber);

        return (
          <div
            key={id}
            onClick={onClick}
            id={`search_results_carousel_ad_impression_${carouselCardNumber}`}
          >
            <WithLink href={href}>
              <CarouselCard
                header={<CardTitle>{header}</CardTitle>}
                image={
                  <Image
                    orientationSmall={ORIENTATION.VERTICAL}
                    imgSrcWebp={imgSrcWebp}
                    alt={imageAlt}
                  />
                }
                orientationSmall={ORIENTATION.VERTICAL}
                paddingSmall="S8"
                gapSmall="S8"
                footer={
                  <Price
                    currency={currency}
                    price={price}
                    displayLabels={false}
                    priceSmallFontSize={PRICE_FONT_SIZE_SMALL}
                  />
                }
              />
            </WithLink>
          </div>
        );
      })}
    </Carousel>
  );
};

export { RecommendedAdsCarousel };
