import styled from 'styled-components';

import { LinkButton } from 'components/Toolkit/Button/LinkButton';
import { Button } from 'components/Toolkit/Button';

const OuterContainer = styled.div`
  overflow: hidden;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.S8};
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Chrome/Safari/Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  padding: ${({ theme }) => theme.spacing.S2};
`;

const FilterModalLink = styled(LinkButton)`
  white-space: nowrap;
`;

const FilterModalButton = styled(Button)`
  white-space: nowrap;
`;

export { OuterContainer, InnerContainer, FilterModalButton, FilterModalLink };
