import { useRouter } from 'next/router';

import { useFiltersState } from 'features/filters/Filters.context';
import { useLocationContext } from 'features/location/Location.context';

import {
  formatPathParameters,
  getFilterPathParameterData,
} from 'helpers/seo/searchParams';
import {
  getFilterValue,
  getRangeValue,
} from 'components/SearchPage/helpers/formatting';
import { asString } from 'utils/query-parameters';
import { findCountyByValue } from 'features/location/helpers';
import { mapMakeModelQuery } from 'components/SearchPage/features/makeModel/MakeModel.map';

const useSEOFilterValues = () => {
  const { query } = useRouter();
  const { section, make, ...rest } = query;
  const { filtersData } = useFiltersState();
  const { countyList } = useLocationContext();

  const { makePathParameter, modelPathParameter, SEOFilterValue } =
    formatPathParameters(section);
  const { formattedFilterName, filterValue } = getFilterPathParameterData(
    filtersData,
    SEOFilterValue,
  );

  const {
    year_from,
    year_to,
    fuelType,
    colour,
    transmission,
    bodyType,
    adType,
    area: areaQueryValue,
    sellerType,
  } = rest;

  const wanted = Boolean(Array.isArray(adType) ? adType[0] : adType);
  const formattedMakeQuery = asString(make);

  const mappedMakeModelQuery = formattedMakeQuery
    ? mapMakeModelQuery(formattedMakeQuery)
    : undefined;

  /*
   * If the user is coming from the homepage, the make and model value may be formatted as a query.
   * Until the homepage formats these values as path parameters, we look for a query value here.
   */
  const makeQueryValue = mappedMakeModelQuery?.make;
  const modelQueryValue = mappedMakeModelQuery?.model;
  const includeMakeModelQueryValue = Boolean(
    !makePathParameter &&
      makeQueryValue &&
      (!modelQueryValue || modelQueryValue.length === 1),
  );

  const makeValue = [
    ...(makePathParameter ? [makePathParameter] : []),
    ...(includeMakeModelQueryValue && makeQueryValue ? [makeQueryValue] : []),
  ];

  const modelValue = [
    ...(modelPathParameter ? [modelPathParameter] : []),
    ...(includeMakeModelQueryValue && modelQueryValue?.[0]
      ? [modelQueryValue[0]]
      : []),
  ];

  const years = getRangeValue(
    filtersData,
    'year',
    year_from,
    year_to,
    formattedFilterName,
    filterValue,
  );
  const fuelTypes = getFilterValue(
    filtersData,
    'fuelType',
    fuelType,
    formattedFilterName,
    filterValue,
  );
  const colours = getFilterValue(
    filtersData,
    'colour',
    colour,
    formattedFilterName,
    filterValue,
  );
  const transmissions = getFilterValue(
    filtersData,
    'transmission',
    transmission,
    formattedFilterName,
    filterValue,
  );
  const bodyTypes = getFilterValue(
    filtersData,
    'bodyType',
    bodyType,
    formattedFilterName,
    filterValue,
  );

  const formatQueryAsArray = (queryValue?: string) => {
    return queryValue ? [queryValue] : undefined;
  };

  const countyPathParam = findCountyByValue(countyList, SEOFilterValue);
  const areasByQuery = Array.isArray(areaQueryValue)
    ? areaQueryValue.flatMap(
        (item) => findCountyByValue(countyList, item)?.value ?? [],
      )
    : formatQueryAsArray(areaQueryValue);

  const areas = countyPathParam?.value
    ? [countyPathParam?.value]
    : areasByQuery;

  const isPrivateSellerAd = asString(sellerType) === 'private';

  return {
    wanted,
    years,
    fuelTypes,
    colours,
    transmissions,
    bodyTypes,
    makeValue,
    modelValue,
    areas,
    isPrivateSellerAd,
  };
};

export { useSEOFilterValues };
