import type { FilterPropsReturnType } from 'components/SearchPage/features/QuickFilters/FilterProps.hook.typed';

import { asFrom, asString, asTo } from 'utils/query-parameters';

import { CURRENCIES } from 'types';

import type { Param } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';

type UseFilterProps = {
  onChangeFilterValue: (args: {
    queryParam?: Param;
    filterName?: string;
    filterValue?: string | Array<string>;
    filterGroup?: string;
  }) => void;
  /**
   * The names and values of filters that may be referenced
   */
  filterQueryValues: Param;
};

const useFilterProps = (args: UseFilterProps) => {
  const { onChangeFilterValue, filterQueryValues } = args;

  const getFilterProps = (
    filterType: string,
    filterName: string,
    filterGroup?: 'filters' | 'ranges' | 'makeModel' | 'terms',
  ): FilterPropsReturnType => {
    switch (filterType) {
      case 'ListSingleSelect':
        return {
          onChange: singleSelectCallback(filterName, filterGroup),
          queryParam: {
            [filterName]: asString(filterQueryValues[filterName]),
          },
          value: asString(filterQueryValues[filterName]),
          defaultQueryParam: { [filterName]: '' },
        };
      case 'ListMultiSelect':
        return {
          onChange: listMultiSelectCallback(filterName, filterGroup),
          queryParam: { [filterName]: filterQueryValues[filterName] },
          value: filterQueryValues[filterName],
          defaultQueryParam: { [filterName]: '' },
        };
      case 'PriceInputRange':
        return {
          onChange: priceCallback(filterName, filterGroup),
          queryParam: formatPriceQueryValue(filterQueryValues),
          value: formatInitialPriceFilterValue(filterQueryValues),
          defaultQueryParam: {
            price_from: '',
            price_to: '',
            sterlingPrice_from: '',
            sterlingPrice_to: '',
            pricePerMonth_from: '',
            pricePerMonth_to: '',
            carFinance: '',
          },
        };
      case 'DropDownRange':
        return {
          onChange: dropDownRangeCallback(filterName, filterGroup),
          queryParam: formatRangeQueryValue(filterName, filterQueryValues),
          value: formatRangeValue(filterName, filterQueryValues),
          defaultQueryParam: {
            [asFrom(filterName)]: '',
            [asTo(filterName)]: '',
          },
        };
      case 'TextInputRange':
        return {
          queryParam: formatRangeQueryValue(filterName, filterQueryValues),
          onChange: dropDownRangeCallback(filterName, filterGroup),
          value: formatRangeValue(filterName, filterQueryValues),
          defaultQueryParam: {
            [asFrom(filterName)]: '',
            [asTo(filterName)]: '',
          },
        };
      case 'SingleSelectRange':
        return {
          queryParam: formatSingleSelectRangeValue(
            filterName,
            filterQueryValues,
          ),
          onChange: singleSelectRangeCallback(filterName),
          value: formatRangeValue(filterName, filterQueryValues),
          defaultQueryParam: {
            [asFrom(filterName)]: '',
            [asTo(filterName)]: '',
          },
        };
      case 'CheckBox':
        return {
          queryParam: {
            [filterName]: asString(filterQueryValues[filterName]),
          },
          onChange: singleSelectCallback(filterName, filterGroup),
          value: asString(filterQueryValues[filterName]),
          defaultQueryParam: { [filterName]: '' },
        };
      default:
        return {
          onChange: () => null,
          value: undefined,
          queryParam: undefined,
          defaultQueryParam: undefined,
        };
    }
  };

  const dropDownRangeCallback =
    (filterName: string, filterGroup?: string) => (value: Array<string>) => {
      const keyFrom = asFrom(filterName);
      const keyTo = asTo(filterName);
      const [from, to] = value;
      onChangeFilterValue({
        queryParam: {
          [keyFrom]: from,
          [keyTo]: to,
        },
        filterValue: [from, to],
        filterName,
        filterGroup,
      });
    };

  const formatRangeQueryValue = (filterName: string, query: Param) => {
    const keyFrom = asFrom(filterName);
    const keyTo = asTo(filterName);
    const from = asString(query[keyFrom]) ?? '';
    const to = asString(query[keyTo]) ?? '';

    return {
      [keyFrom]: from,
      [keyTo]: to,
    };
  };

  const formatRangeValue = (filterName: string, query: Param) => {
    const keyFrom = asFrom(filterName);
    const keyTo = asTo(filterName);
    /*
     * Values for ranges may join separate values using the "+" character.
     * This character is removed when the value is retrieved from the query object.
     *
     * For example, the values of the road tax filter may include this character 'now+9M/M',
     * which is formatted as part of the query object as 'now 9M/M'.
     *
     * We therefore check for a matching value based on this change.
     */
    const from = asString(query[keyFrom])?.replace(' ', '+') ?? '';
    const to = asString(query[keyTo])?.replace(' ', '+') ?? '';
    return [from, to];
  };

  const singleSelectCallback =
    (filterName: string, filterGroup?: string) => (value: string) =>
      onChangeFilterValue({
        queryParam: { [filterName]: value },
        filterValue: value,
        filterName,
        filterGroup,
      });

  const listMultiSelectCallback =
    (filterName: string, filterGroup?: string) => (value: string) => {
      const formattedValue = filterQueryValues
        ? filterQueryValues[filterName]
        : undefined;

      if (value == '') {
        onChangeFilterValue({
          queryParam: { [filterName]: undefined },
          filterValue: undefined,
          filterName,
          filterGroup,
        });
      } else if (Array.isArray(formattedValue)) {
        formatMultiSelectArrayValue(
          formattedValue,
          value,
          filterName,
          filterGroup,
        );
      } else if (
        typeof formattedValue === 'string' &&
        formattedValue.length > 0
      ) {
        formatMultiSelectStringValue(
          formattedValue,
          value,
          filterName,
          filterGroup,
        );
      } else {
        onChangeFilterValue({
          queryParam: {
            [filterName]: value,
          },
          filterValue: value,
          filterName,
          filterGroup,
        });
      }
    };

  const formatMultiSelectArrayValue = (
    formattedvalue: Array<string>,
    value: string,
    filterName: string,
    filterGroup?: string,
  ) => {
    if (formattedvalue.includes(value)) {
      const nonMatchingValues = formattedvalue.filter(
        (item: string) => item !== value,
      );
      if (nonMatchingValues.length > 1) {
        onChangeFilterValue({
          queryParam: { [filterName]: nonMatchingValues },
          filterValue: nonMatchingValues,
          filterName,
          filterGroup,
        });
      } else {
        const [matchingValue] = nonMatchingValues;
        onChangeFilterValue({
          queryParam: {
            [filterName]: matchingValue,
          },
          filterValue: matchingValue,
          filterName,
          filterGroup,
        });
      }
    } else {
      onChangeFilterValue({
        queryParam: {
          [filterName]: [...(formattedvalue ?? []), value],
        },
        filterValue: [...(formattedvalue ?? []), value],
        filterName,
        filterGroup,
      });
    }
  };

  const formatMultiSelectStringValue = (
    formattedvalue: string,
    value: string,
    filterName: string,
    filterGroup?: string,
  ) => {
    if (formattedvalue === value) {
      onChangeFilterValue({
        queryParam: { [filterName]: undefined },
        filterValue: undefined,
        filterName,
        filterGroup,
      });
    } else {
      onChangeFilterValue({
        queryParam: {
          [filterName]: [formattedvalue, value],
        },
        filterValue: [formattedvalue, value],
        filterName,
        filterGroup,
      });
    }
  };

  const formatPriceQueryValue = (query: Param) => {
    const price_from = asString(query.price_from) ?? '';
    const price_to = asString(query.price_to) ?? '';
    const sterlingPrice_from = asString(query.sterlingPrice_from) ?? '';
    const sterlingPrice_to = asString(query.sterlingPrice_to) ?? '';
    const pricePerMonth_from = asString(query.pricePerMonth_from) ?? '';
    const pricePerMonth_to = asString(query.pricePerMonth_to) ?? '';
    const carFinance = asString(query.carFinance) ?? '';

    return {
      price_from,
      price_to,
      sterlingPrice_from,
      sterlingPrice_to,
      pricePerMonth_from,
      pricePerMonth_to,
      carFinance,
    };
  };

  const formatInitialPriceFilterValue = (query: Param) => {
    const price_from = asString(query.price_from) ?? '';
    const price_to = asString(query.price_to) ?? '';
    const sterlingPrice_from = asString(query.sterlingPrice_from) ?? '';
    const sterlingPrice_to = asString(query.sterlingPrice_to) ?? '';
    const pricePerMonth_from = asString(query.pricePerMonth_from) ?? '';
    const pricePerMonth_to = asString(query.pricePerMonth_to) ?? '';
    const carFinance = asString(query.carFinance) ?? '';
    const currency =
      sterlingPrice_from || sterlingPrice_to ? CURRENCIES.GBP : CURRENCIES.EUR;

    const from = (price_from || sterlingPrice_from) ?? '';
    const to = (price_to || sterlingPrice_to) ?? '';
    const PPMFrom = pricePerMonth_from;
    const PPMTo = pricePerMonth_to;

    return [from, to, PPMFrom, PPMTo, currency, carFinance];
  };

  const priceCallback =
    (filterName: string, filterGroup?: string) => (value: Array<string>) => {
      const [fromValue, toValue, currency, typePrice = 'price'] = value;

      const price = typePrice === 'price';
      const pricePerMonth = typePrice === 'pricePerMonth';
      const isEuro = currency === CURRENCIES.EUR;
      const isGBP = currency === CURRENCIES.GBP;

      const price_from = price && isEuro ? fromValue : '';
      const price_to = price && isEuro ? toValue : '';
      const sterlingPrice_from = price && isGBP ? fromValue : '';
      const sterlingPrice_to = price && isGBP ? toValue : '';
      const pricePerMonth_from = pricePerMonth ? fromValue : '';
      const pricePerMonth_to = pricePerMonth ? toValue : '';
      const carFinance = typePrice === 'pricePerMonth' ? 'true' : '';

      const from = (price_from || sterlingPrice_from) ?? '';
      const to = (price_to || sterlingPrice_to) ?? '';
      const PPMFrom = pricePerMonth_from;
      const PPMTo = pricePerMonth_to;

      onChangeFilterValue({
        queryParam: {
          price_from,
          price_to,
          sterlingPrice_from,
          sterlingPrice_to,
          pricePerMonth_from,
          pricePerMonth_to,
          carFinance,
        },
        filterValue: [from, to, PPMFrom, PPMTo, currency, carFinance],
        filterName,
        filterGroup,
      });
    };

  const singleSelectRangeCallback =
    (filterName: string, filterGroup?: string) => (value: Array<string>) => {
      const [from, to] = value;

      onChangeFilterValue({
        queryParam: {
          [asTo(filterName)]: to,
          [asFrom(filterName)]: from,
        },
        filterValue: to || from,
        filterName,
        filterGroup,
      });
    };

  const formatSingleSelectRangeValue = (filterName: string, query: Param) => {
    const keyFrom = asFrom(filterName);
    const keyTo = asTo(filterName);
    const from = asString(query[keyFrom]) ?? '';
    const to = asString(query[keyTo]) ?? '';

    return {
      [keyTo]: to,
      [keyFrom]: from,
    };
  };

  return { getFilterProps };
};

export { useFilterProps };
