import { createContext, ReactElement, useContext } from 'react';
import type {
  ISearchPageFilter,
  ISortField,
} from 'api/types/searchPageApiTypes';
import { ResponseModelFilter } from 'types';
import { useFilters } from 'features/filters/Filters.hook';
import { FilterState, FilterDispatch } from 'features/filters/Filters.typed';

export const FilterDispatchContext = createContext({} as FilterDispatch);
export const FilterStateContext = createContext({} as FilterState);

export const useFiltersDispatch = (): FilterDispatch =>
  useContext(FilterDispatchContext);
export const useFiltersState = (): FilterState =>
  useContext(FilterStateContext);

export const FilterProvider = ({
  filtersData,
  sortFields,
  filtersMakeModelData,
  children,
  userId,
}: {
  filtersData: ISearchPageFilter[];
  sortFields: ISortField[];
  filtersMakeModelData: ResponseModelFilter;
  children: ReactElement;
  userId?: string | string[];
}): ReactElement => {
  const { state, dispatch } = useFilters(
    filtersData,
    sortFields,
    filtersMakeModelData,
    userId,
  );

  return (
    <FilterDispatchContext.Provider
      value={{
        ...dispatch,
      }}
    >
      <FilterStateContext.Provider
        value={{
          ...state,
        }}
      >
        {children}
      </FilterStateContext.Provider>
    </FilterDispatchContext.Provider>
  );
};
