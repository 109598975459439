import NextLink from 'next/link';
import { Link } from 'components/Toolkit/Button/Link';
import styled from 'styled-components';

export interface ISeoTagsProps {
  items: {
    text: string;
    url: string;
  }[];
}

const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: ${({ theme }) => theme.spacing.S8};
    margin-bottom: ${({ theme }) => theme.spacing.S8};
  }
`;

const Tag = styled.span`
  display: block;
  padding: ${({ theme }) => theme.spacing.S8};
  ${({ theme }) => theme.fontSize.B14};
  border: 1px solid ${({ theme }) => theme.colors.GREY_DARK};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  color: ${({ theme }) => theme.colors.GREY_DARK};

  &:hover {
    color: ${({ theme }) => theme.colors.BLUE};
    border-color: ${({ theme }) => theme.colors.BLUE};
  }

  &:focus {
    outline-color: ${({ theme }) => theme.colors.GREY_DARK};
  }
`;

function SeoTags({ items }: ISeoTagsProps) {
  return (
    <div>
      <Tags>
        {items.map((item, index) => (
          <li key={index}>
            <Link href={item.url} ofType="SECONDARY" NextLink={NextLink}>
              <Tag>{item.text}</Tag>
            </Link>
          </li>
        ))}
      </Tags>
    </div>
  );
}

export { SeoTags };
