import * as Styled from 'components/ToolkitV2/Inputs/CheckboxBase/CheckboxBase.styled';
import { CheckboxBaseProps } from 'components/ToolkitV2/Inputs/CheckboxBase/CheckboxBase.typed';
import React from 'react';

/*
 * This is the very basics of any checkbox component
 * We can use this generic checkbox to build other styles of checkboxes
 * e.g. Checkbox, ImageCheckbox, Switch, Toggle etc.
 * The use of label wraps the hidden input field and children encapsulating the
 * input field and elements we want to be clickable and eliminates the need to
 * use 'for' attribute.
 * */

const CheckboxBase = React.forwardRef<HTMLInputElement, CheckboxBaseProps>(
  (props: CheckboxBaseProps, ref) => {
    const { children, checked, className, ...rest } = props;

    return (
      <Styled.Label className={className}>
        <Styled.Input
          ref={ref}
          type="checkbox"
          defaultChecked={checked}
          {...rest}
        />
        {children}
      </Styled.Label>
    );
  },
);

CheckboxBase.displayName = 'CheckboxBase';

export { CheckboxBase };
