import { BaseSearch } from 'components/Toolkit/SearchModal/BaseSearch';

import { useKeywordSearch } from 'components/SearchPage/components/KeywordSearch/KeywordSearch.hook';

import { Container } from 'components/SearchPage/components/KeywordSearch/KeywordSearch.styled';

import type { KeywordSearchProps } from 'components/SearchPage/components/KeywordSearch/KeywordSearch.typed';

const KeywordSearch = (props: KeywordSearchProps) => {
  const { sectionName } = props;

  const {
    onClickSuggestion,
    keywordQueryValue,
    onSearch,
    onFocus,
    searchRef,
    handleSearchInput,
    autoSuggestItems,
    onClose,
  } = useKeywordSearch({ sectionName });

  return (
    <Container ref={searchRef}>
      <BaseSearch
        placeholder={`Search ${sectionName}`}
        onHandleSearch={onSearch}
        variant="INLINE"
        onInput={handleSearchInput}
        onHandleSelectSearchItem={onClickSuggestion}
        onClose={onClose}
        onFocus={onFocus}
        closeOnSearch
        allowEmptySearch
        presetInput={keywordQueryValue}
        autoSuggestItems={autoSuggestItems}
      />
    </Container>
  );
};

export { KeywordSearch };
