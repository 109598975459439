import styled, { css } from 'styled-components';

const RadioButtonWrapper = styled.div`
  cursor: pointer;
`;

const radioButtonStyles = css`
  margin-bottom: 0;

  label {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.S8};
`;

export { RadioButtonWrapper, radioButtonStyles, Wrapper };
