import { isOk } from 'domains/Result';

import { useLocationContext } from 'features/location/Location.context';
import { useLocationShared } from 'features/location/hooks/LocationShared/LocationShared.hook';
import { findCountyByValue } from 'features/location/helpers';
import { fireToast } from 'helpers/Toasts';

import type { UseLocationReadURLArgs } from 'features/location/hooks/LocationReadURL/LocationReadURL.hook.typed';

const useLocationReadURL = (args: UseLocationReadURLArgs) => {
  const { countyPathParam, countyQueryValue, countyTownQueryValue } = args;

  const { levelOneReset, levelTwoReset, countyList, selectedCounty } =
    useLocationContext();

  const {
    handleCountySelect,
    handleCountyTownSelect,
    getCountyAreas,
    mapAreas,
  } = useLocationShared();

  const countyPathParamOrQueryValue = countyPathParam ?? countyQueryValue;
  const county = countyPathParamOrQueryValue
    ? findCountyByValue(countyList, countyPathParamOrQueryValue)
    : undefined;

  const handleCountyQueryValueChange = () => {
    if (county) {
      handleCountySelect(county);
    } else if (selectedCounty) {
      levelOneReset();
    }
  };

  const handleCountyTownQueryValueChange = (radius?: string) => {
    if (countyTownQueryValue && county) {
      selectCountyTown(radius);
    } else if (county) {
      const mappedAreas = mapAreas(countyList, county?.displayName);
      levelTwoReset(mappedAreas);
    }
  };

  const selectCountyTown = async (radius?: string) => {
    if (county) {
      const areas = await getCountyAreas(county);

      if (isOk(areas)) {
        const [match] = areas.ok.filter(
          (area: { value: string; displayName: string }) =>
            area.displayName ==
            `${countyTownQueryValue}, ${county.displayName}`,
        );
        if (match) {
          handleCountyTownSelect(match, radius);
        }
      } else {
        fireToast({
          type: 'ERROR',
          text: 'Oops! Something went wrong, please try again later',
        });
      }
    }
  };

  return {
    handleCountyQueryValueChange,
    handleCountyTownQueryValueChange,
  };
};

export { useLocationReadURL };
