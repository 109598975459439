import type { ReactNode } from 'react';
import * as Styles from 'components/SearchPage/components/NoResults/styles';

export interface INoResults {
  headerText: string;
  subText: string | ReactNode;
  imageSrc?: string;
  className?: string;
}

function NoResults({ headerText, subText, imageSrc, className }: INoResults) {
  return (
    <Styles.Container className={className}>
      {imageSrc && (
        <Styles.Simg src={imageSrc} alt={headerText} width={64} height={64} />
      )}
      <div>
        <Styles.Header>{headerText}</Styles.Header>
        <Styles.SubText>{subText}</Styles.SubText>
      </div>
    </Styles.Container>
  );
}

export { NoResults };
