import * as Styled from 'features/filters/components/ListSingleSelectRadio/ListSingleSelectRadio.styled';
import { ListSingleSelectRadioProps } from 'features/filters/components/ListSingleSelectRadio/ListSingleSelectRadio.typed';
import { accessibleOnClick } from 'utils/Accessibility';
import { RadioButton } from 'components/Toolkit/Inputs/RadioButton';

const ListSingleSelectRadio = (props: ListSingleSelectRadioProps) => {
  const { options, onSelectOption, selectedOption, label, renderSpacer } =
    props;

  return (
    <>
      <Styled.Wrapper role="group" aria-label={label}>
        {options.map((option, index) => {
          const isSelected = selectedOption && selectedOption === option.value;

          return (
            <Styled.RadioButtonWrapper
              key={`option-${index}`}
              {...accessibleOnClick(
                () => onSelectOption && onSelectOption(option.value),
              )}
            >
              <RadioButton
                name={option.value}
                label={option.label}
                value={option.value}
                checked={!!isSelected}
                radioButtonStyles={Styled.radioButtonStyles}
                onChange={() => {
                  onSelectOption && onSelectOption(option.value);
                }}
                tabIndex={-1}
              />
            </Styled.RadioButtonWrapper>
          );
        })}
      </Styled.Wrapper>
      {renderSpacer}
    </>
  );
};

export { ListSingleSelectRadio };
