import { useRouter } from 'next/router';

import { useMakeModelReadURL } from 'components/SearchPage/features/makeModel/hooks/MakeModelReadURL.hook';
import { useMakeModelWriteURL } from 'components/SearchPage/features/makeModel/hooks/MakeModelWriteURL.hook';

import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';

import type { MakeModel } from 'components/SearchPage/features/makeModel/MakeModel.typed';

import { formatPathParameters } from 'helpers/seo/searchParams';

type UseMakeModelReadAndWriteURLArgs = {
  updateMakeModels: (args: {
    makeModels?: Array<MakeModel>;
    filterName?: string;
    searchQueryGroup?: string;
    makeModelEditable?: string;
  }) => void;
  filterName?: string;
  searchQueryGroup?: string;
  maximumAmountOfMakeModelGroupsToBeDisplayed: number;
};

const useMakeModelReadAndWriteURL = (args: UseMakeModelReadAndWriteURLArgs) => {
  const {
    filterName,
    updateMakeModels,
    searchQueryGroup,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
  } = args;

  const {
    query: { section, make: currentMultipleMakeAndOrModels, makeModelEditable },
  } = useRouter();

  const {
    currentSection,
    makePathParameter: currentMake,
    modelPathParameter: currentModel,
  } = formatPathParameters(section);

  const { mapMakeModels } = useMakeModelReadURL({
    currentMake,
    currentModel,
    currentMultipleMakeAndOrModels,
    currentSection,
    makeModelEditable,
  });

  const { onSelectMake, onSelectModel, onAddSelection } = useMakeModelWriteURL({
    filterName,
    updateMakeModels,
    searchQueryGroup,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
  });

  useOnUpdateOnly(() => {
    mapMakeModels();
  }, [
    currentMake,
    currentModel,
    currentMultipleMakeAndOrModels,
    currentSection,
    makeModelEditable,
  ]);

  return {
    onSelectMake,
    onSelectModel,
    onAddSelection,
  };
};

export { useMakeModelReadAndWriteURL };
