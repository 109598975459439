import { useMakeModelContext } from 'components/SearchPage/features/makeModel/MakeModelContext';
import { useMakeModelShared } from 'components/SearchPage/features/makeModel/hooks/MakeModelShared.hook';

import { asString } from 'utils/query-parameters';
import { mapMakeModelQuery } from 'components/SearchPage/features/makeModel/MakeModel.map';

type UseMakeModelReadURlArgs = {
  currentMake?: string;
  currentModel?: string;
  currentMultipleMakeAndOrModels?: string | Array<string>;
  currentSection?: string;
  makeModelEditable?: string | Array<string>;
};

const useMakeModelReadURL = (args: UseMakeModelReadURlArgs) => {
  const {
    currentMake,
    currentModel,
    currentMultipleMakeAndOrModels,
    currentSection = 'cars',
    makeModelEditable,
  } = args;

  const { setMakeModels } = useMakeModelContext();
  const { getModels } = useMakeModelShared({
    section: currentSection,
  });

  const mapMakeModels = () => {
    const makeModelEditableValueQueryValue = asString(makeModelEditable);
    // query is formatted as makeModelEditable = make_model_<index>
    const [, , editableMakeModelIndex] = makeModelEditableValueQueryValue
      ? makeModelEditableValueQueryValue.split('_')
      : '';

    if (currentMake) {
      const isEditable = parseInt(editableMakeModelIndex) === 0;

      if (isEditable) {
        getModels(currentMake);
      }

      setMakeModels([
        {
          make: currentMake,
          model: currentModel ? [currentModel] : [],
          isEditable,
        },
      ]);
    } else if (Array.isArray(currentMultipleMakeAndOrModels)) {
      const mappedMakesAndModels = currentMultipleMakeAndOrModels.map(
        (make, index) => {
          return handleMultipleMakesAndModels(
            make,
            parseInt(editableMakeModelIndex) === index,
          );
        },
      );

      setMakeModels(mappedMakesAndModels);
    } else if (typeof currentMultipleMakeAndOrModels === 'string') {
      const mappedMakeAndModels = [
        handleMultipleMakesAndModels(
          currentMultipleMakeAndOrModels,
          parseInt(editableMakeModelIndex) === 0,
        ),
      ];
      setMakeModels(mappedMakeAndModels);
    } else {
      setMakeModels();
    }
  };

  const handleMultipleMakesAndModels = (
    makeModelValue: string,
    isEditable: boolean,
  ) => {
    const mappedMakeAndModels = mapMakeModelQuery(makeModelValue, isEditable);

    const { make } = mappedMakeAndModels;

    if (isEditable) {
      getModels(make);
    }

    return mappedMakeAndModels;
  };

  return {
    mapMakeModels,
  };
};

export { useMakeModelReadURL };
