import { useRouter } from 'next/router';

import { asString } from 'utils/query-parameters';

import type { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import { getFilterPathParameterData } from 'helpers/seo/searchParams';

const useURLData = (filter?: ISearchPageFilter) => {
  const {
    query: { section, area, countyTown, radius, make, makeModelEditable },
  } = useRouter();
  /* Note that although there may be a path parameter representing a filter value,
  that value does not necessarily correspond to the current filter */
  const [_section, makeQueryValue, modelQueryValue, SEOFilterQueryValue] =
    Array.isArray(section) ? section : [];
  const { filterValue: currentFilterPathParameterValue, formattedFilterName } =
    getFilterPathParameterData(filter ? [filter] : [], SEOFilterQueryValue);

  const countyQueryValue = asString(area);
  const countyTownQueryValue = asString(countyTown);
  const radiusQueryValue = asString(radius);
  const currentMake = asString(makeQueryValue);
  const currentModel = asString(modelQueryValue);
  const currentMultipleMakeAndOrModels = make;
  const currentSection = asString(section) ?? 'all';

  return {
    SEOFilterQueryValue,
    currentFilterPathParameterValue,
    formattedFilterName,
    countyQueryValue,
    countyTownQueryValue,
    radiusQueryValue,
    currentMake,
    currentModel,
    currentMultipleMakeAndOrModels,
    currentSection,
    makeModelEditable,
  };
};

export { useURLData };
