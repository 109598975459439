import { useState } from 'react';

import { searchPageApi } from 'api/searchPageApi';

import { fireToast } from 'helpers/Toasts';

import type { IGetAdsRequest, IGetSection } from 'api/types/searchPageApiTypes';
import type { Section } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.typed';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { API_CLIENT_TIMEOUT } from 'utils';

export type UseSectionDisplayInformationProps = {
  initialSections: IGetSection[];
  initialSectionName: string;
  initialParentName: string | null;
  initialParentDisplayName: string | null;
  initialSearchMatches: number;
};

type SectionData = {
  sections?: Section[];
  sectionName: string;
  parentName: string | null;
  parentDisplayName: string | null;
  searchMatches: number;
};

function useSectionDisplayInformation(
  props: UseSectionDisplayInformationProps,
) {
  const {
    initialSections,
    initialSectionName,
    initialParentName,
    initialParentDisplayName,
    initialSearchMatches,
  } = props;

  const [sectionData, setSectionData] = useState<SectionData>({
    sections: initialSections,
    sectionName: initialSectionName,
    parentName: initialParentName,
    parentDisplayName: initialParentDisplayName,
    searchMatches: initialSearchMatches,
  });

  async function retrieveSections(section: string, payload: IGetAdsRequest) {
    try {
      const { data } = await searchPageApi.getSections(
        section,
        payload,
        API_CLIENT_TIMEOUT,
      );
      setSectionData({
        sections: data.subSections,
        sectionName: data.displayName,
        parentName: data.parentName ?? null,
        parentDisplayName: data.parentDisplayName ?? null,
        searchMatches: data.searchMatches ?? 0,
      });
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling retrieveSections'),
        tags: [PAGE.SEARCH],
        customData: {
          message: error.message || 'client_error',
          section: section,
          payload: payload,
        },
      });
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
    }
  }

  return {
    sections: sectionData.sections,
    sectionName: sectionData.sectionName,
    parentName: sectionData.parentName,
    parentDisplayName: sectionData.parentDisplayName,
    searchMatches: sectionData.searchMatches,
    retrieveSections,
  };
}

export { useSectionDisplayInformation };
