import { useState, useMemo, useCallback } from 'react';

import { savedSearchApi } from 'api/savedSearches';

import { useAuthOptions } from 'hooks/useAuthOptions';
import { useBrowserStorage } from 'hooks/UseBrowserStorage';
import { useUserContext } from 'contexts/UserContext';

import { formatAuthorizationHeader } from 'helpers/auth';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { useFilterCount } from 'components/SearchPage/hooks/useFilterCount/useFilterCount';

const useSavedSearchPrompt = () => {
  const { count } = useFilterCount();
  const { status, accessToken } = useAuthOptions();
  const { setLocalStorageItem, getLocalStorageItem } = useBrowserStorage();
  const { user } = useUserContext();

  const [isSavedSearchPromptOpen, setIsSavedSearchPromptOpen] = useState(false);

  const SAVED_SEARCH_PROMPT_EXPIRY_DATE_KEY =
    'displaySavedSearchPromptExpiryDate';
  const THIRTY_DAYS_IN_MILLISECONDS = 2592000000;
  const APPLIED_FILTER_THRESHOLD_FOR_DISPLAYING_MODAL = 2;

  const openToolTipAfterDelay = () =>
    setTimeout(() => setIsSavedSearchPromptOpen(true), 5000);

  const getSavedSearches = async (userId: number, token: string) => {
    try {
      // Return the first result only if any
      const { data } = await savedSearchApi.getSavedSearches(
        {
          userId: userId.toString(),
          page: 0,
          offset: 1,
        },
        formatAuthorizationHeader(token),
      );

      return data;
    } catch (error) {
      rg4js('send', {
        error: new Error('Error fetching saved searches'),
        tags: [PAGE.SEARCH],
        customData: {
          message: error.message || 'client_error',
        },
      });
    }
  };

  const handleTooltipDisplay = () => {
    if (count >= APPLIED_FILTER_THRESHOLD_FOR_DISPLAYING_MODAL) {
      const expiryDate = getLocalStorageItem(
        SAVED_SEARCH_PROMPT_EXPIRY_DATE_KEY,
      );
      const hasExpired = expiryDate ? parseInt(expiryDate) < Date.now() : true;
      if (hasExpired) {
        handleUserStatus();
      }
    }
  };

  const handleUserStatus = useCallback(async () => {
    if (status === 'unauthenticated') {
      openToolTipAfterDelay();
    } else if (status === 'authenticated' && user?.id && accessToken) {
      const savedSearches = await getSavedSearches(user.id, accessToken);
      if (savedSearches && savedSearches.length === 0) {
        openToolTipAfterDelay();
      }
    }
  }, [accessToken, status, user?.id]);

  useMemo(handleTooltipDisplay, [count, handleUserStatus, getLocalStorageItem]);

  const closeSavedSearchPrompt = () => {
    setIsSavedSearchPromptOpen(false);
    setLocalStorageItem(
      SAVED_SEARCH_PROMPT_EXPIRY_DATE_KEY,
      (Date.now() + THIRTY_DAYS_IN_MILLISECONDS).toString(),
    );
  };

  return {
    isSavedSearchPromptOpen,
    closeSavedSearchPrompt,
  };
};

export { useSavedSearchPrompt };
