import { useState } from 'react';

import { useOnScroll } from 'hooks/UseOnScroll';

const useFooter = () => {
  const [displaySaveSearch, setDisplaySaveSearch] = useState(true);

  useOnScroll({
    onScrollDown: () => setDisplaySaveSearch(false),
    onScrollUp: () => setDisplaySaveSearch(true),
  });

  return {
    displaySaveSearch,
  };
};

export { useFooter };
