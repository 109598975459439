import { FilterHorizontalIc } from '@dsch/dd-icons';

import { QuickFilter } from 'components/SearchPage/features/QuickFilters/components/QuickFilter/QuickFilter';
import { FilterDisplay } from 'components/SearchPage/features/QuickFilters/components/FilterBottomSheet/FilterBottomSheet';

import { useQuickFilters } from 'components/SearchPage/features/QuickFilters/QuickFilters.hook';

import * as Styled from 'components/SearchPage/features/QuickFilters/QuickFilters.styled';

import type { QuickFiltersProps } from 'components/SearchPage/features/QuickFilters/QuickFilters.typed';

import { theme } from 'helpers/Theme';
import {
  LocationProvider,
  useLocationContext,
} from 'features/location/Location.context';

import { MakeModelProvider } from 'components/SearchPage/features/makeModel/MakeModelContext';

const QuickFilters = (props: QuickFiltersProps) => {
  const { className, sectionName } = props;

  const { openFilterModal, quickFiltersData, countDisplay, displayText } =
    useQuickFilters();

  const {
    countyList,
    coordinates: initialCoordinates,
    radiusList,
    locationDisplay,
    selectedCounty,
    radius,
    countyTown,
  } = useLocationContext();

  return (
    <MakeModelProvider>
      <LocationProvider
        initialSelectedCounty={selectedCounty}
        initialRadius={radius}
        initialLocationDisplay={locationDisplay}
        initialCoordinates={initialCoordinates}
        initialCountyTown={countyTown}
        countyList={countyList}
        radiusList={radiusList}
      >
        <Styled.OuterContainer className={className}>
          <Styled.InnerContainer>
            <Styled.FilterModalButton inline onClick={openFilterModal}>
              <FilterHorizontalIc
                color={theme.colors.WHITE}
                height={20}
                width={20}
                data-testid="filter-button"
              />
              &nbsp;Filters{countDisplay}
            </Styled.FilterModalButton>
            {quickFiltersData.map((filter) => {
              return (
                <QuickFilter
                  filter={filter}
                  key={`quick-filter-${filter.name}`}
                  displayText={displayText(filter) ?? filter.displayName}
                />
              );
            })}
            <Styled.FilterModalLink
              ofType="SECONDARY"
              onClick={openFilterModal}
            >
              All Filters
            </Styled.FilterModalLink>
          </Styled.InnerContainer>
        </Styled.OuterContainer>
        <FilterDisplay sectionName={sectionName} />
      </LocationProvider>
    </MakeModelProvider>
  );
};

export { QuickFilters };
