import debounce from 'lodash.debounce';

import { ListSingleSelectNative } from 'features/filters/components/ListSingleSelectNative/ListSingleSelectNative';
import { ListSingleSelectTag } from 'features/filters/components/ListSingleSelectTag/ListSingleSelectTag';
import { ListMultiSelect } from 'features/filters/components/ListMultiSelect/ListMultiSelect';
import { ListMultiSelectTag } from 'features/filters/components/ListMultiSelectTag/ListMultiSelectTag';
import { ListMultiSelectCheckbox } from 'features/filters/components/ListMultiSelectCheckbox/ListMultiSelectCheckbox';
import { LocationFilter } from 'features/filters/components/Location/Location';
import { PriceInputRange } from 'features/filters/components/PriceInputRange/PriceInputRange';
import { PriceSelectRange } from 'features/filters/components/PriceSelectRange/PriceSelectRange';
import { PriceSelectMonthRange } from 'features/filters/components/PriceSelectMonthRange/PriceSelectMonthRange';
import { MakeModelFilter } from 'features/filters/components/MakeModelV2/MakeModelFilter';
import { DropDownRange } from 'features/filters/components/DropDownRange/DropDownRange';
import { TextInputRange } from 'features/filters/components/TextInputRange/TextInputRange';
import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';
import { ListSingleSelect } from 'features/filters/components/ListSingleSelect/ListSingleSelect';

import {
  mapToMultiSelect,
  mapToMultiSelectImage,
  mapToPriceSelect,
  mapToSingleSelect,
} from 'features/filters/Filters.mapper';
import { mapMakeOptions } from 'components/SearchPage/features/makeModel/MakeModel.map';
import { getMultiOptionDisplay } from 'components/SearchPage/helpers/formatting';
import { asString } from 'utils/query-parameters';

import type { MakeModelFilterProps } from 'features/filters/components/MakeModelV2/MakeModelFilter.typed';
import type { ISearchPageFilterValue } from 'api/types/searchPageApiTypes';
import type {
  FiltersProps,
  SearchPageFilter,
} from 'features/filters/FiltersV2.typed';
import type { Option } from 'features/filters/components/ListSingleSelect/ListSingleSelect.typed';
import { CURRENCIES } from 'types';
import { MultiSelectImage } from 'features/filters/components/MultiSelectImage/MultiSelectImage';
import * as Styled from 'features/filters/Filters.styled';
import { ListSingleSelectRadio } from 'features/filters/components/ListSingleSelectRadio/ListSingleSelectRadio';
import { PriceInputRangeWithCheckbox } from 'features/filters/components/PriceInputRangeWithCheckbox/PriceInputRangeWithCheckbox';

const FiltersV2 = (props: FiltersProps) => {
  const {
    filter,
    locationConfiguration,
    makeModelConfiguration,
    validPriceConfiguration,
    sectionName,
  } = props;

  const renderFilter = (filter: SearchPageFilter) => {
    switch (filter.filterType.name) {
      case 'Group':
        return (
          <Styled.LocationFilter>
            <LocationFilter
              {...locationConfiguration}
              displayLabel={false}
              heightVariant="LARGE"
            />
          </Styled.LocationFilter>
        );
      case 'ListSingleSelect':
        return displayListSingleSelect(filter);
      case 'ListMultiSelect': {
        if (filter.name === 'makeModel') {
          return displayMakeModel(makeModelConfiguration, filter.values);
        } else return displayListMultiSelect(filter);
      }
      case 'PriceInputRange':
        return displayPrice(filter);
      case 'TextInputRange':
        return displayInputRange(filter);
      case 'DropDownRange':
        return displayDropDownRange(filter);
      case 'SingleSelectRange':
        return displayListSingleSelectRange(filter);
      case 'CheckBox':
        return displayCheckBox(filter);
      default:
        return undefined;
    }
  };

  const displayInputRange = (filter: SearchPageFilter) => {
    const [from, to] = Array.isArray(filter.value) ? filter.value : ['', ''];
    return (
      <TextInputRange
        value={{
          from: from || undefined,
          to: to || undefined,
        }}
        onChange={(from = '', to = '') => filter.onChange([from, to])}
        willUseSubText={false}
        heightVariant="LARGE"
      />
    );
  };

  const displayDropDownRange = (filter: SearchPageFilter) => {
    const [from, to] = Array.isArray(filter.value) ? filter.value : ['', ''];
    if (!filter.values || filter.values.length === 0) return null;
    const [values] = filter.values;
    const { from: fromValues, to: toValues } = values;
    const dropDownRangeFilter = {
      from: fromValues,
      to: toValues,
    };
    return (
      <DropDownRange
        name={filter.name}
        from={{
          options: mapToSingleSelect(dropDownRangeFilter.from),
          selectedValue: from || '',
        }}
        to={{
          options: mapToSingleSelect(dropDownRangeFilter.to),
          selectedValue: to || '',
        }}
        onFromChange={(value: string) => filter.onChange([value, to])}
        onToChange={(value: string) => filter.onChange([from, value])}
        willUseSubText={false}
        heightVariant="LARGE"
      />
    );
  };

  const displayListSingleSelect = (filter: SearchPageFilter) => {
    const { values, value: filterValue, name, onChange } = filter;
    const options = mapToSingleSelect(values ?? []);
    // Set default as first option in case first value is not empty string
    const value = asString(filterValue) ?? options[0].value ?? '';

    if (filter.variant === 'DONEDEAL_TAG') {
      return (
        <ListSingleSelectTag
          name={filter.name}
          options={options}
          onChange={onChange}
          value={value}
          heightVariant="LARGE"
        />
      );
    } else if (filter.variant === 'DONEDEAL_RADIO') {
      return (
        <ListSingleSelectRadio
          name={filter.name}
          options={options}
          onSelectOption={onChange}
          selectedOption={value}
          renderSpacer={<Styled.SpacerSm />}
        />
      );
    } else {
      return (
        <ListSingleSelectNative
          name={name}
          options={options}
          onChange={onChange}
          value={value}
          heightVariant="LARGE"
          willUseSubText={false}
        />
      );
    }
  };

  const displayListMultiSelect = (filter: SearchPageFilter) => {
    switch (filter.variant) {
      case 'DONEDEAL_TAG': {
        const { name, values, value, onChange } = filter;

        const options = mapToMultiSelect(values ?? []);
        const selectedOptions = Array.isArray(value) ? value : [value ?? ''];
        const defaultOption = options[0]?.value;

        return (
          <ListMultiSelectTag
            name={name}
            options={options}
            selectedOptions={selectedOptions}
            onSelectOption={onChange}
            onClear={() => onChange(defaultOption)}
            heightVariant="LARGE"
          />
        );
      }

      case 'DONEDEAL_CHECKBOX': {
        const { name, values, value, onChange } = filter;

        const options = mapToMultiSelect(values ?? []);
        const selectedOptions = Array.isArray(value) ? value : [value ?? ''];
        const defaultOption = options[0]?.value;

        return (
          <ListMultiSelectCheckbox
            name={name}
            options={options}
            selectedOptions={selectedOptions}
            onSelectOption={onChange}
            onClear={() => onChange(defaultOption)}
          />
        );
      }

      case 'DONEDEAL_IMAGE': {
        const { displayName, values, value, onChange } = filter;
        const options = mapToMultiSelectImage(
          values ?? [],
          filter.name,
          sectionName,
        );
        const selectedOptions = Array.isArray(value) ? value : [value ?? ''];

        return (
          <MultiSelectImage
            label={displayName}
            options={options}
            selectedOptions={selectedOptions}
            onSelectOption={onChange}
          />
        );
      }

      default: {
        const { name, values, value: selectedValue, onChange } = filter;

        const options = mapToMultiSelect(values ?? []);
        const selectedOptions = Array.isArray(selectedValue)
          ? selectedValue
          : [selectedValue ?? ''];
        const [firstOption] = options;
        const placeholder =
          values &&
          selectedValue &&
          getMultiOptionDisplay(values, selectedOptions);
        const fallbackPlaceholder = firstOption.label;
        const defaultOption = options[0]?.value;

        return (
          <>
            <ListMultiSelect
              name={name}
              placeholder={placeholder ?? fallbackPlaceholder}
              options={options}
              selectedOptions={selectedOptions}
              onSelectOption={onChange}
              onClear={() => onChange(defaultOption)}
            />
            <Styled.SpacerSm />
          </>
        );
      }
    }
  };

  const displayMakeModel = (
    makeModel: MakeModelFilterProps,
    filterValues: ISearchPageFilterValue[] | null,
  ) => {
    const {
      selectMake,
      selectModel,
      onAddSelection,
      maximumAmountOfMakeModelGroupsToBeDisplayed,
    } = makeModel;

    const makes = filterValues?.[0];
    const all = makes?.all;
    const popular = makes?.popular;

    const makeOptions = mapMakeOptions(all, popular);

    return (
      <MakeModelFilter
        selectMake={selectMake}
        selectModel={selectModel}
        onAddSelection={onAddSelection}
        maximumAmountOfMakeModelGroupsToBeDisplayed={
          maximumAmountOfMakeModelGroupsToBeDisplayed
        }
        makeOptions={makeOptions}
        displayLabel={false}
        heightVariant="LARGE"
      />
    );
  };

  const displayPrice = (filter: SearchPageFilter) => {
    const { onChange } = filter;
    const [from, to, PPMFrom, PPMTo, currency, carFinance] = Array.isArray(
      filter.value,
    )
      ? filter.value
      : ['', '', '', '', '', ''];

    const formattedCurrency =
      currency === CURRENCIES.GBP ? CURRENCIES.GBP : CURRENCIES.EUR;
    const currencySymbol = currency === CURRENCIES.GBP ? '£' : '€';

    switch (filter.variant) {
      case 'DONEDEAL_PRICE_DROPDOWN': {
        const [fromAndToOptions] = filter.values ? filter.values : [];
        const { from: fromOptions, to: toOptions } = fromAndToOptions;

        return (
          <PriceSelectRange
            from={{
              options: mapToPriceSelect(fromOptions, currencySymbol),
              selectedValue: from ?? '',
            }}
            to={{
              options: mapToPriceSelect(toOptions, currencySymbol),
              selectedValue: to ?? '',
            }}
            value={{
              to,
              from,
              currency: formattedCurrency,
            }}
            onChangePrice={(currency, from = '', to = '') => {
              onChange([from, to, currency]);
            }}
            willUseSubText={false}
            label={''}
            heightVariant="LARGE"
          />
        );
      }
      case 'DONEDEAL_PRICE_DROPDOWN_PPM': {
        const [options] = filter.values ? filter.values : [];
        const {
          from: fromOptions,
          to: toOptions,
          pricePerMonthFrom,
          pricePerMonthTo,
        } = options;

        const typePrice = carFinance === 'true' ? 'pricePerMonth' : 'price';

        return (
          <PriceSelectMonthRange
            typePrice={typePrice}
            from={{
              options: mapToPriceSelect(fromOptions, currencySymbol),
              selectedValue: from,
            }}
            to={{
              options: mapToPriceSelect(toOptions, currencySymbol),
              selectedValue: to,
            }}
            fromMonth={{
              options: mapToPriceSelect(pricePerMonthFrom, currencySymbol),
              selectedValue: PPMFrom,
            }}
            toMonth={{
              options: mapToPriceSelect(pricePerMonthTo, currencySymbol),
              selectedValue: PPMTo,
            }}
            onChangePrice={(currency, fromValue, toValue, typePrice) => {
              onChange([fromValue ?? '', toValue ?? '', currency, typePrice]);
            }}
            onChangeType={(currency, type) => {
              const newCurrency =
                type === 'pricePerMonth' ? CURRENCIES.EUR : currency;
              onChange(['', '', newCurrency, type]);
            }}
            value={{
              from: from || PPMFrom,
              to: to || PPMTo,
              currency: formattedCurrency,
              typePrice: typePrice,
            }}
            showCurrencySelectorInTitle={false}
            label={null}
            willUseSubText={false}
            heightVariant="LARGE"
          />
        );
      }
      case 'DONEDEAL_PRICE_CHECKBOX': {
        return (
          <PriceInputRangeWithCheckbox
            value={{
              from,
              to,
              currency: formattedCurrency,
            }}
            onChangePrice={debounce((currency, from, to) => {
              onChange([from, to, currency]);
            }, 500)}
            label={''}
            willUseSubText={false}
            heightVariant="LARGE"
            checkboxLabel={filter.subFilters?.[0]?.displayName}
            validPriceConfiguration={validPriceConfiguration}
          />
        );
      }
      default:
        return (
          <PriceInputRange
            value={{
              from,
              to,
              currency: formattedCurrency,
            }}
            onChangePrice={debounce((currency, from, to) => {
              onChange([from, to, currency]);
            }, 500)}
            label={''}
            willUseSubText={false}
            heightVariant="LARGE"
          />
        );
    }
  };

  const displayListSingleSelectRange = (filter: SearchPageFilter) => {
    const { values, value: filterValue, name, onChange } = filter;

    const options = mapToSingleSelect(values ?? []);
    const [from, to] = Array.isArray(filterValue) ? filterValue : [];
    const selectedOption = options.find(
      ({ value, type }) =>
        (from && value === from && type === 'MIN') ||
        (to && value === to && type === 'MAX'),
    );
    const [firstOption] = options;
    const defaultOption =
      firstOption.type === 'MAX'
        ? ['', firstOption.value]
        : [firstOption.value, ''];

    return (
      <ListSingleSelect
        name={name}
        options={options}
        selectedOption={selectedOption ?? firstOption}
        onSelectOption={({ value, type }: Option) => {
          const selection = type === 'MAX' ? ['', value] : [value, ''];
          onChange(selection);
        }}
        onClear={() => onChange(defaultOption)}
        placeholder={selectedOption ? selectedOption.label : firstOption.label}
        renderSpacer={<Styled.SpacerSm />}
        heightVariant="LARGE"
      />
    );
  };

  const displayCheckBox = (filter: SearchPageFilter) => {
    const { onChange, value, displayName, name } = filter;

    const checked = value === 'true';

    const onChangeHandler = (isChecked: boolean) =>
      onChange(isChecked ? 'true' : '');

    return (
      <Checkbox
        label={displayName}
        checked={checked}
        onChange={onChangeHandler}
        name={name}
        willUseSubText={false}
      />
    );
  };

  return <>{renderFilter(filter)}</>;
};

export { FiltersV2 };
