import styled, { css } from 'styled-components';
import { RadioButton } from 'components/Toolkit/Inputs/RadioButton';

export const RadioInput = styled(RadioButton)`
  width: 100%;
`;

export const radioButtonStyles = css`
  grid-template-columns: auto 24px;

  input {
    order: 2;
  }
  label {
    cursor: pointer;
    order: 1;
  }
`;
