import styled from 'styled-components';

const Label = styled.label`
  cursor: pointer;
  user-select: none;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export { Label, Input };
