import { Menu } from 'components/Toolkit/Menu/Menu';
import { SimpleDropdown } from 'components/Toolkit/SimpleDropdown/SimpleDropdown';
import * as SortStyle from 'components/SearchPage/components/Sort/Sort.styled';
import { useSort } from 'components/SearchPage/components/Sort/Sort.hook';
import type { SortProps } from 'components/SearchPage/components/Sort/Sort.typed';
import { SwapVerticalOutlineIc } from '@dsch/dd-icons';
import { DisplayContainer } from 'components/Support/DisplayContainer/DisplayContainer';

const Sort = (props: SortProps) => {
  const { labelText, items, labelMobileElement, mobileItems } = useSort(props);
  return (
    <>
      <DisplayContainer
        config={{
          small: 'none',
          medium: 'flex',
          large: 'flex',
        }}
      >
        <SimpleDropdown text={labelText}>
          <Menu items={items} />
        </SimpleDropdown>
      </DisplayContainer>
      <DisplayContainer
        config={{
          small: 'flex',
          medium: 'none',
          large: 'none',
        }}
      >
        <SimpleDropdown
          text={
            <>
              <SwapVerticalOutlineIc width={20} height={20} />
              <SortStyle.Text data-testid="sort">
                {labelMobileElement}
              </SortStyle.Text>
            </>
          }
          showArrow={false}
        >
          <Menu items={mobileItems} />
        </SimpleDropdown>
      </DisplayContainer>
    </>
  );
};

export { Sort };
