import type { LocationFilterProps } from 'features/filters/components/Location/Location.typed';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useLocationContext } from 'features/location/Location.context';
import { LEVELS } from 'features/location/Location.typed';
import { useLocationWriteState } from 'features/location/hooks/LocationWriteState/LocationWriteState.hook';
import { useLocationWriteURL } from 'features/location/hooks/LocationWriteURL/LocationWriteURL.hook';
import { findCountyByValue } from 'features/location/helpers';

const useLocationFilter = () => {
  const {
    areaQueryValue,
    carFilterQueryValue,
    updateURL,
    makeQueryValue,
    modelQueryValue,
  } = useURLStateManagement();

  const countyPathParam =
    carFilterQueryValue !== '' ? carFilterQueryValue : undefined;

  const {
    countyTown,
    coordinates: { latitude, longitude },
    selectedCounty,
    levelOneReset,
    radius,
    countyList,
  } = useLocationContext();

  const includesCountyPathParam = findCountyByValue(
    countyList,
    carFilterQueryValue,
  )?.value;

  const applySEOPathParam = Boolean(
    makeQueryValue &&
      modelQueryValue &&
      (!carFilterQueryValue || includesCountyPathParam),
  );

  const { handleSelectListItem } = useLocationWriteURL({
    countyPathParam: includesCountyPathParam ? countyPathParam : undefined,
    applySEOPathParam,
    URLCallback: updateURL,
    areaQueryValue,
  });

  const { handleSelectListItem: onSelect } = useLocationWriteState();

  const level = countyTown ? LEVELS.LEVEL_TWO : LEVELS.LEVEL_ONE;

  const locationConfiguration: LocationFilterProps = {
    displayLabel: false,
    onSelect,
  };

  const handleSelectLocation = () =>
    handleSelectListItem({
      latitude,
      longitude,
      county: selectedCounty?.value ?? '',
      countyDisplayName: selectedCounty?.displayName ?? '',
      countyTown: countyTown ?? '',
      level,
      radius,
    });

  return {
    handleSelectLocation,
    locationConfiguration,
    clearLocation: levelOneReset,
  };
};

export { useLocationFilter };
