import styled from 'styled-components';

import { Button } from 'components/Toolkit/Button/Button';

type FilterButtonProps = { isSelected: boolean };

const FilterButton = styled(Button)<FilterButtonProps>`
  white-space: nowrap;
  ${({ theme }) => theme.fontSize.M16};
  font-weight: ${({ isSelected, theme }) =>
    isSelected ? theme.fontWeight.bold : theme.fontWeight.regular};

  border: ${({ isSelected, theme }) =>
    isSelected
      ? `2px solid ${theme.colors.GREY_DARKER}`
      : `1px solid ${theme.colors.GREY}`};

  :hover {
    border: ${({ isSelected, theme }) =>
      `${isSelected ? '2' : '1'}px solid ${theme.colors.GREY_DARKER}`};
  }

  :focus-visible {
    border: ${({ isSelected, theme }) =>
      `${isSelected ? '2' : '1'}px solid ${theme.colors.WHITE}`};
  }
`;

export { FilterButton };
