import { useRef } from 'react';
import { useRouter } from 'next/router';

import { useQuickFilterContext } from 'components/SearchPage/features/QuickFilters/QuickFilters.context';
import { useFilterProps } from 'components/SearchPage/features/QuickFilters/FilterProps.hook';
import { useLocationReadURL } from 'features/location/hooks/LocationReadURL/LocationReadURL.hook';
import { useURLData } from 'components/SearchPage/features/QuickFilters/URLData.hook';
import { useMakeModelReadURL } from 'components/SearchPage/features/makeModel/hooks/MakeModelReadURL.hook';

import { camelToSnakeCase } from 'helpers/formatting';

import type { UseQuickFilterProps } from 'components/SearchPage/features/QuickFilters/components/QuickFilter/QuickFilter.typed';

const useQuickFilter = (args: UseQuickFilterProps) => {
  const { filter } = args;

  const ref = useRef<HTMLButtonElement>(null);

  const { selectQuickFilter, onChangeFilterValue, selectedFilterQueryParam } =
    useQuickFilterContext();
  const { query } = useRouter();

  const { getFilterProps } = useFilterProps({
    onChangeFilterValue,
    filterQueryValues: { ...query, ...selectedFilterQueryParam },
  });
  const {
    currentFilterPathParameterValue,
    formattedFilterName,
    countyQueryValue,
    countyTownQueryValue,
    radiusQueryValue,
    currentMake,
    currentModel,
    currentMultipleMakeAndOrModels,
    currentSection,
    makeModelEditable,
  } = useURLData(filter);

  const { value: initialValue, queryParam: initialQueryParam } = getFilterProps(
    filter?.filterType.name ?? '',
    filter?.name ?? '',
  );

  const countyPathParam =
    currentFilterPathParameterValue !== ''
      ? currentFilterPathParameterValue
      : undefined;

  const { handleCountyQueryValueChange, handleCountyTownQueryValueChange } =
    useLocationReadURL({
      countyQueryValue,
      countyPathParam,
      countyTownQueryValue,
    });

  const initialiseLocationFilter = () => {
    if (countyTownQueryValue) {
      handleCountyTownQueryValueChange(radiusQueryValue);
    } else {
      handleCountyQueryValueChange();
    }
  };

  const { mapMakeModels } = useMakeModelReadURL({
    currentMake,
    currentModel,
    currentMultipleMakeAndOrModels,
    currentSection,
    makeModelEditable,
  });

  const initialiseSEOFilter = (SEOFilterValue: string) => {
    const filterValue =
      filter.searchQueryGroup === 'ranges'
        ? [SEOFilterValue, SEOFilterValue]
        : SEOFilterValue;

    onChangeFilterValue({
      queryParam: {
        [filter.name]: SEOFilterValue,
      },
      filterValue,
    });
  };

  const initialiseSelectedFilterQueryValue = () => {
    if (filter?.name === 'makeModel') {
      mapMakeModels();
    } else if (filter?.name === 'location') {
      initialiseLocationFilter();
    } else if (
      currentFilterPathParameterValue &&
      filter?.name === formattedFilterName
    ) {
      initialiseSEOFilter(currentFilterPathParameterValue);
    } else if (filter?.name === 'make') {
      onChangeFilterValue({
        queryParam: {
          [filter.name]: currentMake,
        },
        filterValue: currentMake,
      });
    } else {
      onChangeFilterValue({
        queryParam: initialQueryParam ?? {},
        filterValue: initialValue,
      });
    }
  };

  const scrollFilterToCentre = () => {
    const { current } = ref;

    if (current) {
      current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  };

  const onClick = () => {
    scrollFilterToCentre();
    selectQuickFilter(filter);
    initialiseSelectedFilterQueryValue();
  };

  const quickFilterID = `quick_filter_${camelToSnakeCase(filter.name)}`;

  return {
    ref,
    onClick,
    quickFilterID,
  };
};
export { useQuickFilter };
