import { useBrowserStorage } from 'hooks/UseBrowserStorage';
import { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import { useEffect, useState } from 'react';
import { AccordionStateStore } from 'features/filters/AccordionState.typed';
import { usePathname } from 'next/navigation';

export const useAccordionState = (filtersData: ISearchPageFilter[]) => {
  const pathName = usePathname()?.split('/')[1] || '';
  const accordionStateLocalStorageKey = `filtersAccordionOpenState${pathName}`;
  const { getLocalStorageItem, setLocalStorageItem } = useBrowserStorage();
  const [hasUpdatedAccordionState, setHasUpdatedAccordionState] =
    useState(false);

  const defaultAccordionState = filtersData?.reduce(
    (acc: AccordionStateStore, item) => {
      if (item.openByDefault) {
        acc[item.name] = 'true';
      }
      return acc;
    },
    {},
  );

  const [accordionState, setAccordionState] = useState<AccordionStateStore>(
    defaultAccordionState,
  );

  const getAccordionState = (name: string) => accordionState[name] === 'true';

  const toggleAccordionState = (name: string) => {
    if (accordionState[name] === 'true') {
      accordionState[name] = 'false';
    } else {
      accordionState[name] = 'true';
    }
    setAccordionState({ ...accordionState });
    setLocalStorageItem(accordionStateLocalStorageKey, accordionState);
  };

  useEffect(() => {
    const userAccordionOpenState =
      getLocalStorageItem(accordionStateLocalStorageKey) || {};
    setAccordionState({
      ...defaultAccordionState,
      ...userAccordionOpenState,
    });
    // Only display filters once the accordion stored state has been set to prevent UI flicker
    setHasUpdatedAccordionState(true);
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getAccordionState,
    toggleAccordionState,
    hasUpdatedAccordionState,
  };
};
