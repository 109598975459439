import { Fragment } from 'react';

import * as Styled from 'components/SearchPage/components/Listings/Listings.styled';

import type { ListingsProps } from 'components/SearchPage/components/Listings/Listings.typed';

import { SearchCard } from 'components/SearchPage/components/Listings/components/SearchCard/SearchCard';
import { SkeletonSearchCard } from 'components/SearchPage/components/Listings/components/SkeletonSearchCard/SkeletonSearchCard';
import { SearchCardV2 } from 'components/SearchPage/components/Listings/components/SearchCardV2/SearchCardV2';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { DISPLAY_NEW_AD_CARDS },
} = getConfig();

function Listings(props: ListingsProps) {
  const {
    listings,
    listingsV2,
    viewType,
    currentSection,
    isLoading = false,
    createSavedAdEvent,
    deleteSavedAdEvent,
    extra,
  } = props;

  const isGrid = viewType === 'grid';

  const SKELETON_SEARCH_CARD_LIST_LENGTH = 15;

  return (
    <Styled.List data-testid="card-list" viewType={viewType}>
      {isLoading &&
        Array.apply(null, Array(SKELETON_SEARCH_CARD_LIST_LENGTH)).map(
          (_item: null, index: number) => {
            return (
              <SkeletonSearchCard
                key={`skeleton-search-card-${index}`}
                isGrid={isGrid}
              />
            );
          },
        )}
      {DISPLAY_NEW_AD_CARDS !== 'true' &&
        !isLoading &&
        listings &&
        listings.map((item, index) => (
          <Fragment
            key={item.spotlight ? `spotlight-${item.id}` : `${item.id}`}
          >
            {extra &&
              extra.length &&
              extra(index).map((item, index) => (
                <Fragment key={index}>{item}</Fragment>
              ))}
            <Styled.ListItem data-testid={`listing-card-index-${index}`}>
              <SearchCard
                item={item}
                isGrid={isGrid}
                currentSection={currentSection}
                createSavedAdEvent={createSavedAdEvent}
                deleteSavedAdEvent={deleteSavedAdEvent}
              />
            </Styled.ListItem>
          </Fragment>
        ))}
      {DISPLAY_NEW_AD_CARDS === 'true' &&
        !isLoading &&
        listingsV2 &&
        listingsV2.map((listing, index) => (
          <Fragment
            key={
              listing.spotlighted ? `spotlight-${listing.id}` : `${listing.id}`
            }
          >
            {extra &&
              extra.length &&
              extra(index).map((item, index) => (
                <Fragment key={index}>{item}</Fragment>
              ))}
            <Styled.ListItem data-testid={`listing-card-index-${index}`}>
              <SearchCardV2
                ad={listing}
                isGrid={isGrid}
                currentSection={currentSection}
                createSavedAdEvent={createSavedAdEvent}
                deleteSavedAdEvent={deleteSavedAdEvent}
              />
            </Styled.ListItem>
          </Fragment>
        ))}
    </Styled.List>
  );
}

export { Listings };
