import { useRouter } from 'next/router';

import {
  useFiltersState,
  useFiltersDispatch,
} from 'features/filters/Filters.context';
import { useLocationContext } from 'features/location/Location.context';
import { useFiltersFormatting } from 'features/filters/FiltersFormatting.hook';
import { useFilterCount } from 'components/SearchPage/hooks/useFilterCount/useFilterCount';

import { getFilterPathParameterData } from 'helpers/seo/searchParams';
import { formatMakeAndModelQueries } from 'components/SearchPage/helpers/formatting';

import type { ISearchPageFilter } from 'api/types/searchPageApiTypes';

import {
  CAR_FILTER_LIMIT,
  COMMERCIALS_FILTER_LIMIT,
  FILTER_LIMIT,
} from 'components/SearchPage/features/QuickFilters/QuickFilters.constants';

const useQuickFilters = () => {
  const { setIsFilterModalOpen } = useFiltersDispatch();
  const { filtersData } = useFiltersState();
  const { count } = useFilterCount();
  const { getFilterCopy } = useFiltersFormatting();

  const { locationDisplay } = useLocationContext();

  const { query } = useRouter();
  const { section, make: makeQuery, radius, countyTown } = query;

  const [currentSection, makeQueryValue, modelQueryValue, seoFilterQuery] =
    Array.isArray(section) ? section : [];

  const countDisplay = count > 0 ? ` (${count})` : '';

  const displayRadius = countyTown && radius ? ` (+${radius}km)` : '';
  const displayModel = modelQueryValue ? ` ${modelQueryValue}` : '';

  const locationCopy = locationDisplay
    ? `${locationDisplay}${displayRadius}`
    : undefined;
  const makeModelCopy = makeQueryValue
    ? `${makeQueryValue}${displayModel}`
    : formatMakeAndModelQueries(makeQuery);

  const openFilterModal = () => setIsFilterModalOpen(true);

  const getFilterLimit = (section?: string) => {
    switch (section) {
      case 'cars':
        return CAR_FILTER_LIMIT;
      case 'commercials':
        return COMMERCIALS_FILTER_LIMIT;
      default:
        return FILTER_LIMIT;
    }
  };

  const filterLimit = getFilterLimit(currentSection);

  const quickFiltersData = filtersData
    .filter(
      (filter) =>
        filter.name !== 'terms' && filter.name != 'containsValidPrice',
    )
    .slice(0, filterLimit);

  const displayFilterAndRangeText = (filter: ISearchPageFilter) => {
    const { filterPathParameter, formattedFilterName } =
      getFilterPathParameterData([filter], seoFilterQuery);

    const filterQuery =
      filterPathParameter && formattedFilterName === filter.name
        ? filterPathParameter
        : query;

    return getFilterCopy(filter, filterQuery, true);
  };

  const displayText = (filter: ISearchPageFilter) => {
    switch (filter.name) {
      case 'location':
        return locationCopy;
      case 'makeModel':
        return makeModelCopy;
      case 'make':
        return makeQueryValue;
      default:
        return displayFilterAndRangeText(filter);
    }
  };

  return {
    openFilterModal,
    quickFiltersData,
    countDisplay,
    displayText,
  };
};

export { useQuickFilters };
